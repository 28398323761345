import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Alert, 
  CircularProgress,
  Paper,
  Link,
  Divider
} from '@mui/material';
import { getUserProfile, updateUserProfile, syncSteamLibrary, updateStatusCounts } from '../../services/api';

const SteamSettings: React.FC = () => {
  const [steamId, setSteamId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hasSteamId, setHasSteamId] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(0);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await getUserProfile();
        if (response.data.steam_id) {
          setSteamId(response.data.steam_id);
          setHasSteamId(true);
        }
      } catch (err) {
        setError('プロフィール情報の取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSteamAuth = () => {
    const redirectUri = encodeURIComponent(`${window.location.origin}/auth/steam/callback?mode=link`);
    window.location.href = `${process.env.REACT_APP_API_URL || ''}/auth/steam/login/?redirect_uri=${redirectUri}`;
  };

  const handleSyncLibrary = async () => {
    try {
      setSyncLoading(true);
      setError(null);
      setSuccess(null);
      
      const result = await syncSteamLibrary();
      setSuccess(result.message || 'Steamライブラリの同期を開始しました');
    } catch (err: any) {
      setError(err.message || 'Steamライブラリの同期に失敗しました');
    } finally {
      setSyncLoading(false);
    }
  };

  const handleUpdateStatusCounts = async () => {
    const now = Date.now();
    if (now - lastUpdateTime < 2000) {
      return;
    }

    try {
      setUpdateLoading(true);
      setError(null);
      setSuccess(null);
      setLastUpdateTime(now);
      
      const [result] = await Promise.all([
        updateStatusCounts(),
        new Promise(resolve => setTimeout(resolve, 2000))
      ]);
      
      setSuccess('ステータスカウントが更新されました');
    } catch (err: any) {
      setError(err.message || 'ステータスカウントの更新に失敗しました');
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Steam連携設定
      </Typography>

      <Paper elevation={0} sx={{ mb: 3, bgcolor: "background.paper" }}>
        <Typography variant="body1" color="text.secondary" paragraph>
          Steamアカウントを連携すると、SteamのゲームをPULSEライブラリと同期できます。
        </Typography>

        <Box sx={{ mb: 3 }}>
          {hasSteamId ? (
            <>
              <Alert
                severity="success"
                sx={{
                  mb: 2,
                  maxWidth: 500,
                  background:
                    "linear-gradient(135deg, rgba(23, 26, 33, 0.95), rgba(42, 71, 94, 0.95))",
                  color: "#ffffff",
                  border: "1px solid rgba(102, 192, 244, 0.2)",
                  borderRadius: "8px",
                  backdropFilter: "blur(8px)",
                  "& .MuiAlert-icon": {
                    color: "#66c0f4",
                  },
                }}
              >
                Steamアカウントが連携されています。Steam ID: {steamId}
              </Alert>
              <Button
                variant="contained"
                onClick={handleSteamAuth}
                disabled={loading}
              >
                Steamアカウントを再連携
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2" paragraph color="text.secondary">
                Steamアカウントを連携するには、Steamにログインする必要があります。
              </Typography>
              <Button
                variant="contained"
                onClick={handleSteamAuth}
                disabled={loading}
                sx={{ mb: 2 }}
              >
                Steamで認証する
              </Button>
            </>
          )}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Steamライブラリの同期
          </Typography>

          <Typography variant="body2" paragraph color="text.secondary">
            SteamのゲームをPULSEライブラリに取り込みます。
            プレイ状況に応じて自動でステータスが振り分けられますが、正確なステータスは手動で設定してください。
            なお、既存のゲームステータスが削除されることはありません。
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleSyncLibrary}
            disabled={syncLoading || !hasSteamId}
            startIcon={syncLoading ? <CircularProgress size={20} /> : null}
            sx={{ mt: 1 }}
          >
            ライブラリを同期
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            ステータスカウント修復ツール
          </Typography>

          <Typography variant="body2" paragraph color="text.secondary">
            ライブラリのステータスカウントが正しく集計されていない場合、手動で修復できます。
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpdateStatusCounts}
            disabled={updateLoading || Date.now() - lastUpdateTime < 2000}
            startIcon={updateLoading ? <CircularProgress size={20} /> : null}
            sx={{
              mt: 1,
              color: "text.secondary",
              borderColor: "divider",
              "&:hover": {
                backgroundColor: "action.hover",
                borderColor: "text.secondary",
              },
            }}
          >
            {updateLoading ? "更新中..." : "ライブラリを修復"}
          </Button>
        </Box>
      </Paper>

      {success && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {success}
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SteamSettings; 