import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, useTheme, useMediaQuery, CircularProgress, LinearProgress, IconButton, Button } from '@mui/material';
import { SportsEsports, Timer, CalendarToday, Grade, RateReview, Launch } from '@mui/icons-material';
import { UserProfile } from '../types/UserProfile';
import { getProfileStats } from '../services/api';
import Tips from './dialogs/Tips';
import { Link, useNavigate } from 'react-router-dom';
import FavoriteGamesWidget from './profile/FavoriteGamesWidget';
import { getPlatformName } from '../utils/PlatformList';
import Dialog from './dialogs/Dialog';

interface ProfileStats {
  total_played: number;
  played_in_current_year: number;
  last_updated?: string;
  game_debut: {
    year: number;
    date: string;
    age?: number;
    age_text?: string;
    game: {
      id: number;
      name: string;
      cover_url: string | null;
    };
  } | null;
  rating_stats: {
    average: number | null;
    count: number;
    distribution: { [key: number]: number };
    last_updated?: string;
  };
  review_count: number;
  platform_stats: {
    distribution: { [key: number]: number };
    count: number;
    last_updated?: string;
  };
}

interface ProfileTabProps {
  profile: UserProfile | null;
  isPublic?: boolean;
}

// 共通のPaperスタイルを定義
const commonPaperStyle = {
  p: 2.5,
  height: '100%',
  padding: { xs: 2, sm: 2.5 },
  borderRadius: 2,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'background.paper',
  backdropFilter: 'blur(10px)',
  transition: 'all 0.2s ease',
  position: 'relative',
};

// アイコンの共通スタイル
const iconStyle = {
  mr: 1,
  color: 'text.primary',
  fontSize: '1rem',
  display: 'none',
};

// 数値表示用のタイポグラフィスタイル
const statsNumberStyle = {
  fontSize: { xs: '1.6rem', sm: '2rem' },
  fontWeight: 600,
  color: '#e6edf3',
  letterSpacing: '-0.5px',
  lineHeight: 1.2
};

// サブテキスト用のスタイル
const subtitleStyle = {
  color: '#8b949e',
};

// LinearProgressのスタイルを更新
const progressStyle = {
  height: 2,
  borderRadius: 2,
  backgroundColor: 'rgba(110, 118, 129, 0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'primary.main'
  }
};

// 順位に応じて透明度を計算する関数
const getOpacityByRank = (index: number, total: number): number => {
  // 1番目は1.0、最後は0.3になるように線形に減少
  return Math.max(1.0 - (index / total) * 0.7, 0.3);
};

// 相対時刻を計算する関数
const getRelativeTimeString = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return '数秒前';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}分前`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}時間前`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days}日前`;
  }
};

// StatsWidgetコンポーネントを作成
interface StatsWidgetProps {
  title: string;
  value: number | string;
  subtitle: string;
  icon?: React.ReactElement;
  onClick?: () => void;
  lastUpdated?: string;
  children?: React.ReactNode;
}

const StatsWidget: React.FC<StatsWidgetProps> = ({
  title,
  value,
  subtitle,
  icon,
  onClick,
  lastUpdated,
  children
}) => (
  <Paper 
    elevation={0}
    sx={{ 
      ...commonPaperStyle,
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      {icon && React.cloneElement(icon, { sx: iconStyle })}
      <Typography variant="subtitle2" color="text.secondary">
        {title}
      </Typography>
    </Box>
    <Typography variant="h3" component="div" sx={statsNumberStyle}>
      {value}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
      {subtitle}
    </Typography>
    {children}
    {onClick && (
      <IconButton
        onClick={onClick}
        size="small"
        sx={{
          position: 'absolute',
          right: { xs: 4, sm: 8 },
          bottom: { xs: 4, sm: 8 },
          color: 'text.secondary',
          '&:hover': {
            color: 'primary.main'
          }
        }}
      >
        <Launch fontSize="small" />
      </IconButton>
    )}
    {lastUpdated && (
      <Typography 
        variant="caption" 
        color="text.secondary"
        sx={{ 
          display: 'block',
          textAlign: 'right',
          fontSize: '0.6rem',
          fontWeight: '400',
          opacity: 0.7
        }}
      >
        データ更新: {getRelativeTimeString(lastUpdated)}
      </Typography>
    )}
  </Paper>
);

const ProfileTab: React.FC<ProfileTabProps> = ({ profile, isPublic }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [stats, setStats] = useState<ProfileStats | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dialogProps, setDialogProps] = useState<{
    open: boolean;
    title: string;
    content: React.ReactNode;
    width?: number;
  }>({
    open: false,
    title: '',
    content: null
  });

  useEffect(() => {
    const fetchStats = async () => {
      if (!profile) return;
      
      try {
        const response = await getProfileStats(isPublic ? profile.username : undefined);
        setStats(response.data);
      } catch (error) {
        console.error('統計情報の取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [profile, isPublic]);

  const statsInfo = !isPublic && (
    <Typography variant="body2">
      <Typography component="p" paragraph>
        プロフィール統計情報の計算方法：
      </Typography>
      <Typography component="p" paragraph>
        <strong>総プレイゲーム数：</strong><br />
        「プレイ済み」「プレイ中」「中断」のステータスが設定されているゲームの合計数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>2025年のプレイ数：</strong><br />
        2025年にプレイを開始したゲーム、または2025年にプレイを終了したゲームの合計数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>評価統計：</strong><br />
        スコアを設定したゲームの平均評価点と評価数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>プラットフォーム統計：</strong><br />
        プレイしたプラットフォームの種類とその数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>レビュー投稿数：</strong><br />
        テキストレビューを投稿したゲームの数です。
      </Typography>
      <Typography component="p" paragraph>
        <strong>ゲームデビュー：</strong><br />
        登録されているゲームの中で最も古いプレイ開始日を基準に算出しています。
      </Typography>
    </Typography>
  );

  if (!profile) {
    return null;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <FavoriteGamesWidget username={isPublic ? profile.username : undefined} />
      <Box sx={{ py: { xs: 1.5, sm: 2 } }}>
        {!isPublic && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, opacity: 0.7 }}>
          <Typography variant="body2" component="h2" >
            統計情報
          </Typography>
          
            <Tips 
              title="統計情報について" 
              content={statsInfo}
            />
          </Box>
        )}
        <Grid container spacing={{ xs: 1.5, sm: 2 }}>
          {/* 総プレイゲーム数 */}
          <Grid item xs={6} sm={6} md={3}>
            <StatsWidget
              title="総プレイ数"
              value={stats?.total_played || 0}
              subtitle="games"
              icon={<SportsEsports />}
              onClick={() => {
                const newUrl = isPublic 
                  ? `/profile/${profile?.username}?tab=library&status=1` 
                  : '/profile?tab=library&status=1';
                navigate(newUrl);
              }}
            />
          </Grid>

          {/* 現在の年のプレイ数 */}
          <Grid item xs={6} sm={6} md={3}>
            <StatsWidget
              title={`${new Date().getFullYear()}年プレイ数`}
              value={stats?.played_in_current_year || 0}
              subtitle="games"
              icon={<Timer />}
            />
          </Grid>

          {/* レビュー投稿数 */}
          <Grid item xs={6} sm={6} md={3}>
            <StatsWidget
              title="レビュー投稿数"
              value={stats?.review_count || 0}
              subtitle="reviews"
              icon={<RateReview />}
              onClick={() => {
                const newUrl = isPublic 
                  ? `/profile/${profile?.username}?tab=reviews&page=1` 
                  : '/profile?tab=reviews&page=1';
                navigate(newUrl);
              }}
            />
          </Grid>

          {/* 積みゲー数 */}
          <Grid item xs={6} sm={6} md={3}>
            <StatsWidget
              title="積みゲー"
              value={profile?.status_counts?.[3] || 0}
              subtitle="games"
              icon={<SportsEsports />}
              onClick={() => {
                const newUrl = isPublic 
                  ? `/profile/${profile?.username}?tab=library&status=4` 
                  : '/profile?tab=library&status=4';
                navigate(newUrl);
              }}
            />
          </Grid>

          {/* 評価統計 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Grade sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  評価統計
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                {stats?.rating_stats.average ? 
                  stats.rating_stats.average.toFixed(1) : 
                  '-'}
                <Typography 
                  component="span" 
                  sx={{ 
                    fontSize: '0.8rem', 
                    color: 'text.secondary',
                    ml: 1 
                  }}
                >
                  Avg.
                </Typography>
              </Typography>
              <Box sx={{ mb: 1 }}>
                {stats?.rating_stats.distribution && 
                  Object.entries(stats.rating_stats.distribution)
                    .sort(([a], [b]) => Number(b) - Number(a))
                    .map(([score, count], index, array) => {
                      // 全てのカウントが0の場合は表示しない
                      const totalCount = Object.values(stats.rating_stats.distribution).reduce((a, b) => a + b, 0);
                      if (totalCount === 0) return null;

                      // 評価スコアは合計を100%として表示
                      const percentage = (count / totalCount) * 100;
                      const opacity = getOpacityByRank(index, array.length);
                      
                      return (
                        <Box key={score} sx={{ mb: 0.25 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
                            <Typography variant="caption">{Number(score).toFixed(1)}</Typography>
                            <Typography variant="caption">{count}</Typography>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={percentage}
                            sx={{
                              ...progressStyle,
                              '& .MuiLinearProgress-bar': {
                                ...progressStyle['& .MuiLinearProgress-bar'],
                                opacity
                              }
                            }}
                          />
                        </Box>
                      );
                    })}
              </Box>
              <Typography variant="body2" color="text.secondary" sx={subtitleStyle}>
                {`${stats?.rating_stats.count || 0} games rated`}
              </Typography>
              {!isPublic && stats?.rating_stats.last_updated && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    display: 'block',
                    textAlign: 'right',
                    fontSize: '0.6rem',
                    fontWeight: '400',
                    opacity: 0.7
                  }}
                >
                  データ更新: {getRelativeTimeString(stats.rating_stats.last_updated)}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* プラットフォーム統計 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <SportsEsports sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  プラットフォーム
                </Typography>
              </Box>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                {Object.keys(stats?.platform_stats.distribution || {}).length}
              </Typography>
              <Box sx={{ mb: 1 }}>
                {stats?.platform_stats.distribution && 
                  Object.entries(stats.platform_stats.distribution)
                    .sort(([, a], [, b]) => b - a)
                    .slice(0, 10)
                    .map(([platformId, count], index, array) => {
                      // 全てのカウントが0の場合は表示しない
                      const maxCount = Math.max(...Object.values(stats.platform_stats.distribution));
                      if (maxCount === 0) return null;

                      // プラットフォームは最大値を100%として相対表示
                      const percentage = (count / maxCount) * 100;
                      const opacity = getOpacityByRank(index, array.length);
                      
                      // 総数に対するパーセンテージを計算
                      const totalCount = Object.values(stats.platform_stats.distribution).reduce((a, b) => a + b, 0);
                      const totalPercentage = totalCount > 0 ? (count / totalCount * 100).toFixed(1) : '0';

                      return (
                        <Box key={platformId} sx={{ mb: 0.25 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
                            <Typography variant="caption">{getPlatformName(Number(platformId))}</Typography>
                            <Typography variant="caption">
                              {count} <Typography component="span" variant="caption" sx={{ opacity: 0.6, ml: 0.5 }}>{totalPercentage}%</Typography>
                            </Typography>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={percentage}
                            sx={{
                              ...progressStyle,
                              '& .MuiLinearProgress-bar': {
                                ...progressStyle['& .MuiLinearProgress-bar'],
                                opacity
                              }
                            }}
                          />
                        </Box>
                      );
                    })}
              </Box>
              {Object.keys(stats?.platform_stats.distribution || {}).length > 10 && (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mt: 1
                }}>
                  <Button 
                    size="small" 
                    onClick={() => {
                      const platformContent = (
                        <Box>
                          {stats && Object.entries(stats.platform_stats.distribution)
                            .sort(([, a], [, b]) => b - a)
                            .map(([platformId, count], index, array) => {
                              const maxCount = Math.max(...Object.values(stats.platform_stats.distribution));
                              const percentage = (count / maxCount) * 100;
                              const opacity = getOpacityByRank(index, array.length);
                              
                              const totalCount = Object.values(stats.platform_stats.distribution).reduce((a, b) => a + b, 0);
                              const totalPercentage = totalCount > 0 ? (count / totalCount * 100).toFixed(1) : '0';
                              
                              return (
                                <Box key={platformId} sx={{ mb: 1 }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
                                    <Typography>{getPlatformName(Number(platformId))}</Typography>
                                    <Typography>
                                      {count} <Typography component="span" sx={{ opacity: 0.6 }}>({totalPercentage}%)</Typography>
                                    </Typography>
                                  </Box>
                                  <LinearProgress 
                                    variant="determinate" 
                                    value={percentage}
                                    sx={{
                                      ...progressStyle,
                                      '& .MuiLinearProgress-bar': {
                                        ...progressStyle['& .MuiLinearProgress-bar'],
                                        opacity
                                      }
                                    }}
                                  />
                                </Box>
                              );
                            })}
                        </Box>
                      );
                      
                      // ダイアログを表示
                      setDialogProps({
                        open: true,
                        title: 'プラットフォーム統計',
                        content: platformContent,
                        width: 450
                      });
                    }}
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.65rem',
                      textTransform: 'none',
                      p: 0,
                      minWidth: 'auto',
                      '&:hover': {
                        color: 'primary.main',
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    全て表示
                  </Button>
                  {!isPublic && stats?.platform_stats.last_updated && (
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        fontSize: '0.6rem',
                        fontWeight: '400',
                        opacity: 0.7
                      }}
                    >
                      データ更新: {getRelativeTimeString(stats.platform_stats.last_updated)}
                    </Typography>
                  )}
                </Box>
              )}
            </Paper>
          </Grid>


          {/* ゲームデビュー */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper 
              elevation={0}
              sx={{ 
                ...commonPaperStyle,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarToday sx={iconStyle} />
                <Typography variant="subtitle2" color="text.secondary">
                  ゲームデビュー
                </Typography>
              </Box>
              {stats?.game_debut && (
                <>
                  <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
                    {stats.game_debut.year}
                    <Typography 
                      component="span" 
                      sx={{ 
                        fontSize: '0.8rem', 
                        color: 'text.primary',
                        ml: 1 
                      }}
                    >
                      /{new Date().getFullYear() - stats.game_debut.year}年前
                    </Typography>
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={subtitleStyle}
                    component={Link}
                    to={`/game/${stats.game_debut.game.id}`}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {stats.game_debut.age_text ? 
                      `${stats.game_debut.age_text}で${stats.game_debut.game.name}を初プレイ` :
                      stats.game_debut.age ? 
                        `${stats.game_debut.age}歳で${stats.game_debut.game.name}を初プレイ` : 
                        stats.game_debut.game.name}
                  </Typography>
                  <Box 
                    component={Link}
                    to={`/game/${stats.game_debut.game.id}`}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      backgroundColor: 'rgba(255, 255, 255, 0.02)',
                      mt: 2,
                      pt: 2,
                      pb: 2,
                      textDecoration: 'none'
                    }}
                  >
                    <Box 
                      component="img"
                      src={stats.game_debut.game.cover_url || '/image/no_image.png'}
                      alt={stats.game_debut.game.name}
                      sx={{ 
                        width: '100%',
                        maxWidth: 140,
                        height: 'auto',
                        borderRadius: 1,
                        objectFit: 'cover',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.03)'
                        }
                      }}
                    />
                  </Box>
                </>
              )}
            </Paper>
          </Grid>

          {isPublic && stats?.last_updated && (
          <Grid item xs={12} >
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.6rem',
              fontWeight: '400',
              opacity: 0.7
            }}
          >
            データ更新: {getRelativeTimeString(stats.last_updated)}
          </Typography>
          </Grid>
        )}

        </Grid>
      </Box>
      <Dialog
        open={dialogProps.open}
        onClose={() => setDialogProps({ ...dialogProps, open: false })}
        title={dialogProps.title}
        content={dialogProps.content}
        width={dialogProps.width}
      />
    </Box>
  );
};

export default ProfileTab; 