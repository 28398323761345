import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, Chip, Card, CardMedia, TextField, IconButton, Button, useMediaQuery } from '@mui/material';
import { getGamePlayLog, updatePlayLogSummary, updatePlayLogRecord } from '../services/api';
import { formatPlayTime, formatTotalPlayTime } from '../utils/dateUtils';
import PlayTimeGraph from '../components/graph/PlayTimeGraph';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../components/dialogs/Dialog';
import StyledTextField from '../components/common/StyledTextField';
import AdvertisementResponsive from '../components/advertisement/Ad_GA_res';
import PlaySessionButton from '../components/PlaySessionButton';
import ShareButtons from '../components/common/ShareButtons';
import { Helmet } from 'react-helmet-async';

// カテゴリーの定義
const CATEGORIES = {
  "0": { label: "メイン", color: "#4caf50" },
  "1": { label: "サブ", color: "#2196f3" },
  // "2": { label: "探索", color: "#a7a7a7" },
  // "3": { label: "育成", color: "#a7a7a7" },
  "7": { label: "やり込み", color: "#a7a7a7" },
  "8": { label: "マルチ", color: "#a7a7a7" },
  "97": { label: "プレイ終了", color: "#5a5a5a" },
  "98": { label: "クリア", color: "#e2e600" },
  "99": { label: "コンプリート", color: "#e2e600" }
} as const;

interface PlayLogRecord {
  date: string;
  play_time: number;
  category: string[];
  comment: string;
}

interface GameInfo {
  game_id: number;
  name: string;
  japanese_name: string;
  first_release_date: string;
  cover: string | null;
}

interface PlayLogData {
  game: GameInfo;
  user: {
    username: string;
    display_name: string;
    avatar: string | null;
  };
  daily_records: PlayLogRecord[];
  total_play_time: number;
  total_sessions: number;
  total_days: number;
  summary: string;
}

// 共通スタイルの定義
const numberStyle = {
  fontSize: { xs: '0.9rem', sm: '1.5rem' },
  fontWeight: 600,
  color: '#e6edf3',
  lineHeight: 1, // ラインヘイトを1に設定
};

const smallTextStyle = {
  fontSize: { xs: '0.6rem', sm: '0.7rem' }, // 小さく
  color: 'rgba(255, 255, 255, 0.5)', // 薄く
  ml: 0.5 // マージンを追加
};

// アクティブ率の計算関数を作成（共通ロジック）
const calculateActiveRate = (records: PlayLogRecord[]): number => {
  if (records.length < 3) return 0;

  const lastPlayDate = new Date(records[0].date);
  const firstPlayDate = new Date(records[records.length - 1].date);
  const ninetyDaysAgo = new Date(lastPlayDate.getTime() - (90 * 24 * 60 * 60 * 1000));
  
  const startDate = firstPlayDate > ninetyDaysAgo ? firstPlayDate : ninetyDaysAgo;
  const daysDiff = Math.max(1, Math.floor((lastPlayDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1);
  
  const activeDays = records.filter(record => {
    const recordDate = new Date(record.date);
    return recordDate >= startDate && recordDate <= lastPlayDate;
  }).length;

  return (activeDays / daysDiff) * 100;
};

const GamePlayLogPage = () => {
  const { gameId, username } = useParams();
  const [playLog, setPlayLog] = useState<PlayLogData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [summary, setSummary] = useState('');
  const currentUser = JSON.parse(localStorage.getItem('user') || 'null');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  // レコード編集用の状態
  const [isRecordDialogOpen, setIsRecordDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<PlayLogRecord | null>(null);
  const [editedPlayTime, setEditedPlayTime] = useState<number>(0);
  const [editedCategory, setEditedCategory] = useState<string[]>([]);
  const [editedComment, setEditedComment] = useState<string>('');

  const isMobile = useMediaQuery('(max-width:600px)'); // スマホ判定用
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayLog = async () => {
      try {
        const data = await getGamePlayLog(Number(gameId), username);
        setPlayLog(data);
      } catch (error) {
        console.error('プレイログの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayLog();
  }, [gameId, username]);

  const handleSaveSummary = async () => {
    try {
      await updatePlayLogSummary(Number(gameId), summary);
      setPlayLog(prev => prev ? { ...prev, summary } : null);
      setIsDialogOpen(false);
    } catch (error) {
      console.error('概要の更新に失敗しました:', error);
    }
  };

  const handleEditRecord = (record: PlayLogRecord) => {
    setSelectedRecord(record);
    setEditedPlayTime(record.play_time);
    setEditedCategory(record.category);
    setEditedComment(record.comment);
    setIsRecordDialogOpen(true);
  };

  const handleSaveRecord = async () => {
    if (!selectedRecord) return;
    
    try {
      const updatedRecord = {
        date: selectedRecord.date,
        play_time: editedPlayTime,
        category: editedCategory,
        comment: editedComment
      };
      
      await updatePlayLogRecord(Number(gameId), updatedRecord);
      
      // 更新後のデータを反映
      setPlayLog(prev => {
        if (!prev) return null;
        
        const updatedRecords = prev.daily_records.map(record => 
          record.date === selectedRecord.date ? updatedRecord : record
        );
        
        // 合計プレイ時間を再計算
        const totalPlayTime = updatedRecords.reduce((sum, record) => sum + record.play_time, 0);
        
        return {
          ...prev,
          daily_records: updatedRecords,
          total_play_time: totalPlayTime
        };
      });
      
      setIsRecordDialogOpen(false);
    } catch (error) {
      console.error('プレイログの更新に失敗しました:', error);
    }
  };

  const dialogContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <StyledTextField
        value={summary}
        onChange={setSummary}
        placeholder="概要を入力（100字まで）"
        multiline
        minRows={3}
        maxRows={5}
        inputProps={{ maxLength: 100 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button onClick={() => setIsDialogOpen(false)}>キャンセル</Button>
        <Button onClick={handleSaveSummary} variant="contained">保存</Button>
      </Box>
    </Box>
  );

  const recordDialogContent = selectedRecord && (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="subtitle1">
        {new Date(selectedRecord.date).toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'short'
        })}
      </Typography>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
          プレイ時間（分）
        </Typography>
        <TextField
          type="number"
          size="small"
          value={String(editedPlayTime)}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0 && value <= 1440) { // 1440分 = 24時間
              setEditedPlayTime(value);
            }
          }}
          fullWidth
          inputProps={{ min: 1, max: 1440 }}
          error={editedPlayTime > 1440}
          helperText={editedPlayTime > 1440 ? "1日のプレイ時間は24時間（1440分）を超えることはできません" : ""}
        />
      </Box>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
          カテゴリ
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {Object.entries(CATEGORIES).map(([id, { label }]) => (
            <Chip
              key={id}
              label={label}
              size="small"
              onClick={() => {
                setEditedCategory(prev => 
                  prev.includes(id) 
                    ? prev.filter(catId => catId !== id)
                    : [...prev, id]
                );
              }}
              sx={{
                backgroundColor: editedCategory.includes(id) 
                  ? 'primary.main' // プライマリカラーに変更
                  : 'rgba(255, 255, 255, 0.08)',
                color: editedCategory.includes(id) ? 'rgba(0, 0, 0, 0.85)' : 'white',
                border: 'none',
                opacity: editedCategory.includes(id) ? 1 : 0.6,
                '&:hover': {
                  backgroundColor: editedCategory.includes(id) 
                    ? 'primary.main' // プライマリカラーに変更
                    : 'rgba(255, 255, 255, 0.12)',
                }
              }}
            />
          ))}
        </Box>
      </Box>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
          コメント（60文字まで）
        </Typography>
        <StyledTextField
          value={editedComment}
          onChange={setEditedComment}
          placeholder="コメントを入力"
          size="small"
          multiline={false}
          fullWidth
          inputProps={{ maxLength: 60 }}
        />
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button onClick={() => setIsRecordDialogOpen(false)}>キャンセル</Button>
        <Button onClick={handleSaveRecord} variant="contained">保存</Button>
      </Box>
    </Box>
  );

  // シェアテキストを生成する関数
  const generateShareText = () => {
    if (!playLog) return '';
    
    const gameName = playLog.game.japanese_name || playLog.game.name;
    const userName = playLog.user.display_name || playLog.user.username;
    const totalPlayTime = formatTotalPlayTime(playLog.total_play_time);
    const totalDays = playLog.total_days;
    const totalSessions = playLog.total_sessions;
    const activeRate = calculateActiveRate(playLog.daily_records);
    const playPeriod = Math.floor(
      (new Date(playLog.daily_records[0].date).getTime() - 
       new Date(playLog.daily_records[playLog.daily_records.length - 1].date).getTime()) / 
      (1000 * 60 * 60 * 24) + 1
    );
    
    return [
      `${gameName} のプレイログ by ${userName}`,
      `🕐 総プレイ時間: ${totalPlayTime}時間`,
      `📅 プレイ日数: ${totalDays} / プレイ期間: ${playPeriod}`,
      `🎮 セッション: ${totalSessions}`,
      activeRate > 0 ? `📊 起動率: ${activeRate.toFixed(1)}%` : activeRate === 0 ? '📊 起動率: -' : '',
      '| PULSE - ゲーム記録管理',
      ''  // 最後に空行を追加して見やすくする
    ].filter(line => line !== '').join('\n');
  };

  if (loading) {
    return <Typography>読み込み中...</Typography>;
  }

  if (!playLog) {
    return <Typography>プレイログが見つかりませんでした。</Typography>;
  }

  return (
    <Box>
      {playLog && (
        <Helmet>
          <title>
            {`${playLog.game.japanese_name || playLog.game.name} のプレイ記録 by ${
              playLog.user.display_name || playLog.user.username
            } | PULSE`}
          </title>
          <meta 
            name="description" 
            content={`${playLog.game.japanese_name || playLog.game.name} のプレイ記録 - 総プレイ時間: ${
              formatTotalPlayTime(playLog.total_play_time)
            }時間, プレイ日数: ${playLog.total_days}日`} 
          />
        </Helmet>
      )}

      {/* ゲーム情報カード */}
      {playLog && (
        <Card 
          sx={{ 
            display: 'flex',
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.01)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'none',
            }
          }}
        >
          {playLog.game.cover && (
            <CardMedia
              component="img"
              sx={{ 
                width: { xs: 70, sm: 90 },
                height: '133.33%',
                objectFit: 'cover',
                borderRadius: '4px',
                m: 1,
                cursor: 'pointer'
              }}
              image={playLog.game.cover}
              alt={playLog.game.japanese_name || playLog.game.name}
              onClick={() => navigate(`/game/${playLog.game.game_id}`)}
            />
          )}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            p: { xs: 1, sm: 2 },
            flex: 1
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: { xs: '0.7rem', sm: '1rem' },
                fontWeight: 600,
                mb: 0.5,
                color: '#e6edf3',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              onClick={() => navigate(`/game/${playLog.game.game_id}`)}
            >
              {playLog.game.japanese_name || playLog.game.name}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              {username && (
                <Typography 
                  sx={{ 
                    fontSize: { xs: '0.6rem', sm: '0.75rem' },
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.75
                  }}
                >
                  {playLog.user.avatar && (
                    <Box
                      component="img"
                      src={playLog.user.avatar}
                      alt={playLog.user.display_name}
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.8
                        }
                      }}
                      onClick={() => navigate(`/profile/${playLog.user.username}`)}
                    />
                  )}
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={() => navigate(`/profile/${playLog.user.username}`)}
                  >
                    {playLog.user.display_name || playLog.user.username}
                  </Box>
                  のプレイログ
                </Typography>
              )}
              <Typography 
                sx={{ 
                  fontSize: { xs: '0.65rem', sm: '0.7rem' },
                  color: 'text.secondary',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '0.7rem' }, color: 'pulse.info' }}>
                    {new Date(playLog.daily_records[playLog.daily_records.length - 1].date).toLocaleDateString('ja-JP')}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: '0.6rem', sm: '0.7rem' }, color: 'pulse.info', mr:0.5 }}>
                    - {Math.floor((new Date().getTime() - new Date(playLog.daily_records[playLog.daily_records.length - 1].date).getTime()) / (1000 * 60 * 60 * 24))}日前に記録を開始
                  </Typography>
                  <ShareButtons
                    text={generateShareText()}
                    url={`/game/${playLog.game.game_id}/playlog/${playLog.user.username}`}
                    hashtags={['PULSE_DB']}
                    buttonText="共有"
                    iconSize={14}
                    fontSize={12}
                  />
                </Box>
              </Typography>
              {/* 概要文の表示・編集部分 */}
              <Box sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography 
                    sx={{ 
                      fontSize: { xs: '0.6rem', sm: '0.7rem' },
                      color: playLog.summary ? 'text.primary' : 'text.secondary',
                      flex: 1,
                    }}
                  >
                    {playLog.summary || (username === currentUser?.username ? '概要を追加' : '')}
                    {username === currentUser?.username && (
                      <EditIcon 
                        onClick={() => {
                          setSummary(playLog.summary || '');
                          setIsDialogOpen(true);
                        }}
                        sx={{ 
                          fontSize: '0.9rem',
                          color: 'text.secondary',
                          ml: 0.5,
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                          opacity: 0.7,
                          '&:hover': {
                            opacity: 1
                          }
                        }}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
              {/* <Box sx={{ mt: 1 }}>
                <PlaySessionButton 
                  gameId={playLog.game.game_id}
                  disabled={username !== currentUser?.username}
                />
              </Box> */}
            </Box>
          </Box>
        </Card>
      )}

      {playLog.daily_records.length > 0 && (
        <PlayTimeGraph 
          records={playLog.daily_records} 
          activeRate={calculateActiveRate(playLog.daily_records)}
        />
      )}
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(6, 1fr)' },
        gap: { xs: 1, sm: 2 },
        mb: 4,
        '& > div': {  // 共通のスタイル
          p: 1.5,
          borderRadius: 1,
          background: 'rgba(255, 255, 255, 0.02)',
          border: '1px solid rgba(255, 255, 255, 0.05)',
        }
      }}>
        <Box>
          <Typography 
            sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.65rem' },
              color: 'text.secondary',
              mb: 0.5
            }}
          >
            総プレイ
          </Typography>
          <Typography sx={numberStyle}>
            {formatTotalPlayTime(playLog.total_play_time)}
            <Typography component="span" sx={smallTextStyle}>時間</Typography>
          </Typography>
        </Box>
        <Box>
          <Typography 
            sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.65rem' },
              color: 'text.secondary',
              mb: 0.5
            }}
          >
            プレイ日数
          </Typography>
          <Typography sx={numberStyle}>
            {playLog.total_days}
            <Typography component="span" sx={smallTextStyle}>日</Typography>
          </Typography>
        </Box>
        <Box>
          <Typography 
            sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.65rem' },
              color: 'text.secondary',
              mb: 0.5
            }}
          >
            セッション
          </Typography>
          <Typography sx={numberStyle}>
            {playLog.total_sessions}
            <Typography component="span" sx={smallTextStyle}>回</Typography>
          </Typography>
        </Box>
        <Box>
          <Typography 
            sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.65rem' },
              color: 'text.secondary',
              mb: 0.5
            }}
          >
            プレイ期間
          </Typography>
          <Typography sx={numberStyle}>
            {Math.floor((new Date(playLog.daily_records[0].date).getTime() - 
              new Date(playLog.daily_records[playLog.daily_records.length - 1].date).getTime()) / (1000 * 60 * 60 * 24) + 1)}
            <Typography component="span" sx={smallTextStyle}>日</Typography>
          </Typography>
        </Box>
        {isMobile && ( // スマホの時のみアクティブ率を表示
          <Box>
            <Typography 
              sx={{ 
                fontSize: { xs: '0.65rem', sm: '0.65rem' },
                color: 'text.secondary',
                mb: 0.5
              }}
            >
              起動率
            </Typography>
            <Typography sx={numberStyle}>
              {(() => {
                // プレイ日数が2日以下の場合は「-」を表示
                if (playLog.daily_records.length < 3) {
                  return '-';
                }
                
                const rate = calculateActiveRate(playLog.daily_records);
                return rate.toFixed(1);
              })()}
              <Typography component="span" sx={smallTextStyle}>
                {playLog.daily_records.length < 3 ? '' : '%'}
              </Typography>
            </Typography>
          </Box>
        )}
        <Box>
          <Typography 
            sx={{ 
              fontSize: { xs: '0.65rem', sm: '0.65rem' },
              color: 'text.secondary',
              mb: 0.5
            }}
          >
            プレイ平均
          </Typography>
          <Typography sx={numberStyle}>
            {playLog.total_days > 0 
              ? formatTotalPlayTime(Math.round(playLog.total_play_time / playLog.total_days))
              : '-'}
            <Typography component="span" sx={smallTextStyle}>時間/日</Typography>
          </Typography>
        </Box>
      </Box>

      {/* スマホ用広告 */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <AdvertisementResponsive maxWidth320={true} />
      </Box>

      {/* プレイログ部分をグリッドレイアウトに変更 */}
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '8fr 4fr' },
        gap: 2,
        position: 'relative'
      }}>
        {/* プレイログリスト */}
        <Box>
          {playLog.daily_records.map((record, index) => {
            // プレイ時間の割合を計算（6時間を最大値として）
            const maxPlayTime = 6 * 60; // 6時間を分単位で
            const playTimeRatio = Math.min(record.play_time / maxPlayTime, 1); // 1を超えないように制限

            return (
              <React.Fragment key={record.date}>
                <Box
                  sx={{
                    py: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                    backgroundColor: new Date(record.date).getDay() === 0 ? 'rgba(255, 255, 255, 0.03)' :
                                   new Date(record.date).getDay() === 6 ? 'rgba(255, 255, 255, 0.03)' : 'transparent',
                    position: 'relative',
                    opacity: 0,
                    transform: 'translateY(10px)',
                    animation: 'fadeInUp 0.5s ease-out forwards',
                    '@keyframes fadeInUp': {
                      '0%': {
                        opacity: 0,
                        transform: 'translateY(10px)',
                      },
                      '100%': {
                        opacity: 1,
                        transform: 'translateY(0)',
                      },
                    },
                    animationDelay: `${0.5 + index * 0.05}s`,
                  }}
                >
                  {/* プレイ時間バー */}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      height: '1px',
                      backgroundColor: '#d9ff00',
                      opacity: 0.5,
                      width: `${playTimeRatio * 100}%`,
                      transform: 'scaleX(0)',
                      transformOrigin: 'left',
                      animation: 'growBar 1s ease-out forwards',
                      '@keyframes growBar': {
                        '0%': {
                          transform: 'scaleX(0)',
                        },
                        '100%': {
                          transform: 'scaleX(1)',
                        },
                      },
                      animationDelay: `${1 + index * 0.05}s`,
                    }}
                  />

                  {/* 既存のコンテンツ */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start', // 上揃えに変更
                    gap: { xs: 0, sm: 2 }
                  }}>
                    <Box sx={{ 
                      minWidth: { xs: '85px', sm: '100px' }
                    }}>
                      <Typography 
                        sx={{ 
                          fontSize: { xs: '0.7rem', sm: '0.75rem' },
                          color: 'text.secondary',
                        }}
                      >
                        {new Date(record.date).toLocaleDateString('ja-JP', {
                          month: 'numeric',
                          day: 'numeric',
                          weekday: 'short'
                        })}
                      </Typography>
                    </Box>
                    <Box sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5
                    }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: 1.5, sm: 2 }
                      }}>
                        <Typography 
                          sx={{ 
                            fontSize: { xs: '0.7rem', sm: '0.75rem' },
                          }}
                        >
                          {formatPlayTime(record.play_time)}
                        </Typography>
                        <Box sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap', 
                          gap: 0.5
                        }}>
                          {record.category?.map((catId) => (
                            <Chip
                              key={catId}
                              label={CATEGORIES[catId as keyof typeof CATEGORIES]?.label}
                              size="small"
                              sx={{
                                height: '16px',
                                fontSize: { xs: '0.55rem', sm: '0.6rem' },
                                backgroundColor: `${CATEGORIES[catId as keyof typeof CATEGORIES]?.color}44`,
                                color: 'rgba(255, 255, 255, 0.7)',
                                border: 'none',
                                borderRadius: '4px',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                },
                                '& .MuiChip-label': {
                                  px: 0.8,
                                  py: 0,
                                  letterSpacing: '0.02em'
                                }
                              }}
                            />
                          ))}
                        </Box>
                        
                        {/* 編集ボタン - 自分のプレイログの場合のみ表示 */}
                        {username === currentUser?.username && (
                          <IconButton 
                            size="small" 
                            onClick={() => handleEditRecord(record)}
                            sx={{ 
                              p: 0.5, 
                              ml: 'auto',
                              color: 'text.secondary',
                              opacity: 0.3,
                              '&:hover': { color: 'white' }
                            }}
                          >
                            <EditIcon sx={{ fontSize: '0.9rem' }} />
                          </IconButton>
                        )}
                      </Box>
                      {record.comment && (
                        <Typography 
                          sx={{ 
                            fontSize: { xs: '0.65rem', sm: '0.7rem' },
                            color: 'text.secondary'
                          }}
                        >
                          {record.comment}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                {index < playLog.daily_records.length - 1 && (
                  <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.08)' }} />
                )}
              </React.Fragment>
            );
          })}
        </Box>

        {/* PC用広告 - 固定配置 */}
        <Box sx={{ 
          display: { xs: 'none', md: 'block' },
          position: 'sticky',
          top: 80, // ヘッダー分のオフセット
          height: 'fit-content'
        }}>
          <AdvertisementResponsive maxWidth320={true} />
        </Box>
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="プレイログ概要"
        content={dialogContent}
        showCloseButton={false}
      />
      
      <Dialog
        open={isRecordDialogOpen}
        onClose={() => setIsRecordDialogOpen(false)}
        title="プレイログ編集"
        content={recordDialogContent}
        showCloseButton={false}
      />
    </Box>
  );
};

export default GamePlayLogPage;
