import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Grid, Divider, CircularProgress, Button, Skeleton } from '@mui/material';
import { getEvent, getEventGames, getBasicGameStatuses } from '../services/api';
import GameCard from '../components/GameCard';
import { Helmet } from 'react-helmet-async';
import { Game } from '../types/Game';
import LinkIcon from '@mui/icons-material/Link';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

interface Event {
  event_id: number;
  name: string;
  japanese_name: string | null;
  summary: string | null;
  start_date: string | null;
  end_date: string | null;
  event_url: string | null;
  youtube_id: string | null;
  thumbnail_url: string | null;
  hero_image_url: string | null;
}

interface EventGame {
  game: Game;
  youtube_id: string | null;
  summary: string | null;
  category: number;
}

const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [games, setGames] = useState<EventGame[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const [eventData, gamesData] = await Promise.all([
          getEvent(Number(eventId)),
          getEventGames(Number(eventId))
        ]);
        setEvent(eventData);
        setGames(gamesData);

        // ゲームのステータスを取得
        const token = localStorage.getItem('token');
        if (token) {
          const gameIds = gamesData.map((game: EventGame) => game.game.game_id);
          const statuses = await getBasicGameStatuses(gameIds);
          setGameStatuses(statuses);
        }
      } catch (err) {
        setError('イベント情報の取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventData();
    }
  }, [eventId]);

  const getCategoryTag = (category: number) => {
    switch (category) {
      case 1:
        return { label: '新情報', color: 'rgba(76, 175, 80, 0.15)', textColor: '#81c784' };
      case 2:
        return { label: '発表', color: 'rgba(33, 150, 243, 0.15)', textColor: '#64b5f6' };
      case 3:
        return { label: '発売日情報', color: 'rgba(255, 152, 0, 0.15)', textColor: '#ffb74d' };
      case 4:
        return { label: 'アップデート', color: 'rgba(156, 39, 176, 0.15)', textColor: '#ba68c8' };
      case 5:
        return { label: '追加コンテンツ', color: 'rgba(233, 30, 99, 0.15)', textColor: '#f06292' };
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ 
        position: 'relative',
        width: '100vw',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        bgcolor: '#121212',
        pb: 4
      }}>
        {/* ヒーローセクションのプレースホルダー */}
        <Box sx={{ 
          position: 'relative',
          height: { xs: '250px', md: '400px' },
          overflow: 'hidden',
          backgroundColor: 'rgba(255, 255, 255, 0.05)'
        }}>
          {/* グラデーションオーバーレイ */}
          <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '200px',
            background: 'linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.85) 70%, rgba(18, 18, 18, 1) 100%)',
            zIndex: 2
          }} />
        </Box>

        <Container maxWidth="lg" sx={{ px: 1.5, mt: { xs: -4, md: -12 }, position: 'relative', zIndex: 3 }}>
          <Grid container spacing={4}>
            {/* 左カラム: サムネイルと情報のプレースホルダー */}
            <Grid item xs={12} md={3.5}>
              <Box sx={{
                position: 'relative',
                width: { xs: '50%', md: '100%' },
                minWidth: { xs: '120px', md: 'auto' },
                maxWidth: { xs: '160px', md: '100%' },
              }}>
                <Skeleton 
                  variant="rectangular" 
                  width="100%" 
                  height={0}
                  sx={{ 
                    paddingTop: '56.25%', // 16:9 aspect ratio
                    borderRadius: '4px'
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <Skeleton width="60%" height={16} />
                  <Skeleton width="100%" height={20} sx={{ mt: 1 }} />
                  <Box sx={{ display: { xs: 'none', md: 'block' }, mt: 2 }}>
                    <Skeleton width="100%" height={80} />
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* 右カラム: メインコンテンツのプレースホルダー */}
            <Grid item xs={12} md={8.5}>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Skeleton width="80%" height={24} />
                <Skeleton width="100%" height={80} sx={{ mt: 1 }} />
              </Box>

              {/* 動画セクションのプレースホルダー */}
              {event?.youtube_id && (
                <Box sx={{ 
                  position: 'relative',
                  paddingBottom: '56.25%', // 16:9 aspect ratio
                  height: 0,
                  mb: 4
                }}>
                <Skeleton 
                  variant="rectangular" 
                  width="100%"
                  height="100%"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    borderRadius: '4px'
                  }}
                />
              </Box>
              )}

              <Typography variant="h6" component="h2" sx={{ 
                fontSize: '1rem',
                fontWeight: '500',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                ラインナップ
                <Typography variant="caption" sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.8rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  px: 1,
                  borderRadius: '4px'
                }}>
                  {games.length}作品
                </Typography>
              </Typography>

              {/* 実際のゲーム数に応じた高さを確保 */}
              <Grid container spacing={1}>
                {[...Array(100)].map((_, index) => (
                  <Grid item xs={4} sm={4} md={3} key={index}>
                    <Box sx={{ 
                      width: '100%',
                      visibility: index >= 36 ? 'hidden' : 'visible' // 36個目以降は非表示
                    }}>
                      <Box sx={{ position: 'relative', mb: 1 }}>
                        <Skeleton 
                          variant="rectangular" 
                          width="100%" 
                          height={0}
                          sx={{ paddingTop: '133.33%', borderRadius: 1 }}
                        />
                      </Box>
                      <Skeleton width="100%" height={16} />
                      <Skeleton width="60%" height={14} sx={{ marginBottom: 2 }} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  if (error || !event) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">
          {error || 'イベントが見つかりません'}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{event.japanese_name || event.name} | PULSE</title>
      </Helmet>
      <Box sx={{ 
        position: 'relative',
        width: '100vw',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        bgcolor: '#121212',
        pb: 4
      }}>
        {/* ヒーローセクション */}
        <Box sx={{ 
          position: 'relative',
          height: { xs: '250px', md: '400px' },
          overflow: 'hidden'
        }}>
          {event.hero_image_url && (
            <>
              {/* 背景ブラー画像 */}
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${event.hero_image_url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(45px)',
                opacity: 0.4,
                zIndex: 0
              }} />
              {/* メイン画像 */}
              <Box sx={{
                position: 'relative',
                height: '100%',
                maxWidth: 'lg',
                margin: '0 auto',
                zIndex: 1,
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  // maskImage: { xs: 'none', md: 'linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)' },
                  // WebkitMaskImage: { xs: 'none', md: 'linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)' }
                }
              }}>
                <img src={event.hero_image_url} alt={event.japanese_name || event.name} />
              </Box>
              {/* グラデーションオーバーレイ */}
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '200px',
                background: 'linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.85) 70%, rgba(18, 18, 18, 1) 100%)',
                zIndex: 2
              }} />
            </>
          )}
        </Box>

        {/* コンテンツセクション */}
        <Container maxWidth="lg" sx={{ px: 1.5, mt: { xs: -4, md: -12 }, position: 'relative', zIndex: 3 }}>
          {/* タイトルセクション - PCのみ表示 */}
          <Box sx={{ 
            display: { xs: 'none', md: 'block' },
            mb: 3
          }}>
            <Typography variant="h5" component="h1" sx={{ 
              fontSize: '1.35rem'
            }}>
              {event.japanese_name || event.name}
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {/* 左カラム: サムネイルと情報 */}
            <Grid item xs={12} md={3.5}>
              <Box sx={{
                position: 'relative',
                width: { xs: 'auto', md: '100%' },
                minWidth: { xs: '120px', md: 'auto' },
                maxWidth: { xs: '100%', md: '100%' },
                marginTop: { xs: '-80px', md: 0 }
              }}>
                {event.thumbnail_url && (
                  <Box sx={{
                    width: { xs: '50%', md: '100%' },
                    minWidth: { xs: '120px', md: 'auto' },
                    maxWidth: { xs: '160px', md: '100%' },
                    borderRadius: '4px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    '& img': {
                      width: '100%',
                      height: 'auto',
                      display: 'block'
                    }
                  }}>
                    <img src={event.thumbnail_url} alt={event.japanese_name || event.name} />
                  </Box>
                )}
                
                {/* イベント情報 */}
                <Box sx={{ mt: 2 }}>
                  {event.start_date && (
                    <Typography variant="body2" sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.7rem',
                      mb: 0
                    }}>
                      {(() => {
                        const startDate = new Date(event.start_date);
                        const endDate = event.end_date ? new Date(event.end_date) : null;
                        
                        const isSameDate = endDate && 
                          startDate.getFullYear() === endDate.getFullYear() &&
                          startDate.getMonth() === endDate.getMonth() &&
                          startDate.getDate() === endDate.getDate();

                        const startStr = startDate.toLocaleString('ja-JP', { 
                          year: 'numeric', 
                          month: '2-digit', 
                          day: '2-digit', 
                          hour: '2-digit', 
                          minute: '2-digit'
                        });

                        if (!endDate) {
                          return `${startStr} JST`;
                        }

                        const endStr = endDate.toLocaleString('ja-JP', {
                          year: isSameDate ? undefined : 'numeric',
                          month: isSameDate ? undefined : '2-digit',
                          day: isSameDate ? undefined : '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        });

                        return `${startStr} 〜 ${endStr}`;
                      })()}
                    </Typography>
                  )}
                  {event.event_url && (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<LinkIcon />}
                      href={event.event_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: '0.6rem',
                        textTransform: 'none',
                        backgroundColor: 'transparent',
                        color: 'pulse.info',
                        '& .MuiButton-startIcon': {
                          color: 'pulse.info',
                          marginRight: 0,
                          position: 'absolute',
                          left: 0
                        },
                        width: '100%',
                        position: 'relative',
                        paddingLeft: '20px',
                        py: 0
                      }}
                    >
                      <Box sx={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                        {event.event_url.replace(/^https?:\/\//, '').replace(/\/$/, '')}
                      </Box>
                    </Button>
                  )}
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {event.summary && (
                      <Typography variant="body2" sx={{ 
                        fontSize: '0.7rem',
                        lineHeight: { xs: '1.35', md: '1.35' },
                        color: 'text.secondary',
                        mt: 2,
                        whiteSpace: 'pre-wrap'
                      }}>
                        {event.summary}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* 右カラム: メインコンテンツ */}
            <Grid item xs={12} md={8.5}>
              {/* スマホ用タイトル */}
              <Box sx={{ display: { xs: 'block', md: 'none' }, mt: { xs: -1, md: 0 } }}>
                <Typography variant="h5" component="h1" sx={{ 
                  fontSize: '1rem',
                  mb: 1
                }}>
                  {event.japanese_name || event.name}
                </Typography>
              </Box>

              {/* スマホ用概要 */}
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {event.summary && (
                  <Typography variant="body2" sx={{ 
                    fontSize: '0.8rem',
                    lineHeight: { xs: '1.35', md: '1.35' },
                    color: 'text.secondary',
                    mb: 2,
                    whiteSpace: 'pre-wrap'
                  }}>
                    {event.summary}
                  </Typography>
                )}
              </Box>

              {event.youtube_id && (
                <Box sx={{ 
                  position: 'relative',
                  paddingBottom: '56.25%',
                  height: 0,
                  mb: 4
                }}>
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '4px'
                    }}
                    src={`https://www.youtube.com/embed/${event.youtube_id}`}
                    title="Event Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
              )}

              <Typography variant="h6" component="h2" sx={{ 
                fontSize: '0.9rem',
                fontWeight: '400',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                ラインナップ
                <Typography variant="caption" sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.8rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  px: 1,
                  borderRadius: '4px'
                }}>
                  {games.length}作品
                </Typography>
              </Typography>

              {/* ラインナップが0作品の場合のメッセージ */}
              {games.length === 0 && (
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.8rem',
                  mb: 2
                }}>
                  このイベントにはまだゲームが登録されていません。
                </Typography>
              )}

              <Grid container spacing={1}>
                {games.map((eventGame, index) => {
                  const tag = getCategoryTag(eventGame.category);
                  
                  // 現在のカードが各ブレイクポイントでの行の先頭か判定
                  const isFirstInRowXs = index % 3 === 0;
                  const isFirstInRowSm = index % 3 === 0;
                  const isFirstInRowMd = index % 4 === 0;
                  
                  // 現在の行のカードグループを取得（ブレイクポイントごと）
                  let rowGamesXs = games.slice(Math.floor(index / 3) * 3, Math.floor(index / 3) * 3 + 3);
                  let rowGamesSm = games.slice(Math.floor(index / 3) * 3, Math.floor(index / 3) * 3 + 3);
                  let rowGamesMd = games.slice(Math.floor(index / 4) * 4, Math.floor(index / 4) * 4 + 4);
                  
                  // 各行にタグを持つカードがあるか確認
                  const rowHasTagXs = rowGamesXs.some(game => !!getCategoryTag(game.category));
                  const rowHasTagSm = rowGamesSm.some(game => !!getCategoryTag(game.category));
                  const rowHasTagMd = rowGamesMd.some(game => !!getCategoryTag(game.category));
                  
                  return (
                    <Grid item xs={4} sm={4} md={3} key={eventGame.game.game_id}>
                      <Box sx={{ 
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 0.5, md: 1 }
                      }}>
                        <Box
                          sx={{
                            height: { xs: '20px', md: '24px' },
                            mb: { xs: 0.25, md: 0 },
                            display: { 
                              xs: rowHasTagXs ? 'inline-flex' : 'none',
                              sm: rowHasTagSm ? 'inline-flex' : 'none',
                              md: rowHasTagMd ? 'inline-flex' : 'none'
                            },
                            ...(tag ? {
                              backgroundColor: tag.color,
                              backdropFilter: 'blur(8px)',
                              border: `1px solid ${tag.textColor}40`,
                              color: tag.textColor,
                              px: 1,
                              py: { xs: 0.5, md: 0.5 },
                              borderRadius: '4px',
                              fontSize: { xs: '0.6rem', md: '0.675rem' },
                              fontWeight: 500,
                              letterSpacing: '0.02em',
                              alignItems: 'center',
                              lineHeight: 1,
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                backgroundColor: `${tag.color.replace('0.15', '0.25')}`,
                              },
                              width: '100%',
                            } : {
                              visibility: 'hidden'
                            })
                          }}
                        >
                          {tag?.label || ''}
                        </Box>
                        <GameCard 
                          game={eventGame.game} 
                          initialStatus={gameStatuses[eventGame.game.game_id]}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EventPage; 