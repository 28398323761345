import React from 'react';
import { createBrowserRouter, RouterProvider, ScrollRestoration, Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box, Container, GlobalStyles } from '@mui/material';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import GameDetailsPage from './pages/GameDetailsPage';
import SearchPage from './pages/SearchPage';
import PlatformListPage from './pages/categories/PlatformListPage';
import GenreListPage from './pages/categories/GenreListPage';
import GameListByCategory from './pages/categories/GameListByCategory';
import GameEngineListPage from './pages/categories/GameEngineListPage';
import ThemeListPage from './pages/categories/ThemeListPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ProfileEditPage from './pages/ProfileEditPage'; // この行を追加
import PublicProfilePage from './pages/PublicProfilePage';
import GameReviewsPage from './pages/GameReviewsPage';
import ProfileSettingsPage from './pages/ProfileSettingsPage';
import TrendingNewReleasesPage from './pages/gamelist/TrendingNewReleasesPage';
import PopularGamesByYearPage from './pages/PopularGamesByYearPage';
import { HelmetProvider } from 'react-helmet-async';
import PrivacyPolicyPage from './pages/policies/PrivacyPolicyPage';
import TermsOfServicePage from './pages/policies/TermsOfServicePage';
import AboutPage from './pages/AboutPage';
import WhyRecordPage from './pages/WhyRecordPage';
import GameStoryPage from './pages/contents/story/GameStoryPage';
import CreateGameStoryPage from './pages/contents/story/CreateGameStoryPage';
import EditGameStoryPage from './pages/contents/story/EditGameStoryPage';
import GameStoryDetailPage from './pages/contents/story/GameStoryDetailPage';
import AllGameStoriesPage from './pages/contents/story/AllGameStoriesPage';
import GameImagesPage from './pages/GameImagesPage';
import MaintenancePage from './pages/MaintenancePage';
import { maintenanceConfig } from './config/maintenance';
import GameListDetailPage from './pages/GameListDetailPage';
import EditGameListPage from './pages/EditGameListPage';
import GamePhraseDetailPage from './pages/contents/phrase/GamePhraseDetailPage';
import GamePhraseEditPage from './pages/contents/phrase/GamePhraseEditPage';
import GameTriviaDetailPage from './pages/contents/trivia/GameTriviaDetailPage';
import GameTriviaEditPage from './pages/contents/trivia/GameTriviaEditPage';
import GameReviewDetailPage from './pages/GameReviewDetailPage';
import ReleaseCalendarPage from './pages/ReleaseCalendarPage';
import MobileBottomNav from './components/MobileBottomNav';
import { isPwa } from './utils/isPwa';
import InstallPwaPage from './pages/InstallPwaPage';
import DiscordCallback from './components/auth/DiscordCallback';
import PopularGamesByYearStatusPage from './pages/PopularGamesByYearStatusPage';
import AnticipatedGamesPage from './pages/gamelist/AnticipatedGamesPage';
import ReleaseNotesPage from './pages/ReleaseNotesPage';
import GamePlayLogPage from './pages/GamePlayLogPage';
import SteamCallback from './pages/auth/SteamCallback';
import EventPage from './pages/EventPage';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        overflowX: 'hidden',
      },
    }}
  />
);

const StandardLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ 
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <Header />
    <Box 
      sx={{ 
        paddingTop: { xs: '56px', sm: '64px' },
        paddingBottom: { xs: isPwa() ? '80px' : 0, sm: isPwa() ? '80px' : 0 },
        flex: 1
      }}
    >
      <Container maxWidth="lg" sx={{ px: 1.5, py: 4 }}>
        {children}
      </Container>
    </Box>
    {isPwa() && <MobileBottomNav />}
    {!isPwa() && <Footer />}
  </Box>
);

const HomeLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ 
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <Header />
    <Box sx={{ 
      paddingBottom: { xs: isPwa() ? '80px' : 0, sm: isPwa() ? '80px' : 0 },
      flex: 1
    }}>
      {children}
    </Box>
    {isPwa() && <MobileBottomNav />}
    {!isPwa() && <Footer />}
  </Box>
);

const FreeLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ 
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <Header />
    <Box sx={{ 
      paddingTop: { xs: '56px', sm: '64px' },
      paddingBottom: { xs: isPwa() ? '80px' : 0, sm: isPwa() ? '80px' : 0 },
      flex: 1
    }}>
      {children}
    </Box>
    {isPwa() && <MobileBottomNav />}
    {!isPwa() && <Footer />}
  </Box>
);

const router = createBrowserRouter([
  {
    element: (
      <>
        <ScrollRestoration />
        <Outlet />
      </>
    ),
    children: [
      {
        path: '/',
        element: <HomeLayout><HomePage /></HomeLayout>,
      },
      {
        path: '/game/:game_id',
        element: <HomeLayout><GameDetailsPage /></HomeLayout>,
      },
      {
        path: '/search',
        element: <StandardLayout><SearchPage /></StandardLayout>,
      },
      {
        path: '/platforms',
        element: <StandardLayout><PlatformListPage /></StandardLayout>,
      },
      {
        path: '/genres',
        element: <StandardLayout><GenreListPage /></StandardLayout>,
      },
      {
        path: '/games/:type/:id',
        element: <StandardLayout><GameListByCategory /></StandardLayout>,
      },
      {
        path: '/game-engines',
        element: <StandardLayout><GameEngineListPage /></StandardLayout>,
      },
      {
        path: '/themes',
        element: <StandardLayout><ThemeListPage /></StandardLayout>,
      },
      {
        path: '/login',
        element: <FreeLayout><LoginPage /></FreeLayout>,
      },
      {
        path: '/profile',
        element: <StandardLayout><ProfilePage /></StandardLayout>,
      },
      {
        path: '/profile/settings',
        element: <StandardLayout><ProfileSettingsPage /></StandardLayout>,
      },
      {
        path: '/profile/:username',
        element: <StandardLayout><PublicProfilePage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/reviews',
        element: <StandardLayout><GameReviewsPage /></StandardLayout>,
      },
      {
        path: '/trending-new-releases',
        element: <StandardLayout><TrendingNewReleasesPage /></StandardLayout>,
      },
      {
        path: '/popular-games/:year',
        element: <StandardLayout><PopularGamesByYearPage /></StandardLayout>,
      },
      {
        path: '/popular-games-status/:year',
        element: <FreeLayout><PopularGamesByYearStatusPage /></FreeLayout>,
      },
      {
        path: '/privacy-policy',
        element: <StandardLayout><PrivacyPolicyPage /></StandardLayout>,
      },
      {
        path: '/terms-of-service',
        element: <StandardLayout><TermsOfServicePage /></StandardLayout>,
      },
      {
        path: '/about',
        element: <StandardLayout><AboutPage /></StandardLayout>,
      },
      {
        path: '/why-record',
        element: <StandardLayout><WhyRecordPage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/stories',
        element: <StandardLayout><GameStoryPage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/stories/create',
        element: <StandardLayout><CreateGameStoryPage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/stories/:storyId/edit',
        element: <StandardLayout><EditGameStoryPage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/stories/:storyId',
        element: <FreeLayout><GameStoryDetailPage /></FreeLayout>,
      },
      {
        path: '/stories',
        element: <StandardLayout><AllGameStoriesPage /></StandardLayout>,
      },
      {
        path: '/game/:game_id/images',
        element: <FreeLayout><GameImagesPage /></FreeLayout>,
      },
      {
        path: '/maintenance',
        element: <FreeLayout><MaintenancePage /></FreeLayout>,
      },
      {
        path: '/list/:id',
        element: <StandardLayout><GameListDetailPage /></StandardLayout>,
      },
      {
        path: '/list/:listId/edit',
        element: <FreeLayout><EditGameListPage /></FreeLayout>,
      },
      {
        path: '/phrase/:phraseId',
        element: <FreeLayout><GamePhraseDetailPage /></FreeLayout>,
      },
      {
        path: '/phrase/:phraseId/edit',
        element: <FreeLayout><GamePhraseEditPage /></FreeLayout>,
      },
      {
        path: '/trivia/:triviaId',
        element: <FreeLayout><GameTriviaDetailPage /></FreeLayout>,
      },
      {
        path: '/trivia/:triviaId/edit',
        element: <FreeLayout><GameTriviaEditPage /></FreeLayout>,
      },
      {
        path: '/game/:gameId/reviews/:reviewId',
        element: <FreeLayout><GameReviewDetailPage /></FreeLayout>,
      },
      {
        path: '/release-calendar',
        element: <StandardLayout><ReleaseCalendarPage /></StandardLayout>,
      },
      {
        path: '/install-pwa',
        element: <StandardLayout><InstallPwaPage /></StandardLayout>,
      },
      {
        path: '/auth/discord/callback',
        element: <DiscordCallback />,
      },
      {
        path: '/anticipated-games',
        element: <StandardLayout><AnticipatedGamesPage /></StandardLayout>,
      },
      {
        path: '/release-notes',
        element: <StandardLayout><ReleaseNotesPage /></StandardLayout>,
      },
      {
        path: '/game/:gameId/playlog/:username?',
        element: <StandardLayout><GamePlayLogPage /></StandardLayout>,
      },
      {
        path: '/auth/steam/callback',
        element: <FreeLayout><SteamCallback /></FreeLayout>,
      },
      {
        path: '/event/:eventId',
        element: <FreeLayout><EventPage /></FreeLayout>,
      },
    ],
  },
]);

function App() {
  // メンテナンスモードの場合、MaintenancePageのみを表示
  if (maintenanceConfig.isUnderMaintenance && window.location.pathname !== '/maintenance') {
    return (
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {globalStyles}
          <MaintenancePage />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {globalStyles}
          <RouterProvider router={router} />
        </ThemeProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
