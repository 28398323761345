import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField, Slider, Chip, Menu, MenuItem, InputAdornment, useMediaQuery, useTheme, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Tips from './dialogs/Tips';
import LifeStageSelector from './LifeStageSelector';
import StyledTextField from './common/StyledTextField';
import { Platform } from '../types/Game';
import { GameReviewData } from '../types/Review';
import { setGameReview, getPlatforms, getGamePlatforms, getUserBirthDate } from '../services/api';
import ReviewPreviewDialog from './dialogs/ReviewPreviewDialog';

interface GameReviewDialogProps {
  open: boolean;
  onClose: () => void;
  gameId: number;
  initialData: GameReviewData;
  platforms: Platform[];
  releaseDate?: string;
  userBirthDate?: string;
  onReviewSaved?: (reviewData: GameReviewData) => void;
}

const GameReviewDialog: React.FC<GameReviewDialogProps> = ({
  open,
  onClose,
  gameId,
  initialData,
  platforms: initialPlatforms,
  releaseDate,
  userBirthDate: initialBirthDate,
  onReviewSaved
}) => {
  const [tempData, setTempData] = useState<GameReviewData>(initialData);
  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>(initialPlatforms);
  const [userBirthDate, setUserBirthDate] = useState<string | null>(initialBirthDate || null);
  const [isDialogDirty, setIsDialogDirty] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        const [availablePlatformsData, gamePlatforms, birthDate] = await Promise.all([
          getPlatforms(),
          !initialPlatforms.length ? getGamePlatforms(gameId) : Promise.resolve(initialPlatforms),
          !initialBirthDate ? getUserBirthDate() : Promise.resolve(initialBirthDate)
        ]);

        setAvailablePlatforms(availablePlatformsData);
        setPlatforms(gamePlatforms);
        setUserBirthDate(birthDate);

        setTempData(prev => ({
          ...prev,
          platform: prev.platform.length > 0 
            ? prev.platform 
            : (gamePlatforms.length === 1 ? [gamePlatforms[0].platform_id] : [])
        }));
      };

      fetchData();
    }
  }, [open, initialData, initialPlatforms, initialBirthDate, gameId]);

  const handleSave = async () => {
    try {
      await setGameReview(
        gameId,
        tempData.score,
        tempData.reviewText,
        tempData.playStartDate,
        tempData.playEndDate,
        tempData.playTime,
        tempData.scoreTags,
        tempData.platform
      );
      
      onReviewSaved?.(tempData);
      onClose();
    } catch (error) {
      console.error('レビューの保存に失敗しました', error);
    }
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [platformMenuAnchorEl, setPlatformMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [platformSearch, setPlatformSearch] = React.useState('');

  const scoreMarks = [
    { value: 1, label: '1.0' },
    { value: 2, label: 'poor' },
    { value: 3, label: '3.0' },
    { value: 4, label: 'good' },
    { value: 5, label: '5.0' },
  ];

  const handleScoreChange = (event: Event, newValue: number | number[]) => {
    setTempData({ ...tempData, score: newValue as number });
    setIsDialogDirty(true);
  };

  const handleScoreTagsChange = (tagId: number) => {
    const newTempScoreTags = tempData.scoreTags.includes(tagId)
      ? tempData.scoreTags.filter((id) => id !== tagId).sort((a, b) => a - b)
      : tempData.scoreTags.length >= 3
        ? tempData.scoreTags
        : [...tempData.scoreTags, tagId].sort((a, b) => a - b);
    setTempData({ ...tempData, scoreTags: newTempScoreTags });
    setIsDialogDirty(true);
  };

  const handlePlatformChange = (platformId: number) => {
    const newPlatform = tempData.platform.includes(platformId)
      ? tempData.platform.filter(id => id !== platformId).sort((a, b) => a - b)
      : tempData.platform.length >= 2
        ? tempData.platform
        : [...tempData.platform, platformId].sort((a, b) => a - b);
    
    setTempData({ ...tempData, platform: newPlatform });
    setIsDialogDirty(true);
  };

  const handlePlatformMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPlatformMenuAnchorEl(event.currentTarget);
  };

  const handlePlatformMenuClose = () => {
    setPlatformMenuAnchorEl(null);
    setPlatformSearch('');
  };

  const handlePlatformSelect = (platformId: number) => {
    handlePlatformChange(platformId);
    handlePlatformMenuClose();
  };

  const filteredPlatforms = availablePlatforms
    .filter(p => !platforms.some(defaultP => defaultP.platform_id === p.platform_id))
    .filter(p => 
      p.name.toLowerCase().includes(platformSearch.toLowerCase())
    );

  const getScoreText = (score: number | null | undefined) => {
    if (score === null || score === undefined) {
      return '未評価';
    }
    const numericScore = typeof score === 'string' ? parseFloat(score) : score;
    return isNaN(numericScore) ? '未評価' : numericScore.toFixed(1);
  };

  const reviewCorrectionTips = (
    <Box>
      <Typography variant="body2" paragraph>
        ・スコア補正とは、あなたの評価に大きく影響を与えている可能性のある要因を示すものです。
      </Typography>
      <Typography variant="body2" paragraph>
        ・確信がなくても構いません。「もしかしたら」という程度の直感も大切です。
      </Typography>
      <Typography variant="body2" paragraph>
        ・自分では気づきにくいバイアスもあります。他ユーザーとの評価に乖離を感じる状況などで自問し、思い当たる点があれば選択してください。
      </Typography>
      <Typography variant="body2" paragraph>
        ・これはスコアの妥当性を示すものではなく、あなたの評価の背景やゲーム体験を他のユーザーに表明するためのものです。
      </Typography>
      <Typography variant="body2" paragraph>
        ・正直に、かつ慎重に選択することで、あなたのレビューの信頼性と他のユーザーの理解が深まります。
      </Typography>
      <Typography variant="body2" paragraph>
        ・自身の評価プロセスを振り返ることで、より深い洞察が得られるかもしれません。
      </Typography>
      <Typography variant="body2" paragraph>
        ・全てのレビューで選択する必要はありません。スコアに大きく関与している可能性がある場合でのみ設定してください。
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Chip
            label="コンテキスト補正"
            color="primary"
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Typography variant="body2">
            ゲームを取り巻く背景や状況を考慮に入れた評価要因です。
            個人的な趣向や体験とは異なり、より広い文脈でゲームの価値を評価する際に使用します。
          </Typography>
        </Box>
        <Box>
          <Chip
            label="エントリー補正"
            color="primary"
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Typography variant="body2">
            あなたにとって新しい、または不慣れなゲーム要素が評価に影響を与えている可能性を示すものです。初めて遊ぶジャンルやシリーズは新鮮さや驚きにより、同じ領域のゲームと比較してスコアが高くなる傾向があります。
          </Typography>
        </Box>
      </Box>  
    </Box>
  );

  const reviewFormatTips = (
    <Box>
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block', 
          mb: 0.5,
          color: 'text.secondary',
          fontStyle: 'italic',
          lineHeight: 1.2
        }}
      >
        Tips：コードをクリックでクリップボードにコピーできます
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {[
          {
            title: '見出し',
            examples: [
              { syntax: '[h1]ヘッダー[/h1]', result: 'ヘッダー' },
              { syntax: '[h2]ヘッダー[/h2]', result: 'ヘッダー' },
              // { syntax: '[h3]小見出し[/h3]', result: '小見出し' }
            ]
          },
          {
            title: 'テキスト装飾',
            examples: [
              { syntax: '[b]太字[/b]', result: '太字' },
              { syntax: '[i]斜体[/i]', result: '斜体' },
              { syntax: '[s]取り消し線[/s]', result: '取り消し線' },
              { syntax: '[u]下線[/u]', result: '下線' }
            ]
          },
          {
            title: 'リスト',
            examples: [
              { syntax: '[list]\n[*]リスト項目\n[*]リスト項目\n[/list]', result: '箇条書き' },
              { syntax: '[olist]\n[*]リスト項目\n[*]リスト項目\n[/olist]', result: '番号付きリスト' }
            ]
          },
          {
            title: 'その他',
            examples: [
              { syntax: '[quote]引用文[/quote]', result: '引用ブロック' },
              { syntax: '[spoiler]ネタバレ[/spoiler]', result: 'ネタバレ' },
            ]
          }
        ].map((section) => (
          <Box key={section.title}>
            <Typography variant="subtitle2" sx={{ mb: 1, color: 'primary.main' }}>
              {section.title}
            </Typography>
            {section.examples.map((example, index) => (
              <Box
                key={index}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                  gap: 1,
                  mb: 0.5,
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                  }
                }}
              >
                <Box
                  component="code"
                  onClick={() => navigator.clipboard.writeText(example.syntax)}
                  sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                    p: 0.5,
                    borderRadius: 1,
                    fontFamily: 'monospace',
                    fontSize: '0.65rem',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'rgba(0, 0, 0, 0.4)',
                    }
                  }}
                >
                  {example.syntax}
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {example.result}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Dialog 
        open={open} 
        onClose={() => onClose()}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>ゲームレビュー</DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2 }}>
            <Typography gutterBottom>スコア（3.0が普通）</Typography>
            <Slider
              value={tempData.score ?? 3}
              onChange={handleScoreChange}
              aria-labelledby="game-score-slider"
              step={0.1}
              marks={scoreMarks}
              min={1}
              max={5}
              valueLabelDisplay="auto"
              sx={{
                '& .MuiSlider-thumb': {
                  color: tempData.score !== null ? 'primary.main' : 'grey.500',
                },
                '& .MuiSlider-track': {
                  color: tempData.score !== null ? 'primary.main' : 'grey.500',
                },
                '& .MuiSlider-rail': {
                  color: tempData.score !== null ? 'primary.light' : 'grey.300',
                },
              }}
            />
            <Typography gutterBottom>
              現在のスコア: {getScoreText(tempData.score)}
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            alignItems: 'center',
            mt: -2 
          }}>
            <Button
              color="primary"
              size="small"
              onClick={() => setPreviewOpen(true)}
              disabled={!tempData.reviewText}
              sx={{ 
                fontSize: { xs: "0.55rem", sm: "0.6rem" },
                minWidth: 'auto',
                p: '2px 8px'
              }}
            >
              プレビュー
            </Button>
            <Tips 
              title="テキストフォーマット" 
              content={reviewFormatTips}
              buttonComponent={
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    fontSize: { xs: "0.55rem", sm: "0.6rem" },
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  使用可能フォーマット
                </Typography>
              }
            />
          </Box>
          <Box sx={{ mt: 0.25, mb: 2 }}>
            <StyledTextField
              id="review"
              label="レビュー"
              value={tempData.reviewText || ''}
              onChange={(value) => {
                setTempData({ ...tempData, reviewText: value });
                setIsDialogDirty(true);
              }}
              multiline
              minRows={fullScreen ? 6 : 4}
              maxRows={12}
              placeholder="レビューを入力してください"
            />
          </Box>

          <Box sx={{ my: 2 }}>
            <Box sx={{ mb: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  label="プレイ開始日"
                  type="date"
                  value={tempData.playStartDate || ''}
                  onChange={(e) => {
                    const input = e.target.value;
                    setTempData({ ...tempData, playStartDate: input || null });
                    setIsDialogDirty(true);
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ max: "9999-12-31" }}
                  size="small"
                  sx={{ flex: 1 }}
                />
                <LifeStageSelector
                  birthDate={userBirthDate}
                  targetDate={releaseDate}
                  onSelect={(date) => {
                    setTempData({ ...tempData, playStartDate: date });
                    setIsDialogDirty(true);
                  }}
                  mode="start"
                />
              </Box>
              {!tempData.playStartDate && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ display: 'block', mt: 0.5, ml: 0.5, lineHeight: 1.2 }}
                >
                  ライフステージセレクターで特定の時期に相当する日付を簡単に設定できます。
                </Typography>
              )}
              {tempData.playStartDate && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                  <TextField
                    label="プレイ終了日"
                    type="date"
                    value={tempData.playEndDate || ''}
                    onChange={(e) => {
                      const input = e.target.value;
                      setTempData({ ...tempData, playEndDate: input || null });
                      setIsDialogDirty(true);
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ 
                      max: "9999-12-31",
                      min: tempData.playStartDate
                    }}
                    size="small"
                    sx={{ flex: 1 }}
                  />
                  <LifeStageSelector
                    birthDate={userBirthDate}
                    targetDate={releaseDate}
                    onSelect={(date) => {
                      setTempData({ ...tempData, playEndDate: date });
                      setIsDialogDirty(true);
                    }}
                    mode="end"
                    startDate={tempData.playStartDate}
                  />
                </Box>
              )}
            </Box>
            
            <TextField
              margin="dense"
              id="playTime"
              label="プレイ時間"
              type="number"
              fullWidth
              value={tempData.playTime || ''}
              onChange={(e) => {
                const value = e.target.value;
                setTempData({ 
                  ...tempData, 
                  playTime: value === '' ? null : Number(value)  // 空文字列の場合はnullを設定
                });
                setIsDialogDirty(true);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">時間</InputAdornment>,
              }}
              size="small"
            />
          </Box>

          <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <Typography>プラットフォーム</Typography>
              <Typography 
                color="text.secondary" 
                variant="body2"
                sx={{ ml: 1 }}
              >
                {tempData.platform.length}/2
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              {platforms.map((platform) => (
                <Chip
                  key={platform.platform_id}
                  label={platform.name}
                  onClick={() => handlePlatformChange(platform.platform_id)}
                  color={tempData.platform.includes(platform.platform_id) ? 'primary' : 'default'}
                  size="small"
                  disabled={!tempData.platform.includes(platform.platform_id) && tempData.platform.length >= 2}
                />
              ))}
              
              {availablePlatforms
                .filter(p => 
                  !platforms.some(defaultP => defaultP.platform_id === p.platform_id) &&
                  tempData.platform.includes(p.platform_id)
                )
                .map((platform) => (
                  <Chip
                    key={platform.platform_id}
                    label={platform.name}
                    onDelete={() => handlePlatformChange(platform.platform_id)}
                    color="primary"
                    size="small"
                  />
                ))}
              
              <Chip
                icon={<AddIcon />}
                label="その他"
                onClick={handlePlatformMenuOpen}
                variant="outlined"
                size="small"
                sx={{ cursor: 'pointer' }}
                disabled={tempData.platform.length >= 2}
              />

              <Menu
                anchorEl={platformMenuAnchorEl}
                open={Boolean(platformMenuAnchorEl)}
                onClose={handlePlatformMenuClose}
                PaperProps={{
                  style: {
                    maxHeight: 300,
                    width: '300px',
                  },
                }}
              >
                <Box sx={{ p: 1 }}>
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                    リメイクやリマスター版・移植版などは別タイトルとしてデータベースに登録されている可能性があることにご留意ください。適切ではないプラットフォームの追加は他ユーザーの混乱を招く恐れがあります。
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="プラットフォームを検索"
                    value={platformSearch}
                    onChange={(e) => setPlatformSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Box>
                {filteredPlatforms.length === 0 ? (
                  <MenuItem disabled>
                    該当するプラットフォームがありません
                  </MenuItem>
                ) : (
                  filteredPlatforms.map((platform) => (
                    <MenuItem
                      key={platform.platform_id}
                      onClick={() => handlePlatformSelect(platform.platform_id)}
                      selected={tempData.platform.includes(platform.platform_id)}
                    >
                      {platform.name}
                    </MenuItem>
                  ))
                )}
              </Menu>
            </Box>
          </Box>

          <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <Typography>スコア補正</Typography>
              <Tips title="スコア補正について" content={reviewCorrectionTips} />
              <Typography 
                color="text.secondary" 
                variant="body2"
                sx={{ ml: 1 }}
              >
                {tempData.scoreTags.length}/3
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {[
                { id: 1, label: '趣味趣向補正' },
                { id: 2, label: '思い出補正' },
                { id: 3, label: 'IP・シリーズ補正' },
                { id: 4, label: 'デベロッパー補正' },
                { id: 5, label: 'ソフトウェアバージョン補正' },
                { id: 6, label: 'マルチプレイ補正' },
                { id: 7, label: 'エントリー補正' },
                { id: 8, label: 'コンテキスト補正' },
                { id: 9, label: '下方バイアス' },
                { id: 0, label: 'その他の上方バイアス' },
              ].map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.label}
                  onClick={() => handleScoreTagsChange(tag.id)}
                  color={tempData.scoreTags.includes(tag.id) ? 'primary' : 'default'}
                  size="small"
                  disabled={!tempData.scoreTags.includes(tag.id) && tempData.scoreTags.length >= 3}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">保存</Button>
          <Button onClick={() => onClose()} color="inherit">キャンセル</Button>
        </DialogActions>
      </Dialog>

      <ReviewPreviewDialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        reviewText={tempData.reviewText || ''}
      />
    </>
  );
};

export default GameReviewDialog;