import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { steamCallback } from '../../services/api';

const SteamCallback: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSteamCallback = async () => {
      try {
        // Steam認証からのすべてのパラメータを取得
        const params = new URLSearchParams(location.search);
        
        // パラメータが空の場合はエラー
        if (!params.toString()) {
          throw new Error('認証パラメータが見つかりません');
        }

        // URLパラメータからモードを取得
        const isLoginMode = params.get('mode') === 'login';
        
        const response = await steamCallback(params.toString());
        
        if (isLoginMode) {
          // ログインモードの場合、トークンを保存
          if (response.data.access_token) {
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            
            // ログイン成功イベントを発火
            window.dispatchEvent(new Event('login'));
            
            // ホームページにリダイレクト
            setTimeout(() => {
              navigate('/', { 
                state: { message: 'Steamアカウントでログインしました' }
              });
            }, 2000);
          }
        } else {
          // 連携モードの場合
          if (response.data.steam_id) {
            // 成功したらプロフィールページに戻る
            setTimeout(() => {
              navigate('/profile/settings', { 
                state: { message: 'Steamアカウントの連携に成功しました' }
              });
            }, 2000);
          } else {
            throw new Error('Steam IDの取得に失敗しました');
          }
        }
        
      } catch (err: any) {
        console.error('Steam認証エラー:', err);
        setError(err.response?.data?.error || err.message || 'Steam認証に失敗しました');
      } finally {
        setLoading(false);
      }
    };
    
    handleSteamCallback();
  }, [location, navigate]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
      p: 3
    }}>
      {loading ? (
        <>
          <CircularProgress size={60} sx={{ mb: 3 }} />
          <Typography variant="h6">
            {new URLSearchParams(location.search).get('mode') === 'login'
              ? 'PULSEライブラリとの連携を確認中...'
              : 'Steamアカウントの連携処理中...'}
          </Typography>
        </>
      ) : error ? (
        <Alert severity="error" sx={{ maxWidth: 500 }}>
          {error}
        </Alert>
      ) : (
        <Alert 
          severity="success" 
          sx={{ 
            maxWidth: 500,
            background: 'linear-gradient(135deg, rgba(23, 26, 33, 0.95), rgba(42, 71, 94, 0.95))',
            color: '#ffffff',
            border: '1px solid rgba(102, 192, 244, 0.2)',
            backdropFilter: 'blur(8px)',
            '& .MuiAlert-icon': {
              color: '#66c0f4'
            }
          }}
        >
          {new URLSearchParams(location.search).get('mode') === 'login'
            ? 'ログインに成功しました。正常にPULSEライブラリと連携されています。'
            : 'Steamアカウントの連携に成功しました。プロフィールページに戻ります。'
          }
        </Alert>
      )}
    </Box>
  );
};

export default SteamCallback; 