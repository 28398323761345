import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Grid, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { getEventPickup } from '../../services/api';

interface Event {
  event_id: number;
  name: string;
  japanese_name: string | null;
  summary: string | null;
  start_date: string;
  end_date: string | null;
  event_url: string | null;
  youtube_id: string | null;
  thumbnail_url: string | null;
  hero_image_url: string | null;
}

// 日付をフォーマットする関数
const formatEventDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}月${day}日`;
};

const EventPickup: React.FC = () => {
  const [events, setEvents] = React.useState<Event[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await getEventPickup();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {[1, 2, 3].map((i) => (
            <Grid 
              item 
              xs={i === 1 ? 12 : 6}
              md={4} 
              key={i}
            >
              <Box sx={{ height: '100%' }}>
                <Skeleton 
                  variant="rectangular" 
                  sx={{ 
                    width: '100%',
                    height: 0,
                    paddingTop: '56.25%', // 16:9のアスペクト比
                    borderRadius: '8px',
                    mb: 0.5
                  }} 
                />
                <Skeleton 
                  variant="text" 
                  width="80%" 
                  sx={{ 
                    height: { xs: 18, sm: 20 }
                  }} 
                />
                <Skeleton 
                  variant="text" 
                  width="40%" 
                  sx={{ 
                    height: { xs: 15, sm: 15 },
                    mb: { xs: 1, sm: 1 }
                  }} 
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (events.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mb: 2 }}>
      {/* <Typography variant="h5" sx={{ mb: 2 }}>Pickup</Typography> */}
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {events.slice(0, 3).map((event, index) => (
          <Grid 
            item 
            xs={index === 0 ? 12 : 6}
            md={4} 
            key={event.event_id}
          >
            <Card 
              component={Link} 
              to={`/event/${event.event_id}`}
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: { xs: 'none', lg: 'translateY(-3px)' },
                },
                backgroundColor: 'background.default',
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: 'none',
                textDecoration: 'none'
              }}
            >
              <CardMedia
                component="img"
                image={event.hero_image_url || event.thumbnail_url || '/placeholder.jpg'}
                alt={event.japanese_name || event.name}
                sx={{ 
                  height: 'auto',
                  width: '100%',
                  aspectRatio: '16/9',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  position: 'relative',
                }}
              />
              <CardContent sx={{ 
                flexGrow: 1, 
                p: 0, 
                pt: 0.5, 
                backgroundColor: 'background.default', 
                '&:last-child': { pb: 0 } 
              }}>
                <Typography 
                  variant="subtitle2" 
                  component="div"
                  sx={{
                    fontSize: { xs: '0.65rem', sm: '0.75rem' },
                    fontWeight: '600',
                    color: 'text.primary',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {event.japanese_name || event.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    fontSize: { xs: '0.6rem', sm: '0.7rem' },
                    mb: 1
                  }}
                >
                  {new Date(event.start_date).toLocaleDateString('ja-JP', {
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventPickup; 