import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, Link, Pagination, PaginationItem, Paper } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { getUserPlayLogs } from '../services/api';
import { formatTotalPlayTime, formatPlayTime } from '../utils/dateUtils';
import LoadingPlaceholder from './LoadingPlaceholder';
import Divider from '@mui/material/Divider';
import { useSearchParams } from 'react-router-dom';

interface PlayLogsTabProps {
  username: string;
  isPublic: boolean;
}

const ITEMS_PER_PAGE = 12; // 1ページあたりの表示数

const PlayLogsTab: React.FC<PlayLogsTabProps> = ({ username, isPublic }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [playLogs, setPlayLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const page = parseInt(searchParams.get('page') || '1', 10);

  useEffect(() => {
    const fetchPlayLogs = async () => {
      try {
        const data = await getUserPlayLogs(isPublic ? username : undefined, page);
        setPlayLogs(data.results);
        setTotalCount(data.count);
      } catch (error) {
        console.error('プレイログの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayLogs();
  }, [username, isPublic, page]);

  // 日付フォーマット用の関数を追加
  const formatLastPlayDate = (dateString: string): string => {
    // タイムゾーンを考慮せずに日付を解析（YYYY-MM-DD形式）
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
    const date = new Date(year, month - 1, day);  // monthは0-basedなので-1
    
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    // 日付の差分を計算（タイムゾーンの影響を受けない）
    const diff = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (diff === 0) {
      return '今日';
    } else if (diff === 1) {
      return '昨日';
    } else if (diff < 7) {
      return `${diff}日前`;
    } else {
      return `${year}年${month}月${day}日`;
    }
  };

  if (loading) {
    return <LoadingPlaceholder type="card" count={6} />;
  }

  if (playLogs.length === 0) {
    return (
      <Paper 
        variant="outlined" 
        sx={{ 
          borderRadius: 1, 
          overflow: 'hidden'
        }}
      >
        <Box sx={{ 
          p: 4, 
          textAlign: 'center',
        }}>
          <Typography variant="body1" color="text.secondary">
            プレイログはありません。<br />
            ステータス"プレイ中"のゲーム詳細ページから、「プレイ開始」ボタンを押してセッションを開始してください。
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Box>
      <Grid container spacing={1.5}>
        {playLogs.map((log) => (
          <Grid item xs={12} md={4} key={log.game.game_id}>
            <Card 
              component={RouterLink}
              to={`/game/${log.game.game_id}/playlog/${isPublic ? username : ''}`}
              variant="outlined" 
              sx={{ 
                height: '100%',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.2s ease',
                backgroundColor: 'rgba(255, 255, 255, 0.02)',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  borderColor: 'rgba(255, 255, 255, 0.2)',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {/* カバー画像 */}
                <Box 
                  sx={{
                    position: 'relative',
                    width: { xs: '60px', md: '90px' },
                    height: { xs: '80px', md: '120px' },
                    backgroundImage: `url(${log.game.cover})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '2px',
                  }}
                />

                {/* ゲーム情報 */}
                <Box sx={{ px: { xs: 1.5, md: 2 }, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                  {/* プレイ情報 */}
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5
                  }}>
                    <Typography 
                      component="span"
                      sx={{ 
                        fontSize: { xs: '0.85rem', sm: '1rem' },
                        color: 'text.primary',
                        fontWeight: 500,
                        lineHeight: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.07)',
                        mb: 0.5,
                        borderRadius: '4px',
                        padding: '4px 8px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        alignSelf: 'flex-start'
                      }}
                    >
                      {formatTotalPlayTime(log.total_play_time)} <Typography sx={{ fontSize: { xs: '0.65rem', sm: '0.7rem' }, ml: 0.5, lineHeight: 1,  }}>時間</Typography>
                    </Typography>
                    {log.latest_play_date && (
                      <Typography 
                        sx={{ 
                          fontSize: { xs: '0.65rem', sm: '0.7rem' },
                          color: 'text.secondary',
                          lineHeight: 1.25,
                          mt: 0.5,
                          ml: 0.5
                        }}
                      >
                        {formatLastPlayDate(log.latest_play_date)}
                      </Typography>
                    )}
                  </Box>
                  <Divider sx={{ my: 0.5 }} />
                  <Typography 
                    component="div"
                    sx={{ 
                      fontSize: { xs: '0.65rem', sm: '0.65rem' },
                      color: 'pulse.info',
                      fontWeight: 400,
                      ml: 0.5,
                      lineHeight: 1.25
                    }}
                  >
                    {log.game.japanese_name || log.game.name}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {totalCount > ITEMS_PER_PAGE && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil(totalCount / ITEMS_PER_PAGE)}
            page={page}
            renderItem={(item) => (
              <PaginationItem
                component={RouterLink}
                to={`/profile/?tab=playlogs&page=${item.page}`}
                {...item}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlayLogsTab; 