import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { formatReviewText } from '../../utils/formatText';
import { useTheme, useMediaQuery } from '@mui/material';

interface ReviewPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  reviewText: string;
}

const ReviewPreviewDialog: React.FC<ReviewPreviewDialogProps> = ({
  open,
  onClose,
  reviewText
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        elevation: 1,
        style: {
          backgroundColor: 'rgba(20, 20, 20, 1)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '12px',
          margin: fullScreen ? 0 : '32px',
          maxWidth: fullScreen ? '95%' : '600px',
          maxHeight: fullScreen ? '90%' : 'calc(100% - 64px)',
        },
      }}
    >
      <DialogTitle>プレビュー</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2, color: 'pulse.text', fontSize: fullScreen ? '0.7rem' : '0.8rem' }}>
          {formatReviewText(reviewText, fullScreen)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewPreviewDialog; 