import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Container, 
  Avatar, 
  Chip, 
  Grid,
  Link as MuiLink,
  Paper,
  useTheme,
  Button,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { getReviewDetail } from '../services/api';
import { ReviewDetail } from '../types/Review';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ShareButtons from '../components/common/ShareButtons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { likeReview, unlikeReview } from '../services/api';
import { Helmet } from 'react-helmet-async';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import { getPlatformDisplayText } from '../utils/PlatformList';
import UpdateIcon from '@mui/icons-material/Update';
import { formatReviewText } from '../utils/formatText';

interface ScoreTag {
  id: number;
  label: string;
}

const scoreTags: ScoreTag[] = [
  { id: 1, label: '趣味趣向補正' },
  { id: 2, label: '思い出補正' },
  { id: 3, label: 'IP・シリーズ補正' },
  { id: 4, label: 'デベロッパー補正' },
  { id: 5, label: 'ソフトウェアバージョン補正' },
  { id: 6, label: 'マルチプレイ補正' },
  { id: 7, label: 'エントリー補正' },
  { id: 8, label: 'コンテキスト補正' },
  { id: 9, label: '下方バイアス' },
  { id: 0, label: 'その他の上方バイアス' },
];

const getScoreColor = (score: number | null) => {
  if (score === null) return "#666666";
  if (score >= 4.5) return "#4CAF50";
  if (score >= 4.0) return "#8BC34A";
  if (score >= 3.5) return "#CDDC39";
  if (score >= 3.0) return "#FFEB3B";
  if (score >= 2.5) return "#FFC107";
  if (score >= 2.1) return "#FF9800";
  return "#F44336";
};

const GameReviewDetailPage: React.FC = () => {
  const { gameId, reviewId } = useParams<{ gameId: string; reviewId: string }>();
  const [review, setReview] = useState<ReviewDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLiked, setIsLiked] = useState(review?.is_liked || false);
  const [likeCount, setLikeCount] = useState(review?.like_count || 0);
  const isAuthenticated = !!localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchReviewDetail = async () => {
      try {
        const reviewData = await getReviewDetail(Number(gameId), Number(reviewId));
        setReview(reviewData);
      } catch (error) {
        console.error('レビュー詳細の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviewDetail();
  }, [gameId, reviewId]);

  useEffect(() => {
    if (review) {
      setIsLiked(review.is_liked);
      setLikeCount(review.like_count);
    }
  }, [review]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getReviewExcerpt = (text: string, maxLength: number = 60) => {
    if (!text) return '';
    const trimmed = text.trim().replace(/\s+/g, ' ');
    return trimmed.length > maxLength ? `${trimmed.slice(0, maxLength)}...` : trimmed;
  };

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    try {
      if (isLiked) {
        const response = await unlikeReview(Number(reviewId));
        setIsLiked(false);
        setLikeCount(response.data.count);
      } else {
        const response = await likeReview(Number(reviewId));
        setIsLiked(true);
        setLikeCount(response.data.count);
      }
    } catch (error) {
      console.error('イイね処理に失敗しました', error);
    }
  };

  if (loading) return <Typography>読み込み中...</Typography>;
  if (!review) return <Typography>レビューが見つかりません</Typography>;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>
          {review ? `${getReviewExcerpt(review.review_text, 30)}｜${review.game.japanese_name || review.game.name}のレビュー${review.game.japanese_name && review.game.name ? `｜${review.game.name}` : ''}｜PULSE` : 'レビュー｜PULSE'}
        </title>
        <meta
          name="description"
          content={review?.review_text || 'レビューの読み込み中...'}
        />
      </Helmet>
      <Grid container spacing={{ xs: 2, md: 4 }}>
        {/* ゲーム情報ブロック */}
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={0} 
            sx={{ 
              borderRadius: 3,
              overflow: 'hidden',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 1 }} sx={{ p: 2 }}>
              {/* ジャケット画像 */}
              <Grid item xs={3.5} md={12}>
                <Box
                  component={RouterLink}
                  to={`/game/${gameId}`}
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    '& img': {
                      width: '100%',
                      height: '133.33%',
                      borderRadius: 1,
                    }
                  }}
                >
                  <img
                    src={review.game.cover || '/image/no_image.png'}
                    alt={review.game.name}
                  />
                </Box>
              </Grid>
              
              {/* ゲーム情報 */}
              <Grid item xs={8.5} md={12}>
                <Box
                  component={RouterLink}
                  to={`/game/${gameId}`}
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'block',
                    '&:hover': {
                      '& .game-title': {
                        color: 'primary.main'
                      }
                    }
                  }}
                >
                  <Typography 
                    variant="body2" 
                    component="h2" 
                    gutterBottom
                    className="game-title"
                  >
                    {review.game.japanese_name || review.game.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {review.game.first_release_date && 
                      new Date(review.game.first_release_date).getFullYear()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* レビュー詳細ブロック */}
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 2, sm: 4 },
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            {/* ユーザー情報 */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Avatar 
                src={review.avatar || undefined} 
                alt={review.username} 
                sx={{ width: 48, height: 48 }} 
              />
              <Box sx={{ ml: 2 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1, 
                  flexWrap: 'wrap'
                }}>
                  <MuiLink
                    component={RouterLink}
                    to={`/profile/${review.username}`}
                    variant="body1"
                    sx={{ 
                      textDecoration: 'none',
                      color: 'text.primary',
                      fontWeight: '600',
                      '&:hover': {
                        color: 'primary.main',
                      }
                    }}
                  >
                    {review.display_name || review.username}
                  </MuiLink>
                  {review.status && review.status !== 1 && (
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        bgcolor: review.status === 2 ? '#2196F320' : '#FF980020',
                        color: 'rgba(255, 255, 255, 0.788)',
                        px: 0.75,
                        py: 0.25,
                        borderRadius: 1,
                        fontSize: '0.6rem',
                        lineHeight: 1,
                        border: `1px solid ${review.status === 2 ? '#2196F340' : '#FF980040'}`,
                      }}
                    >
                      {review.status === 2 ? 'プレイ中' : review.status === 3 ? '中断' : null}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: 1, rowGap: 0, mt: 0.5 }}>
                  <Typography variant="body2" color="pulse.info" sx={{ fontSize: '0.7rem', }}>
                    投稿日：{formatDate(review.created_at)}
                  </Typography>
                  {review.updated_at && review.updated_at !== review.created_at && (
                    <Tooltip title={`更新日：${formatDate(review.updated_at)}`}>
                      <UpdateIcon sx={{ 
                        fontSize: '0.9rem', 
                        lineHeight: 1,
                        color: 'pulse.info',
                        cursor: 'help'
                      }} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>

            {/* スコアとタグ */}
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
                mb: { xs: 1, sm: 2 },
                gap: { xs: 1.5, sm: 2 },
              }}
            >
              {review.score !== null && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  minWidth: { xs: '140px', sm: 'auto' },
                }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "0.8rem",
                      py: 0.5,
                      px: 1,
                      lineHeight: "1",
                      borderRadius: 1,
                      backgroundColor: `${getScoreColor(review.score)}B3`,
                    }}
                  >
                    {review.score.toFixed(1)}
                  </Typography>
                  <Box
                    sx={{
                      width: 100,
                      height: 4,
                      bgcolor: "rgba(255, 255, 255, 0.3)",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${((review.score - 1) / 4) * 100}%`,
                        height: "100%",
                        bgcolor: getScoreColor(review.score),
                        borderRadius: 2,
                        transition: "width 0.3s ease",
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1,
                flex: 1,
              }}>
                {review.score_tags?.map((tagId: number) => {
                  const tag = scoreTags.find((t) => t.id === tagId);
                  return tag && (
                    <Chip
                      key={tag.id}
                      icon={
                        tag.id === 9 ? (
                          <KeyboardDoubleArrowDownIcon style={{ color: '#79bcf3' }} />
                        ) : (
                          <KeyboardDoubleArrowUpIcon style={{ color: '#f37979' }} />
                        )
                      }
                      label={tag.label}
                      size="small"
                      variant="outlined"
                      sx={{ 
                        '& .MuiChip-icon': { fontSize: '0.7rem' },
                        '& .MuiChip-label': { 
                          fontSize: '0.6rem',
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {/* プレイ情報 */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1, columnGap: 2, mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TimerOutlinedIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'pulse.info' }} />
                <Typography variant="body2" color="pulse.info" sx={{ lineHeight: 1 }}>
                  {review.play_time ? `${review.play_time}時間` : '-'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PlayCircleOutlineIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'pulse.info' }} />
                <Typography variant="body2" color="pulse.info" sx={{ lineHeight: 1 }}>
                  {review.play_start_date || '-'} - {review.play_end_date || '-'}
                </Typography>
              </Box>
              {review.platform && review.platform.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SportsEsportsOutlinedIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'pulse.info' }} />
                  <Typography variant="body2" color="pulse.info" sx={{ lineHeight: 1 }}>
                    {getPlatformDisplayText(review.platform)}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* レビュー本文 */}
            <Box 
              sx={{ 
                mb: 3,
                color: 'text.primary',
                '& .spoiler': {
                  backgroundColor: '#444',
                  padding: '0 4px',
                  borderRadius: '2px',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }
              }}
            >
              <Typography variant="body2" color="pulse.text" sx={{ lineHeight: { xs: 1.35, sm: 1.4 } }}>
                {formatReviewText(review.review_text, isMobile)}
              </Typography>
            </Box>

            {/* シェアボタン */}
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <Button
                size="small"
                onClick={handleLikeClick}
                startIcon={isLiked ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
                color={isLiked ? "primary" : "inherit"}
                sx={{ 
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  }
                }}
              >
                {likeCount}
              </Button>
              <ShareButtons
                text={`${review.game.japanese_name || review.game.name}のレビュー by ${review.display_name || review.username}

${getReviewExcerpt(review.review_text)}`}
                url={`/game/${gameId}/reviews/${reviewId}`}
                buttonText='レビューを共有'
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GameReviewDetailPage; 