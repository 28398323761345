import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, Box, Avatar, Button, Pagination, Skeleton, Tabs, Tab, Chip, useMediaQuery, Theme, Link, Tooltip, IconButton, Snackbar } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { getUserProfile, getGamesByStatus, updateUserProfile, getGamesByStatusForUser, getPublicGameStatuses, getProfileGameStatuses } from '../services/api';
import GameCard from '../components/GameCard';
import { UserProfile } from '../types/UserProfile';
import { Game } from '../types/Game';
import { AxiosResponse } from 'axios';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import GameListFilter from '../components/filter/GameListFilter';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';
import OnlineStatus from '../components/OnlineStatus';
import GameListComponent from '../components/GameList/GameListComponent';
import CreateGameList from '../components/GameList/CreateGameList';
import { getUserGameLists, GameListSummary, GameList, deleteGameList } from '../services/contents_api';
import EditGameList from '../components/GameList/EditGameList';
import ShareButtons from '../components/common/ShareButtons';
import { isAuthenticated } from '../services/auth';
import ActivityTab from '../components/profile/Activity';
import ProfileTab from '../components/ProfileTab';
import SettingsIcon from '@mui/icons-material/Settings';
import GetAppIcon from '@mui/icons-material/GetApp';
import Expandable from '../components/common/Expandable';
import { isPwa } from '../utils/isPwa';
import ReviewsTab from '../components/ReviewsTab';
import FollowButton from '../components/common/FollowButton';
import FollowsTab from '../components/FollowsTab';
import TimelineTab from '../components/TimelineTab';
import BoltIcon from '@mui/icons-material/Bolt';
import PlayLogsTab from '../components/PlayLogsTab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const GAMES_PER_PAGE = 30;

interface ProfilePageProps {
  profile?: UserProfile;
  isPublic?: boolean;
  isOwnProfile?: boolean;
}

// タブの種類を定数で定義
const MAIN_TABS = {
  PROFILE: 'profile',
  LIBRARY: 'library',
  LISTS: 'lists',
  REVIEWS: 'reviews',
  ACTIVITY: 'activity',
  FOLLOWS: 'follows',
  TIMELINE: 'timeline',
  PLAYLOGS: 'playlogs',
} as const;

type MainTabType = typeof MAIN_TABS[keyof typeof MAIN_TABS];

const ProfilePage: React.FC<ProfilePageProps> = ({ 
  profile: initialProfile, 
  isPublic = false,
  isOwnProfile = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = useParams<{ username: string }>();
  const queryParams = new URLSearchParams(location.search);
  
  // 初期値の設定を修正
  const tab = queryParams.get('tab') as MainTabType;
  const status = parseInt(queryParams.get('status') || '1', 10);
  
  // 初期値を設定する際にタブの状態も考慮
  const [tabValue, setTabValue] = useState(tab === MAIN_TABS.LIBRARY ? status : 1);
  const [mainTabValue, setMainTabValue] = useState<MainTabType>(
    tab || (isPublic ? MAIN_TABS.PROFILE : MAIN_TABS.LIBRARY)
  );
  const initialPage = parseInt(queryParams.get('page') || '1', 10);
  const initialSearchQuery = queryParams.get('query') || '';
  const initialOrder = queryParams.get('order') || 'default';

  const [profile, setProfile] = useState<UserProfile | null>(initialProfile || null);
  const [isEditing, setIsEditing] = useState(false);
  const [games, setGames] = useState<Game[]>([]);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingGames, setLoadingGames] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);
  const [gameCounts, setGameCounts] = useState<number[]>([0, 0, 0, 0, 0]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [order, setOrder] = useState(initialOrder);
  const [totalGames, setTotalGames] = useState(0);
  const [gameStatuses, setGameStatuses] = useState<{
    [key: number]: {
      status: number | null;
      score: number | null;
      review_text: string | null;
      play_start_date: string | null;
      play_end_date: string | null;
      play_time: number | null;
      sub_status: number[] | null;
      score_tags: string[] | null;
      platform: number[] | null;
    }
  }>({});
  const [gameLists, setGameLists] = useState<GameListSummary[]>([]);
  const [createListDialogOpen, setCreateListDialogOpen] = useState(false);
  const [editingList, setEditingList] = useState<GameList | null>(null);
  const initialListPage = parseInt(queryParams.get('list_page') || '1', 10);
  const [listPage, setListPage] = useState(initialListPage);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // 認証チェックを追加
  useEffect(() => {
    const checkAuth = async () => {
      if (!isPublic) {
        const authenticated = await isAuthenticated();
        if (!authenticated) {
          navigate('/login', { state: { from: location.pathname } });
        }
      }
    };
    
    checkAuth();
  }, [isPublic, navigate, location.pathname]);

  const fetchProfile = useCallback(async () => {
    if (isPublic || initialProfile) return;
    try {
      const response: AxiosResponse<UserProfile> = await getUserProfile();
      setProfile(response.data);
      setGameCounts(response.data.status_counts || [0, 0, 0, 0, 0]);
    } catch (error) {
      console.error('プロフィールの取得に失敗しました', error);
    } finally {
      setLoadingProfile(false);
    }
  }, [isPublic, initialProfile]);

  useEffect(() => {
    if (isPublic && initialProfile) {
      setProfile(initialProfile);
      setGameCounts(initialProfile.status_counts || [0, 0, 0, 0, 0]);
      setLoadingProfile(false);
    } else if (!isPublic) {
      fetchProfile();
    }
  }, [isPublic, initialProfile, fetchProfile]);

  const fetchGames = async (status: number, page: number, query: string, order: string) => {
    setLoadingGames(true);
    try {
      let response;
      if (isPublic && profile?.username) {
        response = await getGamesByStatusForUser(profile.username, status, page, query, order);
      } else {
        response = await getGamesByStatus(status, page, query, order);
      }
      setGames(response.data.results);
      setTotalPages(Math.ceil(response.data.count / GAMES_PER_PAGE));
      setTotalGames(response.data.count);
    } catch (error) {
      console.error('ゲームの取得に失敗しました', error);
    } finally {
      setLoadingGames(false);
    }
  };

  useEffect(() => {
    if (loadingProfile) return; // プロフィールのロードが完了していない場合は実行しない
    if (mainTabValue !== MAIN_TABS.LIBRARY) return; // ライブラリタブ以外では実行しない
    
    if (isPublic && !profile?.username) return;
    
    fetchGames(tabValue, page, searchQuery, order);
  }, [tabValue, page, isPublic, profile?.username, loadingProfile, searchQuery, order, mainTabValue]);

  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!games.length) return;
      if (isPublic && !profile?.username) return;

      const gameIds = games.map(game => game.game_id);
      try {
        let statuses;
        if (isPublic && profile?.username) {
          statuses = await getPublicGameStatuses(profile.username, gameIds);
        } else if (!isPublic) {
          statuses = await getProfileGameStatuses(gameIds);
        }
        setGameStatuses(statuses || {});
      } catch (error) {
        console.error('ゲムステータスの一括取得に失敗しました', error);
      }
    };

    fetchGameStatuses();
  }, [games, isPublic, profile?.username]);

  const handleEditClick = () => {
    navigate('/profile/edit');
  };

  const handleSave = async (updatedProfile: UserProfile) => {
    try {
      const response = await updateUserProfile(updatedProfile);
      setIsEditing(false);
      setProfile(response.data);
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error('プロフィールの更新に失敗しました', error.response.data);
        throw error; // エラーを再スローして、ProfileEditFormで処理できるようにする
      }
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // URLを更新
    updateUrl(tabValue, value, searchQuery, order);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
    // URLを更新
    updateUrl(newValue, 1, searchQuery, order);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, query, order);
  };

  const handleOrderChange = (newOrder: string) => {
    setOrder(newOrder);
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, searchQuery, newOrder);
  };

  // URLを更新する関数
  const updateUrl = (status: number, page: number, query: string, order: string) => {
    const params = new URLSearchParams({
      status: status.toString(),
      page: page.toString(),
      query,
      order,
      tab: mainTabValue
    });
    
    const newUrl = isPublic
      ? `/profile/${username}?${params.toString()}`
      : `/profile?${params.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const TabLabel: React.FC<{ label: string; count: number; isSelected: boolean }> = ({ label, count, isSelected }) => (
    <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
      <Typography sx={{ mr: isSmallScreen ? 0 : 1, fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }}>{label}</Typography>
      {isSelected ? (
        <Chip
          label={count}
          size="small"
          sx={{
            backgroundColor: 'primary.main',
            color: 'black',
            fontSize: isSmallScreen ? '0.625rem' : '0.75rem',
            height: isSmallScreen ? '16px' : '18px',
          }}
        />
      ) : (
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: isSmallScreen ? '0.625rem' : '0.75rem' }}>
          /{count}
        </Typography>
      )}
    </Box>
  );

  const handleCopyLink = () => {
    const publicProfileUrl = `${window.location.origin}/profile/${profile?.username}`;
    navigator.clipboard.writeText(publicProfileUrl);
    setOpenSnackbar(true);
  };

  // 新しい関数を追加
  const handleClearFilter = () => {
    setSearchQuery('');
    setOrder('default');
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, '', 'default');
    fetchGames(tabValue, 1, '', 'default');
  };

  // タブ変更ハンドラーの修正
  const handleMainTabChange = (event: React.SyntheticEvent, newValue: MainTabType) => {
    setMainTabValue(newValue);
    setPage(1);
    
    // 現在のパラメータを取得
    const params = new URLSearchParams(queryParams);
    
    // タブに応じて必要なパラメータのみを保持
    params.set('tab', newValue);
    params.set('page', '1');
    
    if (newValue === MAIN_TABS.LIBRARY) {
      // ライブラリタブの場合
      params.set('status', tabValue.toString());
      if (searchQuery) params.set('query', searchQuery);
      if (order !== 'default') params.set('order', order);
    } else if (newValue === MAIN_TABS.LISTS) {
      // リストタブの場合はリストページを保持
      params.set('list_page', listPage.toString());
    } else {
      // その他のタブではページ関連パラメータをクリア
      params.delete('status');
      params.delete('query');
      params.delete('order');
      params.delete('list_page');
    }
    
    const newUrl = isPublic
      ? `/profile/${username}?${params.toString()}`
      : `/profile?${params.toString()}`;
    navigate(newUrl, { replace: true });
  };

  // ゲームリストを取得する関数
  const fetchGameLists = useCallback(async () => {
    try {
      const response = await getUserGameLists(isPublic ? username : undefined, listPage);
      setGameLists(response.results);
      setTotalPages(Math.ceil(response.count / 9));
    } catch (error) {
      console.error('ゲームリストの取得に失敗しました:', error);
    }
  }, [isPublic, username, listPage]);

  // コンポーネントのマウント時にゲームリストを取得
  useEffect(() => {
    if (mainTabValue === MAIN_TABS.LISTS) {
      fetchGameLists();
    }
  }, [mainTabValue, fetchGameLists]);

  // リストの編集ハンドラー
  const handleEditList = (list: GameListSummary) => {
    setEditingList(list as unknown as GameList); // 一時的な型キャスト
  };

  // リストの削除ハンドラー
  const handleDeleteList = async (listId: number) => {
    if (window.confirm('このリストを削除してもよろしいですか？')) {
      try {
        await deleteGameList(listId);
        fetchGameLists();
      } catch (error) {
        console.error('リストの削除に失敗しました:', error);
      }
    }
  };

  // URLのパラメータが変更されたときの処理を修正
  useEffect(() => {
    const newTab = queryParams.get('tab') as MainTabType;
    const newStatus = parseInt(queryParams.get('status') || '1', 10);
    const newListPage = parseInt(queryParams.get('list_page') || '1', 10);

    // メインタブが変更された場合
    if (newTab !== mainTabValue) {
      setMainTabValue(newTab || (isPublic ? MAIN_TABS.PROFILE : MAIN_TABS.LIBRARY));
    }

    // ライブラリタブでステータスが変更された場合
    if (newTab === MAIN_TABS.LIBRARY && newStatus !== tabValue) {
      setTabValue(newStatus);
      setPage(1);
    }
    
    // リストタブでページが変更された場合
    if (newTab === MAIN_TABS.LISTS && newListPage !== listPage) {
      setListPage(newListPage);
    }
  }, [location.search]); // location.searchの変更時のみ実行

  // リストページ変更ハンドラーを修正
  const handleListPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setListPage(value);
    // URLを更新
    const params = new URLSearchParams(queryParams);
    params.set('list_page', value.toString());
    params.set('tab', MAIN_TABS.LISTS);
    
    const newUrl = isPublic
      ? `/profile/${username}?${params.toString()}`
      : `/profile?${params.toString()}`;
    navigate(newUrl, { replace: true });
    
    // ページトップにスクロール
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <title>
          {isPublic && profile 
            ? `${profile.display_name || profile.username}(@${profile.username})のゲームライブラリ | ${SITE_NAME}`
            : `マイページ | ${SITE_NAME}`}
        </title>
      </Helmet>
      
      <Grid container spacing={3} sx={{ mb: { xs: 2, md: 2 } }}>
        <Grid item xs={12} md={8}>
          {loadingProfile ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="circular" width={56} height={56} />
              <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Skeleton width="40%" height={32} />
                <Skeleton width="60%" />
                <Skeleton width="80%" />
              </Box>
            </Box>
          ) : (
            profile && (
              <>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 1
                }}>
                  <Avatar
                    src={profile.avatar || undefined}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h6" component="h1">{profile.display_name || profile.username}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        @{profile.username}
                      </Typography>
                      {isPublic && profile.last_access && !profile.current_playing_game_id && (
                        <OnlineStatus lastAccess={profile.last_access} />
                      )}
                      {profile.current_playing_game_id && (
                        <Box
                          onClick={() => navigate(`/game/${profile.current_playing_game_id}`)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            backgroundColor: 'rgba(217, 255, 0, 0.12)',
                            border: '1px solid rgba(217, 255, 0, 0.5)',
                            borderRadius: '4px',
                            padding: '1px 8px',
                            paddingLeft: '4px',
                            position: 'relative',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 0.8
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'linear-gradient(90deg, rgba(217, 255, 0, 0), rgba(217, 255, 0, 0.2), rgba(217, 255, 0, 0))',
                              backgroundSize: '200% 100%',
                              animation: 'shimmer 2s infinite',
                            },
                            '@keyframes shimmer': {
                              '0%': {
                                backgroundPosition: '100% 0'
                              },
                              '100%': {
                                backgroundPosition: '-100% 0'
                              }
                            }
                          }}
                        >
                          <PlayArrowIcon 
                            sx={{ 
                              fontSize: '0.875rem',
                              color: '#d9ff00',
                            }} 
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              color: '#d9ff00',
                              fontSize: '0.7rem',
                              fontWeight: 400,
                              lineHeight: '1',
                              letterSpacing: '0.02em',
                              zIndex: 1
                            }}
                          >
                            now playing
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {profile.bio && (
                  <Expandable maxLines={3} expandButtonStyle="icon">
                    <Typography variant="body2" sx={{ mb: 0.5, whiteSpace: 'pre-wrap' }}>
                      {profile.bio}
                    </Typography>
                  </Expandable>
                )}
                {isPublic && profile.location && (
                  <Box sx={{ 
                    mb: 0.5,
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 0.5,
                    color: 'text.secondary',
                  }}>
                    <FmdGoodOutlinedIcon sx={{ fontSize: '1rem' }} />
                    <Typography variant="body2">{profile.location}</Typography>
                  </Box>
                )}
                {!isPublic && (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'text.secondary', mb: 1 }}>
                      <LinkIcon sx={{ fontSize: '1rem' }} />
                      <Typography variant="body2" sx={{ mr: 0.5 }}>公開URL:</Typography>
                      <Link
                        href={`/profile/${profile.username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body2"
                        sx={{ color: 'text.secondary', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                      >
                        …/profile/{profile.username}
                      </Link>
                      <ShareButtons 
                        text={`${profile.display_name || profile.username}のゲームライブラリ | ${SITE_NAME} - ゲーム記録管理プラットフォーム`}
                        url={`/profile/${profile.username}`}
                        hashtags={['PULSE_DB']}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<SettingsIcon />}
                        onClick={() => navigate('/profile/settings')}
                        sx={{
                          color: 'text.primary',
                          backgroundColor: 'rgba(255, 255, 255, 0.04)',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: '50px',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        プロフィール設定
                      </Button>
                      {!isPwa() && (
                        <Tooltip title="PWAアプリをインストール">
                          <IconButton
                            size="small"
                            onClick={() => navigate('/install-pwa')}
                            sx={{ 
                              border: '1px solid',
                              borderColor: 'divider',
                              backgroundColor: 'rgba(255, 255, 255, 0.04)',
                              '&:hover': {
                                backgroundColor: 'action.hover',
                                borderColor: 'primary.main',
                              }
                            }}
                          >
                            <GetAppIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 0 }}>
                  <Box>
                    {isPublic && (
                      <FollowButton
                        username={profile.username}
                        isFollowing={profile.is_following}
                        isOwnProfile={isOwnProfile}
                        isFollowedBy={profile.is_followed_by}
                        onFollowChange={(isFollowing) => {
                          setProfile(prev => prev ? {
                            ...prev,
                            is_following: isFollowing,
                            followers_count: prev.followers_count + (isFollowing ? 1 : -1)
                          } : null);
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </>
            )
          )}
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
          {/* 右側のカラムに追加のコンテンツを配置できます */}
        </Grid>
      </Grid>

      {/* 追加：メインタブ */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs 
          value={mainTabValue} 
          onChange={handleMainTabChange}
          variant="scrollable"
          sx={{
            minHeight: { xs: '32px', sm: '40px' },
            '& .MuiTab-root': {
              minHeight: { xs: '36px', sm: '40px' },
              py: { xs: 1, sm: 1 },
              px: { xs: 1, sm: 1.5 },
              fontSize: { xs: '0.65rem', sm: '0.75rem' },
              minWidth: 0
            }
          }}
        >
          {!isPublic && (
            <Tab 
              icon={
                <Box 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.12)'
                    }
                  }}
                >
                  <BoltIcon 
                    sx={{ 
                      fontSize: '1rem',
                      transform: 'rotate(20deg)' // 20度反時計回りに回転
                    }} 
                  />
                </Box>
              }
              value={MAIN_TABS.TIMELINE}
            />
          )}
          <Tab label="プロフィール" value={MAIN_TABS.PROFILE} />
          <Tab label="ライブラリ" value={MAIN_TABS.LIBRARY} />
          <Tab label="レビュー" value={MAIN_TABS.REVIEWS} />
          <Tab label="プレイログ" value={MAIN_TABS.PLAYLOGS} />
          <Tab label="リスト" value={MAIN_TABS.LISTS} />
          <Tab label="アクティビティ" value={MAIN_TABS.ACTIVITY} />
          <Tab label="フォロー" value={MAIN_TABS.FOLLOWS} />
        </Tabs>
      </Box>

      {/* ゲームタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.LIBRARY && (
        <Box sx={{ width: '100%', overflow: 'auto' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ sx: { display: 'none' } }}
            sx={{ 
              '& .MuiTab-root': {
                minWidth: isSmallScreen ? 'auto' : 'inherit',
                padding: isSmallScreen ? '6px 8px' : '6px 16px',
                borderRadius: '12px',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                }
              }
            }}
          >
            <Tab 
              label={<TabLabel label="プレイ済" count={gameCounts[0]} isSelected={tabValue === 1} />} 
              value={1} 
            />
            <Tab 
              label={<TabLabel label="プレイ中" count={gameCounts[1]} isSelected={tabValue === 2} />} 
              value={2} 
            />
            <Tab 
              label={<TabLabel label="中断" count={gameCounts[2]} isSelected={tabValue === 3} />} 
              value={3} 
            />
            <Tab 
              label={<TabLabel label="積みゲー" count={gameCounts[3]} isSelected={tabValue === 4} />} 
              value={4} 
            />
            <Tab 
              label={<TabLabel label="気になる" count={gameCounts[4]} isSelected={tabValue === 5} />} 
              value={5} 
            />
          </Tabs>
          
          <Box sx={{ mt: 1 }}>
            <GameListFilter 
              onSearch={handleSearch} 
              onOrderChange={handleOrderChange}
              onClearFilter={handleClearFilter}
              totalGames={totalGames}
              initialSearchQuery={searchQuery}
              initialOrder={order}
            />
          </Box>
          {loadingGames ? (
            <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
          ) : (
            <>
              {games.length === 0 ? (
                <Box sx={{ 
                  textAlign: 'center', 
                  py: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: 2,
                  mt: 2
                }}>
                  <Typography variant="h6" gutterBottom>
                    まだゲームが登録されていません
                  </Typography>
                  {!isPublic && (
                    <>
                      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                        ゲームを検索して、ライブラリに追加しましょう
                      </Typography>
                      <Button 
                        variant="contained" 
                        onClick={() => navigate('/search')}
                      >
                        ゲームを探す
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <Grid container spacing={{ xs: 1, sm: 1.5 }}>
                  {games.map((game) => (
                    <Grid item xs={4} sm={3} md={2} key={game.game_id}>
                      <GameCard 
                        game={game} 
                        mode={isPublic ? 'public' : 'profile'}
                        username={isPublic ? profile?.username : undefined}
                        initialStatus={gameStatuses[game.game_id]?.status}
                        initialScore={gameStatuses[game.game_id]?.score}
                        initialReviewText={gameStatuses[game.game_id]?.review_text}
                        initialPlayStartDate={gameStatuses[game.game_id]?.play_start_date}
                        initialPlayEndDate={gameStatuses[game.game_id]?.play_end_date}
                        initialPlayTime={gameStatuses[game.game_id]?.play_time}
                        initialSubStatus={gameStatuses[game.game_id]?.sub_status}
                        initialScoreTags={gameStatuses[game.game_id]?.score_tags}
                        initialPlatform={gameStatuses[game.game_id]?.platform}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {totalPages > 1 && (
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
                />
              )}
            </>
          )}
        </Box>
      )}

      {/* コンテンツタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.LISTS && (
        <Box>
          {!isPublic && (
            <Button 
              variant="contained"
              size="small"
              onClick={() => setCreateListDialogOpen(true)}
              sx={{ 
                mb: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                backdropFilter: 'blur(8px)',
                border: '1px solid rgba(255, 255, 255, 0.12)',
                color: 'rgba(255, 255, 255, 0.9)',
                fontWeight: 400,
                letterSpacing: '0.02em',
                textTransform: 'none',
                borderRadius: '6px',
                px: 2,
                py: 0.5,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.12)',
                  transform: 'translateY(-1px)'
                },
                '&:active': {
                  transform: 'translateY(0)'
                }
              }}
            >
              新規リストを作成
            </Button>
          )}
          
          <Grid container spacing={2}>
            {gameLists.map((list) => (
              <GameListComponent
                key={list.id}
                list={list}
                onEdit={!isPublic ? handleEditList : undefined}
                onDelete={!isPublic ? handleDeleteList : undefined}
              />
            ))}
          </Grid>

          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Pagination
                count={totalPages}
                page={listPage}
                onChange={handleListPageChange}
              />
            </Box>
          )}

          <CreateGameList
            open={createListDialogOpen}
            onClose={() => setCreateListDialogOpen(false)}
            onListCreated={fetchGameLists}
          />

          {editingList && (
            <EditGameList
              open={!!editingList}
              onClose={() => setEditingList(null)}
              list={editingList}
              onListUpdated={fetchGameLists}
            />
          )}
        </Box>
      )}

      {/* レビュータブのコンテンツ */}
      {mainTabValue === MAIN_TABS.REVIEWS && profile && (
        <ReviewsTab 
          username={profile.username} 
          isPublic={isPublic}
        />
      )}

      {/* アクティビティタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.ACTIVITY && (
        <ActivityTab 
          username={profile?.username} 
          isPublic={isPublic} 
        />
      )}

      {/* プロフィールタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.PROFILE && (
        <ProfileTab 
          profile={profile!}
          isPublic={isPublic}
        />
      )}

      {/* フォロータブのコンテンツ */}
      {mainTabValue === MAIN_TABS.FOLLOWS && profile && (
        <FollowsTab 
          username={profile.username} 
          isPublic={isPublic} 
        />
      )}

      {/* タイムラインタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.TIMELINE && (
        <TimelineTab 
          username={profile?.username || ''} 
          isPublic={isPublic} 
        />
      )}

      {/* プレイログタブのコンテンツ */}
      {mainTabValue === MAIN_TABS.PLAYLOGS && profile && (
        <PlayLogsTab 
          username={profile.username} 
          isPublic={isPublic} 
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="リンクがコピーされました"
      />
    </>
  );
};

export default ProfilePage;
