import React from 'react';
import { Typography, Container, Box, Paper, Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import BugReportIcon from '@mui/icons-material/BugReport';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface ReleaseNote {
  version: string;
  date: string;
  changes: {
    type: 'feature' | 'improvement' | 'bugfix' | 'new';
    items: string[];
  }[];
}

const releaseNotes: ReleaseNote[] = [
  {
    version: '1.3.0',
    date: '2025年3月12日',
    changes: [
      {
        type: 'new',
        items: [
          'プレイログ：ゲームごとに詳細なプレイ記録データを作成できる機能',
        ]
      },
    ]
  },
  {
    version: '1.2.X',
    date: '～2025年2月24日',
    changes: [
      {
        type: 'new',
        items: [
          'プロフィール：プラットフォーム統計',
          'レビュー：テキストフォーマット',          
        ]
      },
      {
        type: 'improvement',
        items: [
          'ライブラリ：順序フィルターを拡張',
          '検索：人気度-JPフィルターを追加',
          'ホーム：各種ゲーム一覧をPULSEの利用データをもとに生成されるように調整',
        ]
      },
      {
        type: 'bugfix',
        items: [
          'プロフィールから別のプロフィールに遷移した際、フォローボタンの状態が更新されない問題を修正',
        ]
      }
    ]
  },
  {
    version: '1.2.0',
    date: '2025年2月17日',
    changes: [
      {
        type: 'new',
        items: [
          'フォロー機能を実装',
        ]
      },
      {
        type: 'bugfix',
        items: [
          'アプリ版（PWA,MS）の検索画面でゲームをクリックした後、一覧に戻ってきたときに一番上まで戻ってしまう問題を修正',

        ]
      }
    ]
  }
];

const getIcon = (type: string) => {
  switch (type) {
    case 'new':
      return <NewReleasesIcon sx={{ fontSize: '1rem', color: 'primary.main' }} />;
    case 'feature':
      return <AddCircleOutlineIcon sx={{ fontSize: '1rem', color: '#8250df' }} />;
    case 'improvement':
      return <UpdateIcon sx={{ fontSize: '1rem', color: '#0969da' }} />;
    case 'bugfix':
      return <BugReportIcon sx={{ fontSize: '1rem', color: '#d73a49' }} />;
    default:
      return null;
  }
};

const getTypeLabel = (type: string) => {
  switch (type) {
    case 'new':
      return '新機能';
    case 'feature':
      return '機能追加';
    case 'improvement':
      return '改善';
    case 'bugfix':
      return '不具合';
    default:
      return type;
  }
};

const ReleaseNotesPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Helmet>
        <title>リリースノート | {SITE_NAME}</title>
      </Helmet>

      <Box sx={{ py: { xs: 2, sm: 4 } }}>
        <Typography 
          variant="h5" 
          component="h1" 
          sx={{ 
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          リリースノート
        </Typography>

        {releaseNotes.map((note, index) => (
          <Paper
            key={note.version}
            sx={{
              p: 3,
              mb: 3,
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '6px',
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'flex-start',
              gap: 2,
              mb: 2 
            }}>
              <Box>
                <Typography 
                  variant="h5" 
                  component="h2"
                  sx={{ 
                    fontSize: { xs: '1.1rem', sm: '1.3rem' },
                    fontWeight: 600,
                    color: 'White'
                  }}
                >
                  v{note.version}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    fontSize: '0.8rem',
                    mt: 0.5 
                  }}
                >
                  {note.date}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ ml: 0 }}>
              {note.changes.map((change, changeIndex) => (
                <Box key={changeIndex} sx={{ mb: 2 }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1
                  }}>
                    {getIcon(change.type)}
                    <Typography 
                      variant="subtitle1"
                      sx={{ 
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        color: 'text.primary'
                      }}
                    >
                      {getTypeLabel(change.type)}
                    </Typography>
                  </Box>
                  <Box component="ul" sx={{ 
                    m: 0, 
                    pl: 3,
                    listStyle: 'none'
                  }}>
                    {change.items.map((item, itemIndex) => (
                      <Typography
                        key={itemIndex}
                        component="li"
                        sx={{ 
                          fontSize: '0.85rem',
                          color: 'text.secondary',
                          mb: 0.5,
                          position: 'relative',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: '-1rem',
                            top: '0.5rem',
                            width: '4px',
                            height: '4px',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            borderRadius: '50%'
                          }
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default ReleaseNotesPage; 