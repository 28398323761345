import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface TipsProps {
  title: string;
  content: React.ReactNode;
  buttonComponent?: React.ReactNode;
}

const Tips: React.FC<TipsProps> = ({ title, content, buttonComponent }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonComponent ? (
        <Box onClick={handleOpen}>
          {buttonComponent}
        </Box>
      ) : (
        <IconButton onClick={handleOpen} size="small">
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tips;