import api from './api';
import { GameStory, GameStoryListItem } from '../types/content';

export const getGameStories = async (gameId: number): Promise<GameStoryListItem[]> => {
  const response = await api.get(`/stories/?game_id=${gameId}`);
  return response.data.results;
};

export const getAllGameStories = async (): Promise<GameStoryListItem[]> => {
  const response = await api.get('/stories/all_stories/');
  return response.data;
};

export const getGameStory = async (storyId: number) => {
  const response = await api.get(`/stories/${storyId}/`);
  return response.data;
};

export const createGameStory = async (storyData: Partial<GameStory>) => {
  const response = await api.post('/stories/', storyData);
  return response.data;
};

export const updateGameStory = async (storyId: number, storyData: Partial<GameStory>) => {
  try {
    const response = await api.patch(`/stories/${storyId}/`, storyData);
    return response.data;
  } catch (error) {
    console.error('ストーリーの更新に失敗しました', error);
    throw error;
  }
};

export const deleteGameStory = async (storyId: number) => {
  await api.delete(`/stories/${storyId}/`);
};

// 共通のプロパティを持つベース型を定義
export interface GameListBase {
  id: number;
  user_name: string;
  display_name: string;
  title: string;
  list_type: number;
  visibility: number;
}

// 一覧表示用の型
export interface GameListSummary extends GameListBase {
  item_count: number;
  preview_items: Array<{
    id: number;
    game_cover: string | null;
  }>;
}

// 詳細表示用の型
export interface GameList extends GameListBase {
  description: string;
  items: GameListItem[];
  created_at: string;
  updated_at: string;
  user_avatar: string | null;
}

export interface GameListItem {
  id: number;
  game_id: number;
  game_name: string;
  game_japanese_name: string | null;
  game_cover: string | null;
  order: number;
  note: string;
  created_at: string;
  updated_at: string;
}

// ゲームリスト関連のAPI関数
export const getUserGameLists = async (username?: string, page: number = 1): Promise<{
  results: GameListSummary[];
  count: number;
}> => {
  const endpoint = username 
    ? `/game-lists/?username=${username}&page=${page}` 
    : `/game-lists/?page=${page}`;
  const response = await api.get(endpoint);
  return response.data;
};

export const createGameList = async (listData: Partial<GameList>): Promise<GameList> => {
  const response = await api.post('/game-lists/', listData);
  return response.data;
};

export const updateGameList = async (listId: number, listData: Partial<GameList>): Promise<GameList> => {
  const response = await api.patch(`/game-lists/${listId}/`, listData);
  return response.data;
};

export const deleteGameList = async (listId: number): Promise<void> => {
  await api.delete(`/game-lists/${listId}/`);
};

export const addGameToList = async (listId: number, gameId: number, note: string = ''): Promise<GameListItem> => {
  const response = await api.post(`/game-lists/${listId}/add_game/`, { game_id: gameId, note });
  return response.data;
};

export const removeGameFromList = async (listId: number, gameId: number): Promise<void> => {
  await api.delete(`/game-lists/${listId}/remove_game/?game_id=${gameId}`);
};

export const reorderGameListItems = async (listId: number, items: GameListItem[]): Promise<void> => {
  await api.post(`/game-lists/${listId}/reorder_items/`, { items });
};

export const getGameListDetail = async (listId: number): Promise<GameList> => {
  const response = await api.get(`/game-lists/${listId}/list_detail/`);
  return response.data;
};

export const searchGames = async (query: string, inLibrary: boolean = false, page: number = 1) => {
  const response = await api.get('/game-lists/search_games/', {
    params: {
      q: query,
      in_library: inLibrary ? 1 : 0,
      page: page
    }
  });
  return response.data;
};

// フレーズ関連の型定義
export interface Phrase {
  id: number;
  content: string;
  context: string | null;
  source: string | null;
}

// 一覧表示用の軽量な型
export interface PhraseList {
  id: number;
  content: string;
  context: string | null;
  source: string | null;
}

// 詳細表示用の型
export interface PhraseDetail {
  id: number;
  content: string;
  context: string | null;
  source: string | null;
  type: number;
  status: number;
  created_at: string;
  updated_at: string;
  game_id: number;
}

// フレーズ関連のAPI関数
export const getGamePhrases = async (gameId: number): Promise<PhraseList[]> => {
  const response = await api.get(`/phrases/game/${gameId}/`);
  return response.data;
};

export const addGamePhrase = async (phraseData: {
  game_id: number;
  content: string;
  context?: string;
  source?: string;
}): Promise<Phrase> => {
  const response = await api.post('/phrases/', phraseData);
  return response.data;
};

export const getGamePhrase = async (phraseId: number): Promise<PhraseDetail> => {
  const response = await api.get(`/phrase/${phraseId}/`);
  return response.data;
};

export const updateGamePhrase = async (
  phraseId: number,
  data: {
    content: string;
    context?: string;
    source?: string;
  }
): Promise<PhraseDetail> => {
  const response = await api.patch(`/phrase/${phraseId}/`, data);
  return response.data;
};

export const getGamePhraseHistory = async (phraseId: number) => {
  const response = await api.get(`/phrase/${phraseId}/history/`);
  return response.data;
};

export interface TriviaList {
  id: number;
  title: string;
  content: string;
  source: string | null;
}

export interface TriviaDetail extends TriviaList {
  game_id: number;
  author: string;
  created_at: string;
  updated_at: string;
}

export const getGameTriviaList = async (gameId: number): Promise<TriviaList[]> => {
  const response = await api.get(`/trivia/game/${gameId}/`);
  return response.data;
};

export const getGameTriviaDetail = async (triviaId: number): Promise<TriviaDetail> => {
  const response = await api.get(`/trivia/${triviaId}/`);
  return response.data;
};

export const addGameTrivia = async (data: {
  game_id: number;
  title: string;
  content: string;
  source?: string;
}): Promise<TriviaDetail> => {
  const response = await api.post('/trivia/', data);
  return response.data;
};

export const updateGameTrivia = async (
  triviaId: number,
  data: {
    title?: string;
    content?: string;
    source?: string;
  }
): Promise<TriviaDetail> => {
  const response = await api.patch(`/trivia/${triviaId}/`, data);
  return response.data;
};

export const getGameTriviaHistory = async (triviaId: number): Promise<any[]> => {
  const response = await api.get(`/trivia/${triviaId}/history/`);
  return response.data;
};

// お気に入りゲーム関連の型定義
export interface FavoriteGame {
  id: number;
  game_id: number;
  game_name: string;
  game_japanese_name: string | null;
  game_cover: string | null;
  order: number;
  created_at: string;
  updated_at: string;
}

// お気に入りゲーム関連のAPI関数
export const getFavoriteGames = async (username?: string): Promise<FavoriteGame[]> => {
  const endpoint = username 
    ? `/favorite-games/?username=${username}` 
    : '/favorite-games/';
  const response = await api.get(endpoint);
  return response.data;
};

export const addFavoriteGame = async (gameId: number, order: number): Promise<FavoriteGame> => {
  const response = await api.post('/favorite-games/', { game_id: gameId, order });
  return response.data;
};

export const removeFavoriteGame = async (gameId: number): Promise<void> => {
  await api.delete('/favorite-games/', {
    params: { game_id: gameId }
  });
};

export const reorderFavoriteGames = async (items: { id: number; order: number }[]): Promise<void> => {
  await api.post('/favorite-games/', { items });
};