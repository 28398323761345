import React from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  showCloseButton?: boolean;
  width?: number;
}

const RoundedPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'rgba(30, 30, 30, 0.95)',
  [theme.breakpoints.down('sm')]: {
    margin: '16px',
    width: 'calc(100% - 32px)',
  }
}));

const Dialog: React.FC<DialogProps> = ({ open, onClose, title, content, showCloseButton = true, width }) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperComponent={RoundedPaper}
      PaperProps={{
        elevation: 3,
        style: width ? { width: `${width}px` } : {}
      }}
      sx={{
        '& .MuiDialogTitle-root': {
          fontSize: { xs: '0.8rem', sm: '0.9rem' },
          color: 'text.primary',
          p: { xs: 2, sm: 3 },
          pb: { xs: 1.5, sm: 2 },
        },
        '& .MuiDialogContent-root': {
          p: { xs: 2, sm: 3 },
          pt: { xs: 0, sm: 0 },
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <Typography>{content}</Typography>
        ) : (
          content
        )}
      </DialogContent>
      {showCloseButton && (
        <DialogActions>
          <Button onClick={onClose}>閉じる</Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;