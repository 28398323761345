import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { formatPlayTime } from '../../utils/dateUtils';

interface PlayLogRecord {
  date: string;
  play_time: number;
}

interface PlayTimeGraphProps {
  records: PlayLogRecord[];
  activeRate: number; // 必須プロパティに変更
}

const formatTimeValue = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(1, '0')}:${mins.toString().padStart(2, '0')}`;
};

const PlayTimeGraph: React.FC<PlayTimeGraphProps> = ({ records, activeRate }) => {
  const isMobile = useMediaQuery('(max-width:600px)'); // スマホ判定用

  // 直近1ヶ月のデータを準備
  const now = new Date();
  const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
  // 日付でフィルタリングしてソート
  const filteredRecords = records
    .filter(record => new Date(record.date) >= oneMonthAgo)
    .sort((a, b) => a.date.localeCompare(b.date));

  // 日付の配列を作成（1ヶ月分）
  const dateArray = Array.from({ length: 31 }, (_, i) => {
    const date = new Date(oneMonthAgo.getTime() + i * 24 * 60 * 60 * 1000);
    return date.toISOString().split('T')[0];
  });

  // 全日付のデータを作成（プレイしていない日は0として扱う）
  const completeRecords = dateArray.map(date => ({
    date,
    play_time: filteredRecords.find(r => r.date === date)?.play_time || 0
  }));

  const maxPlayTime = Math.max(...completeRecords.map(r => r.play_time));
  // 目盛りの最大値を60分単位で切り上げ
  const gridMaxValue = Math.ceil(maxPlayTime / 60) * 60;
  const width = 100;
  const height = 40;
  const padding = 6;
  const gridLines = 4;

  // グラフのポイントを計算
  const points = completeRecords.map((record, index) => {
    const x = (index / (completeRecords.length - 1)) * (width - padding * 2) + padding;
    const y = height - (record.play_time / gridMaxValue) * (height - padding * 2) - padding;
    return `${x},${y}`;
  });

  // 水平グリッドラインと目盛りの値を計算（整数値で計算）
  const horizontalGridLines = Array.from({ length: gridLines }, (_, i) => {
    const y = padding + (i * ((height - padding * 2) / (gridLines - 1)));
    // 整数値で計算
    const value = Math.round(gridMaxValue * (1 - i / (gridLines - 1)));
    return { y, value };
  });

  // 月曜日かどうかをチェック
  const isMonday = (dateString: string) => {
    const date = new Date(dateString);
    return date.getDay() === 1;
  };

  // 日付フォーマット（M/d）
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  // polylineの全長を計算
  const polylineRef = React.useRef<SVGPolylineElement>(null);
  const [pathLength, setPathLength] = React.useState(0);

  React.useEffect(() => {
    if (polylineRef.current) {
      const length = polylineRef.current.getTotalLength();
      setPathLength(length);
    }
  }, [points]);

  const circleRadius = 15;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const dashOffset = circleCircumference * (1 - activeRate / 100);

  return (
    <Box sx={{ mb: 3, mt: 1, display: 'flex', alignItems: 'center', gap: 0 }}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        style={{ 
          width: isMobile ? '100%' : '85%', // スマホ時は100%表示
          height: 'auto',
          aspectRatio: `${width} / ${height}`
        }}
      >
        {/* グラデーションの定義 */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(217, 255, 0, 0)">
              <animate
                attributeName="offset"
                values="-1; 2"
                dur="3s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="50%" stopColor="rgba(217, 255, 0, 0.04)">
              <animate
                attributeName="offset"
                values="-0.5; 2.5"
                dur="3s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="100%" stopColor="rgba(217, 255, 0, 0)">
              <animate
                attributeName="offset"
                values="0; 3"
                dur="3s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>

        {/* アニメーションする背景 */}
        <rect
          x={padding}
          y={padding}
          width={width - padding * 2}
          height={height - padding * 2}
          fill="url(#gradient)"
        />

        {/* 背景のベースライン */}
        <rect
          x={padding}
          y={padding}
          width={width - padding * 2}
          height={height - padding * 2}
          fill="none"
          stroke="rgba(255, 255, 255, 0.1)"
          strokeWidth="0.2"
        />

        {/* 水平グリッドラインと目盛り */}
        {horizontalGridLines.map(({ y, value }, i) => (
          <React.Fragment key={`h-${i}`}>
            <line
              x1={padding}
              y1={y}
              x2={width - padding}
              y2={y}
              stroke="rgba(255, 255, 255, 0.1)"
              strokeWidth="0.2"
              strokeDasharray={i === gridLines - 1 ? "none" : "0.5,0.5"}
            />
            <text
              x={padding - 0.5}
              y={y}
              fill="rgba(255, 255, 255, 0.5)"
              fontSize="2"
              textAnchor="end"
              dominantBaseline="middle"
            >
              {formatTimeValue(value)}
            </text>
          </React.Fragment>
        ))}

        {/* 月曜日の垂直グリッドラインとラベル */}
        {completeRecords.map((record, i) => {
          if (isMonday(record.date)) {
            const x = (i / (completeRecords.length - 1)) * (width - padding * 2) + padding;
            return (
              <React.Fragment key={`v-${i}`}>
                <line
                  x1={x}
                  y1={padding}
                  x2={x}
                  y2={height - padding}
                  stroke="rgba(255, 255, 255, 0.1)"
                  strokeWidth="0.2"
                  strokeDasharray="0.5,0.5"
                />
                <text
                  x={x}
                  y={height - 1}
                  fill="rgba(255, 255, 255, 0.5)"
                  fontSize="2"
                  textAnchor="middle"
                >
                  {formatDate(record.date)}
                </text>
              </React.Fragment>
            );
          }
          return null;
        })}

        {/* プレイ時間の折れ線 - 初期状態を非表示に */}
        <polyline
          ref={polylineRef}
          points={points.join(' ')}
          fill="none"
          stroke="#d9ff00"
          strokeWidth="0.2"
          strokeOpacity="0.5"
          style={{
            strokeDasharray: pathLength,
            strokeDashoffset: pathLength,
            animation: `dash 1.5s ease-in-out forwards`,
            opacity: 0, // 初期状態を非表示に
            animationFillMode: 'forwards' // アニメーション終了後の状態を維持
          }}
        />

        {/* データポイント - 初期状態を明示的に非表示に */}
        {points.map((point, i) => (
          completeRecords[i].play_time > 0 && (
            <circle
              key={i}
              cx={point.split(',')[0]}
              cy={point.split(',')[1]}
              r="0.6"
              fill="#d9ff00"
              style={{
                opacity: 0,
                animation: `fadeIn 0.3s ease-in-out forwards ${i * 0.05}s`,
                visibility: 'visible',
                animationFillMode: 'forwards'
              }}
            />
          )
        ))}

        {/* 土曜日の背景 */}
        {completeRecords.map((record, i) => {
          const date = new Date(record.date);
          if (date.getDay() === 6) { // 土曜日
            const x = (i / (completeRecords.length - 1)) * (width - padding * 2) + padding;
            const nextX = i < completeRecords.length -1 ? ((i + 1) / (completeRecords.length - 1)) * (width - padding * 2) + padding : width - padding;
            return (
              <rect
                key={`saturday-bg-${i}`}
                x={x}
                y={padding}
                width={nextX - x}
                height={height - padding * 2}
                fill="rgba(255, 255, 255, 0.03)" // 薄い背景色
              />
            );
          }
          return null;
        })}

        {/* 日曜日の背景 */}
        {completeRecords.map((record, i) => {
          const date = new Date(record.date);
          if (date.getDay() === 0) { // 日曜日
            const x = (i / (completeRecords.length - 1)) * (width - padding * 2) + padding;
            const nextX = i < completeRecords.length -1 ? ((i + 1) / (completeRecords.length - 1)) * (width - padding * 2) + padding : width - padding;

            return (
              <rect
                key={`sunday-bg-${i}`}
                x={x}
                y={padding}
                width={nextX - x}
                height={height - padding * 2}
                fill="rgba(255, 255, 255, 0.03)" // 薄い背景色
              />
            );
          }
          return null;
        })}

        <style>
          {`
            @keyframes dash {
              0% {
                opacity: 0;
                stroke-dashoffset: ${pathLength};
              }
              10% {
                opacity: 1;
              }
              100% {
                opacity: 1;
                stroke-dashoffset: 0;
              }
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
                visibility: visible;
              }
              to {
                opacity: 1;
                visibility: visible;
              }
            }
          `}
        </style>
      </svg>

      {/* スマホ時は円グラフを非表示 */}
      {!isMobile && (
        <svg
          viewBox="-18 -18.5 36 36"
          style={{
            width: '30%',
            maxWidth: '250px',
            height: 'auto'
          }}
        >
          {/* 背景の円 */}
          <circle
            cx="0"
            cy="0"
            r={circleRadius}
            fill="none"
            stroke="rgba(255, 255, 255, 0.1)"
            strokeWidth="0.3"
          />

          {/* アクティブ率を示す円弧 */}
          <circle
            cx="0"
            cy="0"
            r={circleRadius}
            fill="none"
            stroke="#d9ff00"
            strokeWidth="0.3"
            strokeDasharray={circleCircumference}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
            transform="rotate(-90)"
            style={{
              opacity: 0,
              animation: 'circleAnimation 1.5s ease-out forwards 0s'
            }}
          />

          {/* 円弧の先端の点 */}
          {activeRate > 0 && (
            <>
              <circle
                cx={Math.cos((360 * (activeRate / 100) - 90) * Math.PI / 180) * circleRadius}
                cy={Math.sin((360 * (activeRate / 100) - 90) * Math.PI / 180) * circleRadius}
                r="0.8"
                fill="#d9ff00"
                style={{
                  opacity: 0,
                  animation: 'fadeIn 0.3s ease-in-out forwards 1.5s'
                }}
              />

              {/* 始点と中心を結ぶ線 */}
              <line
                x1="0"
                y1="0"
                x2={Math.cos(-90 * Math.PI / 180) * circleRadius}
                y2={Math.sin(-90 * Math.PI / 180) * circleRadius}
                stroke="#d9ff004f"
                strokeWidth="0.2"
                style={{
                  opacity: 0,
                  animation: 'fadeIn 0.3s ease-in-out forwards 2s'
                }}
              />

              {/* 終点と中心を結ぶ線 */}
              <line
                x1="0"
                y1="0"
                x2={Math.cos((360 * (activeRate / 100) - 90) * Math.PI / 180) * circleRadius}
                y2={Math.sin((360 * (activeRate / 100) - 90) * Math.PI / 180) * circleRadius}
                stroke="#d9ff004f"
                strokeWidth="0.2"
                style={{
                  opacity: 0,
                  animation: 'fadeIn 0.3s ease-in-out forwards 2s'
                }}
              />
            </>
          )}

          {/* パーセンテージ表示 */}
          <text
            x="0"
            y="0"
            textAnchor="middle"
            letterSpacing="-0.1"
            dominantBaseline="middle"
            fill="rgba(255, 255, 255, 0.9)"
            fontSize="4.5"
            style={{
              opacity: 0,
              animation: 'fadeIn 0.5s ease-out forwards 2s'
            }}
          >
            {activeRate === 0 ? '-' : `${activeRate.toFixed(1)}%`}
          </text>

          {/* "Active" ラベル */}
          <text
            x="0"
            y="6"
            textAnchor="middle"
            fill="rgba(255, 255, 255, 0.5)"
            fontSize="2"
            style={{
              opacity: 0,
              animation: 'fadeIn 0.5s ease-out forwards 2s'
            }}
          >
            起動率
          </text>

          <style>
            {`
              @keyframes circleAnimation {
                0% {
                  opacity: 0;
                  stroke-dashoffset: ${circleCircumference};
                }
                100% {
                  opacity: 0.5;
                  stroke-dashoffset: ${dashOffset};
                }
              }
              @keyframes fadeIn {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
            `}
          </style>
        </svg>
      )}
    </Box>
  );
};

export default PlayTimeGraph; 