import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

interface SectionTitleProps {
  icon: React.ReactNode;
  text: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ icon, text }) => (
  <Box 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      mb: 1.5,
      mt: 3,
      gap: 1
    }}
  >
    {React.cloneElement(icon as React.ReactElement, { 
      sx: { 
        fontSize: { xs: '1rem', sm: '1.2rem' },
        color: 'primary.main'
      }
    })}
    <Typography 
      variant="h6" 
      component="h2" 
      sx={{ 
        fontSize: { xs: '0.8rem', sm: '1rem' },
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.9)',
        letterSpacing: '0.02em'
      }}
    >
      {text}
    </Typography>
    <Divider sx={{ flexGrow: 1 }} />
  </Box>
);