import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { setGameSubStatus } from '../services/api';
import ReplayIcon from '@mui/icons-material/Replay';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import PopoverMenu from './common/PopoverMenu';

interface SubStatusButtonProps {
  gameId: number;
  status: number;
  initialSubStatus: number[];
  onSubStatusChange: (newSubStatus: number[]) => void;
}

const sortNumberArray = (arr: number[]): number[] => {
  return [...arr].sort((a, b) => a - b);
};

const SubStatusButton: React.FC<SubStatusButtonProps> = ({ gameId, status, initialSubStatus, onSubStatusChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subStatus, setSubStatus] = useState<number[]>(sortNumberArray(initialSubStatus));

  useEffect(() => {
    setSubStatus(sortNumberArray(initialSubStatus));
  }, [initialSubStatus]);

  // ステータスが5の場合は空のJSX要素を返す
  if (status === 5) {
    return <></>;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubStatusChange = async (newSubStatus: number) => {
    try {
      let updatedSubStatus: number[];
      
      // 未クリア(8)とリタイア(9)の相互排他的な処理
      if (newSubStatus === 8 && subStatus.includes(9)) {
        // 未クリアを追加する場合、リタイアを削除
        updatedSubStatus = sortNumberArray(
          [...subStatus.filter(s => s !== 9), newSubStatus]
        );
      } else if (newSubStatus === 9 && subStatus.includes(8)) {
        // リタイアを追加する場合、未クリアを削除
        updatedSubStatus = sortNumberArray(
          [...subStatus.filter(s => s !== 8), newSubStatus]
        );
      } else {
        // 通常の追加/削除処理
        updatedSubStatus = sortNumberArray(
          subStatus.includes(newSubStatus)
            ? subStatus.filter(s => s !== newSubStatus)
            : [...subStatus, newSubStatus]
        );
      }
      
      await setGameSubStatus(gameId, updatedSubStatus);
      setSubStatus(updatedSubStatus);
      onSubStatusChange(updatedSubStatus);
    } catch (error) {
      console.error('サブステータスの更新に失敗しました', error);
    }
  };

  const getSubStatusIcon = (statusCode: number) => {
    switch (statusCode) {
      case 1: return <ReplayIcon fontSize="small" />;
      case 2: return <PriorityHighIcon fontSize="small" />;
      case 7: 
        return status === 1 
          ? <EmojiEventsIcon fontSize="small" />
          : <EmojiEventsIcon fontSize="small" />;
      case 8: return <RemoveCircleOutlineIcon fontSize="small" />;
      case 9: return <DoNotDisturbAltOutlinedIcon fontSize="small" />;
      default: return <ShoppingCartIcon fontSize="small" />;
    }
  };

  const getSubStatusText = (statusCode: number, subStatusCodes: number[]) => {
    const texts = subStatusCodes.map(code => {
      switch (statusCode) {
        case 1: // プレイ済み
          if (code === 1) return '再プレイ済';
          if (code === 7) return 'コンプリート';
          if (code === 8) return '未クリア';
          if (code === 9) return 'リタイア';
          return '';
        case 2: // プレイ中
          return code === 1 ? '再プレイ中' : 'やり込みプレイ中';
        case 3: // 中断
          return code === 1 ? '再プレイ' : 'リタイア';
        case 4: // 積みゲー
          return code === 1 ? '再プレイ' : '優先度 高';
        case 5: // 気になる
          return '購入予定';
        default:
          return '';
      }
    });
    return texts.length > 0 ? texts.join(', ') : 'サブステータス';
  };

  const menuOptions = [
    ...(status === 1 ? [
      {
        icon: <ReplayIcon />,
        label: '再プレイ済',
        onClick: () => handleSubStatusChange(1),
      },
      {
        icon: <EmojiEventsIcon />,
        label: '実績コンプリート',
        onClick: () => handleSubStatusChange(7),
      },
      {
        icon: <RemoveCircleOutlineIcon />,
        label: '未クリア',
        onClick: () => handleSubStatusChange(8),
      },
      {
        icon: <DoNotDisturbAltOutlinedIcon />,
        label: 'リタイア',
        description: '面白くない,合わない等、"ネガティブ"な理由でプレイを諦めた状態',
        onClick: () => handleSubStatusChange(9),
        sx: {
          backgroundColor: 'rgba(211, 47, 47, 0.15)',
          '&:hover': {
            backgroundColor: 'rgba(211, 47, 47, 0.25)',
          }
        },
        iconColor: 'error.main',
      },
    ] : []),
    ...(status === 2 ? [
      {
        icon: <ReplayIcon />,
        label: '再プレイ中',
        onClick: () => handleSubStatusChange(1),
      },
      // {
      //   icon: <ExploreOutlinedIcon />,
      //   label: 'やり込みプレイ中',
      //   onClick: () => handleSubStatusChange(7),
      // },
    ] : []),
    ...(status === 3 ? [
      {
        icon: <ReplayIcon />,
        label: '再プレイ',
        onClick: () => handleSubStatusChange(1),
      },
      {
        icon: <DoNotDisturbAltOutlinedIcon />,
        label: 'リタイア',
        description: '面白くない,合わない等、"ネガティブ"な理由でプレイを中断している状態',
        onClick: () => handleSubStatusChange(9),
        sx: {
          backgroundColor: 'rgba(211, 47, 47, 0.15)',
          '&:hover': {
            backgroundColor: 'rgba(211, 47, 47, 0.25)',
          }
        },
        iconColor: 'error.main'
      },
    ] : []),
    ...(status === 4 ? [
      {
        icon: <ReplayIcon />,
        label: '再プレイ',
        onClick: () => handleSubStatusChange(1),
      },
    ] : []),
    // ...(status === 5 ? [
    //   {
    //     icon: <ShoppingCartIcon />,
    //     label: '予約購入済み',
    //     onClick: () => handleSubStatusChange(1),
    //   },
    // ] : []),
  ];

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.01)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(255, 255, 255, 0.08)',
          borderRadius: '12px',
          padding: { xs: '6px 12px', sm: '8px 12px' },
          lineHeight: 1,
          color: 'text.primary',
          transition: 'all 0.2s ease',
          minWidth: 'auto',
          order: { xs: -1, sm: 0 },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            transform: 'translateY(-1px)',
          },
        }}
      >
        {subStatus.length > 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
            {subStatus.map((code, index) => (
              <React.Fragment key={code}>
                {index > 0 && (
                  <Box
                    sx={{
                      width: '1px',
                      height: '14px',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      mx: 1
                    }}
                  />
                )}
                <Box sx={{ lineHeight: 0,opacity:0.9 }}>
                  {getSubStatusIcon(code)}
                </Box>
              </React.Fragment>
            ))}
          </Box>
        ) : (
          <AddIcon fontSize="small" sx={{ color: 'text.secondary' }} />
        )}
      </Button>
      <PopoverMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        options={menuOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  );
};

export default SubStatusButton;