import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const Advertisement336: React.FC = () => {
  // ユニークなIDを生成するための参照を作成
  const adRef = useRef<HTMLDivElement>(null);
  const uniqueId = useRef(`ad-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    try {
      // 既存の広告要素をクリア
      if (adRef.current) {
        adRef.current.innerHTML = '';
      }

      // 新しい広告要素を作成
      const adElement = document.createElement('ins');
      adElement.className = 'adsbygoogle';
      adElement.style.display = 'inline-block';
      adElement.style.width = '336px';
      adElement.style.height = '280px';
      adElement.setAttribute('data-ad-client', 'ca-pub-6000060497265595');
      adElement.setAttribute('data-ad-slot', '5006852275');
      adElement.setAttribute('data-ad-format', 'auto');
      adElement.setAttribute('data-full-width-responsive', 'false');
      adElement.id = uniqueId.current;

      // 広告要素を追加
      if (adRef.current) {
        adRef.current.appendChild(adElement);
      }

      // 広告を初期化
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Adsense error:', error);
    }

    // クリーンアップ関数
    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = '';
      }
    };
  }, []);

  return (
    <Box ref={adRef} sx={{ display: 'flex', justifyContent: 'center', my: 2 }} />
  );
};

export default Advertisement336;