import React from 'react';
import { Paper, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

const MobileBottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get('tab');

  const handleNavigation = (newValue: string) => {
    if (newValue === 'back') {
      navigate(-1);
    } else {
      const currentPage = queryParams.get('page');
      if (currentPage && newValue.includes('?tab=')) {
        navigate(`${newValue}&page=${currentPage}`);
      } else {
        navigate(newValue);
      }
    }
  };

  const getCurrentValue = () => {
    const baseUrl = `${path}?tab=${currentTab}`;
    return path === '/' ? '/' : baseUrl;
  };

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        zIndex: 1000,
        borderTop: '1px solid',
        borderColor: 'divider',
        background: 'rgba(22, 22, 22, 0.8)',
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        '@supports (padding: constant(safe-area-inset-bottom))': {
          paddingBottom: 'constant(safe-area-inset-bottom)'
        }
      }} 
      elevation={0}
    >
      <BottomNavigation
        value={getCurrentValue()}
        onChange={(_, newValue) => handleNavigation(newValue)}
        sx={{
          bgcolor: 'transparent',
          height: 56,
          '& .MuiBottomNavigationAction-root': {
            color: 'text.secondary',
            minWidth: 'auto',
            padding: '0',
            '&.Mui-selected': {
              color: 'primary.main',
            },
          },
          '& .MuiBottomNavigationAction-label': {
            display: 'none'
          },
        }}
      >
        <BottomNavigationAction
          value={path === '/' ? '/' : 'back'}
          icon={path === '/' ? <HomeIcon /> : <ArrowBackIosIcon />}
        />
        <BottomNavigationAction
          value="/profile?tab=timeline"
          icon={path === '/profile' && currentTab === 'timeline' ? <BoltIcon /> : <BoltOutlinedIcon />}
        />
        <BottomNavigationAction
          value="/profile?tab=library"
          icon={path === '/profile' && currentTab === 'library' ? <SportsEsportsIcon /> : <SportsEsportsOutlinedIcon />}
        />
        <BottomNavigationAction
          value="/search"
          icon={path === '/search' ? <SearchIcon /> : <SearchOutlinedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default MobileBottomNav; 