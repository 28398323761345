import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Chip, Skeleton } from '@mui/material';
import { getGameTriviaList, TriviaList } from '../../services/contents_api';
import { styled } from '@mui/material/styles';
import TriviaUploadButton from './TriviaUploadButton';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useNavigate } from 'react-router-dom';

interface GameTriviaSectionProps {
  gameId: number;
}

const TriviaCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  }
}));

const SourceChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  fontSize: '0.7rem',
  height: '24px',
}));

const GameTriviaSection: React.FC<GameTriviaSectionProps> = ({ gameId }) => {
  const [trivia, setTrivia] = useState<TriviaList[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchTrivia = async () => {
    try {
      const response = await getGameTriviaList(gameId);
      setTrivia(response);
    } catch (error) {
      console.error('トリビアの取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrivia();
  }, [gameId]);

  const handleTriviaClick = (triviaId: number) => {
    navigate(`/trivia/${triviaId}`);
  };

  if (loading) {
    return (
      <Box sx={{ mt: { xs: 3, sm: 3 } }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>トリビア</Typography>
        <Grid container spacing={2}>
          {[1, 2].map((i) => (
            <Grid item xs={12} key={i}>
              <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 3 } }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>トリビア</Typography>
        <TriviaUploadButton 
          gameId={gameId}
          onUploadComplete={fetchTrivia}
          onError={(error) => console.error(error)}
        />
      </Box>
      {trivia.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          このゲームの雑学・豆知識はまだ登録されていません。
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {trivia.map((item) => (
            <Grid item xs={12} key={item.id}>
              <TriviaCard 
                onClick={() => handleTriviaClick(item.id)}
                sx={{ cursor: 'pointer' }}
              >
                <CardContent sx={{ pt: 3, pb: '16px !important' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <LightbulbIcon sx={{ color: 'text.secondary', fontSize: '1.2rem' }} />
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        fontWeight: 600
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="body2"
                    sx={{ 
                      lineHeight: 1.6,
                      ml: 3.2,
                      color: 'text.secondary',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.content}
                  </Typography>
                </CardContent>
              </TriviaCard>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GameTriviaSection; 