import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Typography, 
  Grid, 
  Box, 
  Container,
  Alert,
  styled
} from '@mui/material';
import { getPopularGamesByYearStatus, getBasicGameStatuses } from '../services/api';
import { Game } from '../types/Game';
import GameCard from '../components/GameCard';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { Helmet } from 'react-helmet-async';

const HeaderSection = styled('div')({
  marginTop: '24px',
  position: 'relative',
  padding: '24px 0',
  marginBottom: '24px'
});

const PopularGamesByYearStatusPage: React.FC = () => {
  const { year: yearParam } = useParams<{ year: string }>();
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const year = yearParam ? parseInt(yearParam) : new Date().getFullYear();
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!localStorage.getItem('token'));

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getPopularGamesByYearStatus(year, 60);
        setGames(response.data.results);

        if (isLoggedIn) {
          const gameIds = response.data.results.map((game: Game) => game.game_id);
          const statusesResponse = await getBasicGameStatuses(gameIds);
          setGameStatuses(statusesResponse);
        }
      } catch (err) {
        setError('ゲームの取得中にエラーが発生しました。');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [year, isLoggedIn]);

  if (error) return <Typography color="error">{error}</Typography>;

  const pageTitle = `${year}年の人気ゲーム ランキング - PULSE`;
  const pageDescription = `${year}年発売のゲームをPULSEユーザーの利用データをもとに集計したランキングです。`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <HeaderSection>
        <Container maxWidth="lg">
          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 500,
              color: 'white',
              mb: 1
            }}
          >
            {year}年の人気ゲーム
          </Typography>
          
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 1,
              fontSize: '0.9rem'
            }}
          >
            PULSEユーザーの利用データをもとにした{year}年のランキングです。
          </Typography>

          <Alert 
            severity="info" 
            sx={{ 
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              color: 'rgba(255, 255, 255, 0.7)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              '& .MuiAlert-icon': {
                color: 'rgba(255, 255, 255, 0.7)'
              }
            }}
          >
            このランキングは2025年2月6日以降のPULSEユーザーの利用状況をもとにした比較的新しいデータであり、
            必ずしも当時の正確な人気度を反映したものではありません。また、現時点で十分なデータが集まっていない部分もあるため、
            あくまで参考程度にご覧ください。
          </Alert>
        </Container>
      </HeaderSection>

      <Container maxWidth="lg">
        {loading ? (
          <LoadingPlaceholder type="card" count={60} />
        ) : (
          <Grid container spacing={{ xs: 1, sm: 1.5 }}>
            {games.map((game, index) => (
              <Grid item xs={4} sm={3} md={2} key={game.game_id}>
                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 4,
                      left: 4,
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      px: 0.5,
                      py: 0.5,
                      borderRadius: 1,
                      lineHeight: 1,
                      fontSize: '0.6rem',
                      zIndex: 1
                    }}
                  >
                    {index + 1}
                  </Box>
                  <GameCard 
                    game={game} 
                    initialStatus={isLoggedIn ? gameStatuses[game.game_id] : null}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default PopularGamesByYearStatusPage; 