/**
 * 説明文のコンテンツを画像とテキストに分割して整形するユーティリティ
 */

interface ContentPart {
  type: 'text' | 'image';
  content: string;
}

/**
 * URLからタイムスタンプパラメータを削除
 */
const cleanUrl = (url: string): string => {
  return url.replace(/\?t=\d+/, '');
};

/**
 * 説明文を画像とテキストに分割して整形
 */
export const parseDescription = (text: string): ContentPart[] => {
  if (!text) return [];

  // 画像URLを検出する正規表現（jpg, jpeg, png, gif, webpに対応）
  const urlRegex = /(https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif|webp))\s*/gi;
  let lastIndex = 0;
  const result: ContentPart[] = [];
  let match;

  while ((match = urlRegex.exec(text)) !== null) {
    // URLの前のテキストを追加（存在する場合）
    if (match.index > lastIndex) {
      const textContent = text.slice(lastIndex, match.index).trim();
      if (textContent) {
        result.push({
          type: 'text',
          content: formatText(textContent)
        });
      }
    }

    // 画像URLを追加（クリーニング済み）
    result.push({
      type: 'image',
      content: cleanUrl(match[1])
    });

    lastIndex = urlRegex.lastIndex;
  }

  // 残りのテキストを追加（存在する場合）
  if (lastIndex < text.length) {
    const remainingText = text.slice(lastIndex).trim();
    if (remainingText) {
      result.push({
        type: 'text',
        content: formatText(remainingText)
      });
    }
  }

  return result;
};

/**
 * テキストの整形
 */
const formatText = (text: string): string => {
  let formattedText = text
    // 見出しタグはそのまま保持
    // 3つ以上連続したbrタグを2つに制限
    .replace(/(<br\s*\/?>\s*){2,}/gi, '<br><br>')
    .replace(/(<br\s*\/?>\s*)/gi, '<br>')
    // 行頭のスペースを削除
    .replace(/^\s+/gm, '')
    // 行末のスペースを削除
    .replace(/\s+$/gm, '')
    // 全角スペースを半角に変換
    .replace(/　/g, ' ')
    // 複数の半角スペースを1つに
    .replace(/ +/g, ' ')
    // HTMLエンティティをデコード
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

  return formattedText.trim();
};

/**
 * 説明文にGIF画像が含まれているかチェック
 */
export const containsGif = (text: string | null): boolean => {
  if (!text) return false;
  const gifRegex = /https?:\/\/[^\s]+\.gif\b/i;
  return gifRegex.test(text);
}; 