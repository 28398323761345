import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, CardMedia, Chip, IconButton, Skeleton } from '@mui/material';
import { getGameVideos } from '../../services/api';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/material/styles';
import YoutubeViewer from './YoutubeViewer';
import VideoUploadButton from './VideoUploadButton';

interface Video {
  id: number;
  youtube_id: string;
  title: string;
  description: string | null;
  type: number;
  publish_date: string | null;
  thumbnail_url: string | null;
}

interface GameVideoSectionProps {
  gameId: number;
}

const VideoThumbnail = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  overflow: 'hidden',
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
    '& .overlay': {
      opacity: 1,
    }
  }
}));

const PlayButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 1,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  borderRadius: '50%',
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  zIndex: 1,
}));

const TypeChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 6,
  left: 6,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  lineHeight: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
    height: '20px',
    '& .MuiChip-label': {
      padding: '0 8px'
    }
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.7rem',
    height: '24px',
    '& .MuiChip-label': {
      padding: '0 12px'
    }
  }
}));

const DateChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  fontSize: '0.7rem',
  height: '24px',
}));

const getFallbackThumbnail = (youtubeId: string): string => {
  return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
};

const GameVideoSection: React.FC<GameVideoSectionProps> = ({ gameId }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const fetchVideos = async () => {
    try {
      const response = await getGameVideos(gameId);
      setVideos(response.data);
    } catch (error) {
      console.error('動画の取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [gameId]);

  const getTypeLabel = (type: number): string => {
    const types: { [key: number]: string } = {
      0: 'Launch',
      1: 'PV',
      2: 'Teaser',
      3: 'Gameplay', 
      4: 'CM',
      5: 'Add-on',
      8: 'Interview',
      9: 'Other'
    };
    return types[type] || 'Other';
  };

  const handleVideoClick = (youtubeId: string) => {
    setSelectedVideoId(youtubeId);
  };

  const handleCloseViewer = () => {
    setSelectedVideoId(null);
  };

  const handleUploadComplete = () => {
    fetchVideos();
  };

  if (loading) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>動画</Typography>
        <Grid container spacing={2}>
          {[1, 2, 3, 4].map((i) => (
            <Grid item xs={12} sm={6} md={6} key={i}>
              <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (videos.length === 0) {
    return (
      <Box sx={{ mt: { xs: 3, sm: 3 } }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 1 
        }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>動画</Typography>
          <VideoUploadButton 
            gameId={gameId}
            onUploadComplete={handleUploadComplete}
            onError={(error) => console.error(error)}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          このゲームの動画はまだ登録されていません。
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 3 } }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>動画</Typography>
        <VideoUploadButton 
          gameId={gameId}
          onUploadComplete={handleUploadComplete}
          onError={(error) => console.error(error)}
        />
      </Box>
      <Grid container spacing={1.5}>
        {videos.map((video) => (
          <Grid item xs={6} sm={6} md={6} key={video.id}>
            <Box sx={{ mb: 1 }}>
              <VideoThumbnail onClick={() => handleVideoClick(video.youtube_id)}>
                <CardMedia
                  component="img"
                  image={video.thumbnail_url || getFallbackThumbnail(video.youtube_id)}
                  alt={video.title}
                  sx={{ 
                    aspectRatio: '16/9',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                />
                <Overlay className="overlay" />
                <PlayButton className="playButton">
                  <PlayArrowIcon sx={{ fontSize: { xs: 28, md: 40 }, color: '#fff' }} />
                </PlayButton>
                <TypeChip label={getTypeLabel(video.type)} />
              </VideoThumbnail>
              <Typography 
                variant="caption" 
                sx={{ 
                  mt: 1,
                  px: 0.5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: 1.3,
                  height: '2.6em'
                }}
              >
                {video.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <YoutubeViewer
        open={!!selectedVideoId}
        onClose={handleCloseViewer}
        videoId={selectedVideoId || ''}
      />
    </Box>
  );
};

export default GameVideoSection;