import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Pagination, CircularProgress, Grid, Skeleton, Link, Paper } from '@mui/material';
import { getTimeline } from '../services/api';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { formatRelativeTime } from '../utils/dateUtils';
import LaunchIcon from '@mui/icons-material/Launch';

interface TimelineProps {
  username: string;
  isPublic: boolean;
}

interface TimelineActivity {
  id: number;
  type: number;
  user: {
    username: string;
    display_name: string;
    avatar: string | null;
  };
  game: {
    id: number;
    name: string;
    cover_image: string | null;
  };
  created_at: string;
  old_status?: number;
  new_status?: number;
  review_text?: string;
}

const getStatusText = (status: number) => {
  switch (status) {
    case 1: return 'プレイ済み';
    case 2: return 'プレイ中';
    case 3: return '中断';
    case 4: return '積みゲー';
    case 5: return 'ウィッシュリスト';
    default: return '';
  }
};

const getActionText = (type: number, oldStatus?: number, newStatus?: number) => {
  if (type === 1 && newStatus === 5) {
    return null;
  }

  if (type === 1) {
    switch (newStatus) {
      case 1: return 'プレイ済みに追加';
      case 2: return 'プレイを開始';
      case 3: return '中断に追加';
      case 4: return '積みゲーに追加';
      default: return '';
    }
  }

  if (type === 2) {
    if (oldStatus === 1 && newStatus === 2) {
      return 'プレイ済みタイトルのプレイを再開';
    }
    if (oldStatus === 2 && newStatus === 1) {
      return 'プレイを終えた';
    }
    if (oldStatus === 2 && newStatus === 3) {
      return 'プレイを中断';
    }
    if (oldStatus === 3 && newStatus === 2) {
      return '中断していたタイトルのプレイを再開';
    }
    if (oldStatus === 4 && newStatus === 1) {
      return '積みゲーを消化';
    }
    if (oldStatus === 4 && newStatus === 2) {
      return '積みゲーのプレイを開始';
    }
    if (oldStatus === 5 && newStatus === 2) {
      return 'ウィッシュリストのゲームを開始';
    }
    if (oldStatus === 5 && newStatus === 4) {
      return 'ウィッシュリストのゲームを積んだ';
    }
    if (newStatus !== 5) {
      return `${getStatusText(oldStatus || 0)}から${getStatusText(newStatus || 0)}に変更`;
    }
    return null;
  }

  if (type === 3) return 'レビューを投稿';
  if (type === 4) return 'レビューを更新';
  
  return null;
};

const TimelineActivitySkeleton: React.FC = () => (
  <Box
    sx={{
      p: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
      backdropFilter: 'blur(8px)',
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.08)',
    }}
  >
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton 
        variant="circular" 
        width={48} 
        height={48} 
        sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
      />
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Skeleton 
            variant="text" 
            width={120} 
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
          />
          <Skeleton 
            variant="text" 
            width={60} 
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
          />
        </Box>
        <Skeleton 
          variant="text" 
          width={180} 
          sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
        />

        <Box sx={{ mt: 2 }}>
          <Skeleton 
            variant="rectangular" 
            height={80} 
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: 1 
            }} 
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Skeleton 
            variant="text" 
            width="90%" 
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
          />
          <Skeleton 
            variant="text" 
            width="80%" 
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} 
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

const TimelineTab: React.FC<TimelineProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page') || '1', 10);

  const [activities, setActivities] = useState<TimelineActivity[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchTimeline = async () => {
      setLoading(true);
      try {
        const data = await getTimeline(page);
        setActivities(data.results);
        setTotalPages(data.total_pages);
      } catch (error) {
        console.error('タイムラインの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeline();
  }, [page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('page', value.toString());
    navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
  };

  const getActionTextWithLink = (activity: TimelineActivity) => {
    if (activity.type === 3 || activity.type === 4) {
      return (
        <Link
          component="span"
          onClick={() => navigate(`/profile/${activity.user.username}?tab=reviews`)}
          sx={{ 
            cursor: 'pointer',
            color: '#a7a7a7',
            display: 'inline-flex',
            alignItems: 'center',
            gap: 0.5,
            '&:hover': {
              color: '#ffffff',
              textDecoration: 'underline'
            }
          }}
        >
          {getActionText(activity.type)}
          <LaunchIcon sx={{ fontSize: '0.875rem' }} />
        </Link>
      );
    }
    return getActionText(activity.type, activity.old_status, activity.new_status);
  };

  if (loading) {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={1}>
              {[...Array(12)].map((_, index) => (
                <Grid item xs={12} key={index}>
                  <TimelineActivitySkeleton />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          {activities.length === 0 ? (
            <Paper 
              variant="outlined" 
              sx={{ 
                borderRadius: 1, 
                overflow: 'hidden'
              }}
            >
              <Box sx={{ 
                p: 4, 
                textAlign: 'center',
              }}>
                <Typography variant="body1" color="text.secondary">
                  アクティビティフィードはありません。<br />
                  友達や気になったユーザーをフォローしてみましょう！
                </Typography>
              </Box>
            </Paper>
          ) : (
            <Grid container spacing={1}>
              {activities.map((activity) => {
                const actionText = getActionText(activity.type, activity.old_status, activity.new_status);
                
                if (actionText === null) return null;

                return (
                  <Grid item xs={12} key={activity.id}>
                    <Box
                      sx={{
                        px: { xs: 1.5, md: 2 },
                        py: { xs: 1, md: 2 },
                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                        backdropFilter: 'blur(8px)',
                        borderRadius: '8px',
                        border: '1px solid rgba(255, 255, 255, 0.08)',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 } }}>
                        <Avatar 
                          src={activity.user.avatar || undefined}
                          sx={{ 
                            width: { xs: 36, md: 44 }, 
                            height: { xs: 36, md: 44 },
                            border: '1px solid rgba(255, 255, 255, 0.08)',
                            cursor: 'pointer'
                          }}
                          onClick={() => navigate(`/profile/${activity.user.username}`)}
                        />
                        <Box sx={{ flex: 1, minWidth: 0 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.25 }}>
                            <Typography 
                              variant="body2"
                              sx={{ 
                                fontWeight: 500,
                                lineHeight: 1.2,
                                color: '#ffffff',
                                cursor: 'pointer'
                              }}
                              onClick={() => navigate(`/profile/${activity.user.username}`)}
                            >
                              {activity.user.display_name}
                            </Typography>
                            <Typography 
                              variant="caption" 
                              sx={{ color: 'text.secondary', lineHeight: 1.2 }}
                            >
                              {formatRelativeTime(activity.created_at)}
                            </Typography>
                          </Box>

                          <Typography 
                            variant="body2"
                            sx={{ 
                              fontSize: '0.75rem',
                              color: '#a7a7a7',
                              mb: { xs: 0.5, md: 1 }
                            }}
                          >
                            {getActionTextWithLink(activity)}
                          </Typography>

                          <Box 
                            sx={{ 
                              p: 1,
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              borderRadius: 1,
                              cursor: 'pointer'
                            }}
                            onClick={() => navigate(`/game/${activity.game.id}`)}
                          >
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              {activity.game.cover_image && (
                                <img 
                                  src={activity.game.cover_image} 
                                  alt={activity.game.name}
                                  style={{ 
                                    width: 60,
                                    height: '133.33%',
                                    objectFit: 'cover',
                                    borderRadius: 2
                                  }}
                                />
                              )}
                              <Typography variant="body2">
                                {activity.game.name}
                              </Typography>
                            </Box>
                          </Box>

                          {activity.review_text && (
                            <Typography 
                              variant="body2"
                              sx={{ 
                                mt: 1,
                                color: 'text.secondary',
                                fontSize: '0.75rem',
                                lineHeight: 1.3
                              }}
                            >
                              {activity.review_text}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
          
          {totalPages > 1 && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimelineTab; 