import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

interface AdvertisementResponsiveProps {
  isHorizontal?: boolean;
  maxWidth320?: boolean;
}

const AdvertisementResponsive: React.FC<AdvertisementResponsiveProps> = ({ 
  isHorizontal = false,
  maxWidth320 = false 
}) => {
  const adRef = useRef<HTMLDivElement>(null);
  const uniqueId = useRef(`ad-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    try {
      // 既存の広告要素をクリア
      if (adRef.current) {
        adRef.current.innerHTML = '';
      }

      // 新しい広告要素を作成
      const adElement = document.createElement('ins');
      adElement.className = 'adsbygoogle';
      adElement.style.display = 'block';
      adElement.style.width = '100%';
      adElement.style.maxHeight = isHorizontal ? '100px' : 'auto';
      adElement.style.minWidth = '300px';
      adElement.style.maxWidth = maxWidth320 ? '320px' : '100%';
      adElement.setAttribute('data-ad-client', 'ca-pub-6000060497265595');
      adElement.setAttribute('data-ad-slot', '9797170918');
      adElement.setAttribute('data-ad-format', isHorizontal ? 'horizontal' : 'auto');
      adElement.setAttribute('data-full-width-responsive', 'false');
      adElement.id = uniqueId.current;

      // 広告要素を追加
      if (adRef.current) {
        adRef.current.appendChild(adElement);
      }

      // 広告を初期化
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Adsense error:', error);
    }

    // クリーンアップ関数
    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = '';
      }
    };
  }, [isHorizontal, maxWidth320]); // maxWidth320の変更も監視

  return (
    <Box 
      ref={adRef}
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        my: { xs: 1, md: 2 }, 
        width: '100%',
      }}
    />
  );
};

export default AdvertisementResponsive;