import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar, Button, CircularProgress, Pagination, Grid } from '@mui/material';
import { getUserFollowers, getUserFollowing } from '../services/api';
import { Link, useNavigate, useLocation } from 'react-router-dom';

interface FollowsTabProps {
  username: string;
  isPublic: boolean;
}

interface FollowUser {
  username: string;
  display_name: string;
  avatar: string | null;
  bio: string;
}

interface FollowsResponse {
  results: FollowUser[];
  count: number;
  total_pages: number;
  current_page: number;
}

// タブの種類を定数で定義
const FOLLOWS_TABS = {
  FOLLOWING: 'following',
  FOLLOWERS: 'followers'
} as const;

type FollowsTabType = typeof FOLLOWS_TABS[keyof typeof FOLLOWS_TABS];

const FollowsTab: React.FC<FollowsTabProps> = ({ username, isPublic }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = (queryParams.get('follows') as FollowsTabType) || FOLLOWS_TABS.FOLLOWING;
  const initialPage = parseInt(queryParams.get('page') || '1', 10);

  const [tabValue, setTabValue] = useState<FollowsTabType>(initialTab);
  const [followers, setFollowers] = useState<FollowUser[]>([]);
  const [following, setFollowing] = useState<FollowUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [followersData, followingData] = await Promise.all([
          getUserFollowers(username, page),
          getUserFollowing(username, page)
        ]);
        setFollowers(followersData.results);
        setFollowing(followingData.results);
        setFollowerCount(followersData.count);
        setFollowingCount(followingData.count);
        setTotalPages(tabValue === FOLLOWS_TABS.FOLLOWERS ? followersData.total_pages : followingData.total_pages);
      } catch (error) {
        console.error('フォロー情報の取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username, page, tabValue]);

  // URLを更新する関数
  const updateUrl = (tab: FollowsTabType, page: number) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('follows', tab);
    currentParams.set('page', page.toString());
    
    navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const newTab = newValue === 0 ? FOLLOWS_TABS.FOLLOWING : FOLLOWS_TABS.FOLLOWERS;
    setTabValue(newTab);
    setPage(1);
    updateUrl(newTab, 1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    updateUrl(tabValue, value);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Tabs
        value={tabValue === FOLLOWS_TABS.FOLLOWING ? 0 : 1}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          minHeight: { xs: '32px', sm: '40px' },
          '& .MuiTab-root': {
            minHeight: { xs: '36px', sm: '40px' },
            py: { xs: 1, sm: 1.5 },
            px: { xs: 1, sm: 2 },
            fontSize: { xs: '0.65rem', sm: '0.75rem' },
            minWidth: 0,
            borderRadius: '12px',
            '&.Mui-selected': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
          },
        }}
      >
        <Tab label={`フォロー中 (${followingCount})`} />
        <Tab label={`フォロワー (${followerCount})`} />
      </Tabs>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={1}>
              {(tabValue === FOLLOWS_TABS.FOLLOWING ? following : followers).map((user) => (
                <Grid item xs={12} key={user.username}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: 'rgba(255, 255, 255, 0.03)',
                      backdropFilter: 'blur(8px)',
                      borderRadius: '8px',
                      border: '1px solid rgba(255, 255, 255, 0.08)',
                      transition: 'all 0.2s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        transform: 'translateY(-1px)',
                      }
                    }}
                    onClick={() => navigate(`/profile/${user.username}`)}
                  >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Avatar 
                        src={user.avatar || undefined} 
                        sx={{ 
                          width: 48, 
                          height: 48,
                          border: '1px solid rgba(255, 255, 255, 0.08)'
                        }} 
                      />
                      <Box sx={{ flex: 1, minWidth: 0 }}>
                        <Typography 
                          variant="body2"
                          sx={{ 
                            fontWeight: 500,
                            color: '#ffffff',
                            mb: 0
                          }}
                        >
                          {user.display_name || user.username}
                        </Typography>
                        <Typography 
                          variant="body2"
                          sx={{ 
                            fontSize: '0.75rem',
                            color: '#a7a7a7',
                            mb: 1
                          }}
                        >
                          @{user.username}
                        </Typography>
                        {user.bio && (
                          <Typography 
                            variant="body2"
                            sx={{ 
                              fontSize: '0.75rem',
                              color: 'rgba(255, 255, 255, 0.7)',
                              lineHeight: 1.4,
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden'
                            }}
                          >
                            {user.bio}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            
            {totalPages > 1 && (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FollowsTab; 