import React, { useState } from 'react';
import { Button, CircularProgress, Box, Typography } from '@mui/material';
import { followUser, unfollowUser } from '../../services/api';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';

interface FollowButtonProps {
  username: string;
  isFollowing: boolean;
  isOwnProfile: boolean;
  isFollowedBy?: boolean;
  onFollowChange?: (isFollowing: boolean) => void;
}

const FollowButton: React.FC<FollowButtonProps> = ({ 
  username, 
  isFollowing,
  isOwnProfile,
  isFollowedBy = false,
  onFollowChange 
}) => {
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  if (isOwnProfile) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);
    try {
      if (isFollowing) {
        await unfollowUser(username);
        onFollowChange?.(false);
      } else {
        await followUser(username);
        onFollowChange?.(true);
      }
    } catch (error) {
      console.error('フォロー操作に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Button
        variant={isFollowing ? "outlined" : "contained"}
        onClick={handleClick}
        disabled={loading}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        startIcon={loading ? undefined : 
          isFollowing ? 
            (isHovered ? <PersonAddIcon sx={{ fontSize: '1rem' }} /> : <CheckIcon sx={{ fontSize: '1rem' }} />) : 
            <PersonAddIcon sx={{ fontSize: '1rem' }} />
        }
        sx={{
          minWidth: '100px',
          height: '28px',
          textTransform: 'none',
          borderRadius: '8px',
          fontSize: '0.7rem',
          fontWeight: 500,
          letterSpacing: '0.02em',
          transition: 'all 0.2s ease',
          backgroundColor: isFollowing ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.08)',
          border: isFollowing ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(255, 255, 255, 0.12)',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: isFollowing ? 
              (isHovered ? 'rgba(244, 67, 54, 0.1)' : 'rgba(255, 255, 255, 0.12)') : 
              'rgba(255, 255, 255, 0.12)',
            borderColor: isHovered ? isFollowing ? '#f44336' : 'rgba(255, 255, 255, 0.12)' : 'rgba(255, 255, 255, 0.12)',
            color: isHovered && isFollowing ? '#f44336' : '#ffffff',
          },
          '&:disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            color: 'rgba(255, 255, 255, 0.3)',
          }
        }}
      >
        {loading ? (
          <CircularProgress size={14} color="inherit" />
        ) : (
          isFollowing ? 
            (isHovered ? 'フォロー解除' : 'フォロー中') : 
            'フォロー'
        )}
      </Button>
      {isFollowedBy && (
        <Typography 
          variant="caption" 
          sx={{ 
            color: 'text.secondary',
            fontWeight: 400,
            fontSize: '0.6rem',
            lineHeight: '1.2',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            padding: '2px 4px',
            borderRadius: '4px',
          }}
        >
          フォローされています
        </Typography>
      )}
    </Box>
  );
};

export default FollowButton; 