import React, { useState, useEffect, memo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Typography, Grid, Chip, Box, Button, Skeleton, Container, IconButton } from '@mui/material';
import { getGameDetails, getUserGameStatus, getUserBirthDate, getGameImages } from '../services/api';
import SubStatusButton from '../components/SubStatusButton';
import { UserProfile } from '../types/UserProfile';
import { styled } from '@mui/material/styles';
import ReviewSection from '../components/review/ReviewSection';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WikipediaViewer from '../components/WikipediaViewer';
import { Helmet } from 'react-helmet-async';
import GameGallerySection from '../components/image/GameGallerySection';
import GameVideoSection from '../components/video/GameVideoSection';
import Expandable from '../components/common/Expandable';
import SideAdvertisement from '../components/advertisement/Ad_side_300';
import AdvertisementResponsive from '../components/advertisement/Ad_GA_res';
import GameDescriptionSection from '../components/description/GameDescriptionSection';
import GamePhraseSection from '../components/phrase/GamePhraseSection';
import Copyright from '../components/common/Copyright';
import GameTriviaSection from '../components/trivia/GameTriviaSection';
import ProjectCTA from '../components/common/ProjectCTA';
import GameRating from '../components/rating/GameRating';
import GameStatusButton from '../components/GameStatusButton';
import GameReviewButton from '../components/GameReviewButton';
import { GameReviewData } from '../types/Review';
import { Image } from '../types/Game';
import GameModeIcons from '../components/GameModeIcons';
import { Divider } from '@mui/material';
import GameStatusCount from '../components/GameStatusCount';
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';
import PlaySessionButton from '../components/PlaySessionButton';

interface Genre {
  genre_id: number;
  name: string;
}

interface Platform {
  platform_id: number;
  name: string;
}

interface Franchise {
  franchise_id: number;
  name: string;
  japanese_name: string | null;
}

interface Collection {
  collection_id: number;
  name: string;
  japanese_name: string | null;
}

interface GameMode {
  game_mode_id: number;
  name: string;
}

interface Game {
  game_id: number;
  name: string;
  japanese_name: string | null;
  summary: string | null;
  first_release_date: string;
  rating: number | null;
  rating_jp: number | null;
  genres: Genre[];
  platforms: Platform[];
  franchises: Franchise[];
  collections: Collection[];
  cover?: string;
  cover_hd?: string;
  game_engines: GameEngine[];
  themes: Theme[];
  parent_game_id: number | null;
  companies: Company[];
  slug?: string;
  rating_count: number;
  game_modes: GameMode[];
  keywords: Keyword[];
  status_counts: {
    s1: number;
    s2: number;
    s3: number;
    s4: number;
    s5: number;
  };
  links?: {
    steam?: string;
    website?: string;
    x?: string;
  };
}

interface GameEngine {
  game_engine_id: number;
  name: string;
}

interface Keyword {
  keyword_id: number;
  name: string;
  japanese_name: string;
}

interface Theme {
  theme_id: number;
  name: string;
}

interface Company {
  company_id: number;
  name: string;
  japanese_name: string | null;
  role?: string;
}

interface InfoSectionProps {
  title: string;
  items: Array<{ 
    [key: string]: any, 
    name: string, 
    japanese_name?: string | null, 
    role?: string 
  }>;
  linkPrefix: string;
  idKey: string | ((item: any) => string);
  showRole?: boolean;
  customLink?: boolean;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, items, linkPrefix, idKey, showRole = false, customLink = false }) => (
  <Box>
    <Typography variant="subtitle1" sx={{ color: '#FFFFFFB3', fontWeight: 'bold', fontSize: { xs: '0.7rem', sm: '0.7rem' } }}>
      {title}
    </Typography>
    {items && items.length > 0 ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {items.map((item) => {
          const id = typeof idKey === 'function' ? idKey(item) : item[idKey];
          const linkType = item.genre_id ? 'genre' : 'theme';
          const link = customLink ? `/games/${linkType}/${id}` : `${linkPrefix}${id}`;
          const displayName = item.japanese_name || item.name;
          return (
            <Chip
              key={`${id}-${item.role || ''}`}
              label={`${displayName}${showRole && item.role && item.role !== '開発' ? ` (${item.role})` : ''}`}
              component={Link}
              to={link}
              clickable
              size="small"
              sx={{ 
                fontSize: { xs: '0.675rem', sm: '0.7rem' },
                height: { xs: '20px', sm: '24px' },
                bgcolor: 'rgba(255, 255, 255, 0.08)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                },
                color: '#ffffff',
              }}
            />
          );
        })}
      </Box>
    ) : (
      <Typography variant="body2" sx={{ fontSize: { xs: '0.675rem', sm: '0.7rem' }, color: '#a7a7a7' }}>情報なし</Typography>
    )}
  </Box>
);

const FullWidthGlassSection = styled('div')({
  position: 'relative',
  width: '100vw',
  left: '50%',
  right: '50%',
  marginTop: '-240px',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  paddingTop: '64px',
  zIndex: 1,
  '@media (max-width: 899px)': {
    marginTop: '-164px',
    paddingTop: '56px',
  }
});

const BackgroundImage = styled('div')<{ image: string }>(({ image }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'blur(35px)',
  opacity: 0.1,
  zIndex: -1,
}));

const GlassOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(18, 18, 18, 0)',
  zIndex: -1,
});

const ContentWrapper = styled(Container)({
  position: 'relative',
  zIndex: 1,
  overflow: 'visible',
  '@media (max-width: 899px)': {
    paddingTop: '0 !important'
  }
});

const NormalSection = styled('div')({
  position: 'relative',
  zIndex: 2,
  backgroundColor: '#121212',
});

const CoverImageSection = styled('div')({
  position: 'relative',
  width: '100%',
  maxWidth: '400px',
  marginBottom: '0.5rem',
  '@media (max-width: 899px)': {
    width: '35%',
    minWidth: '120px',
    maxWidth: '120px',
    marginTop: '-80px',
    marginLeft: '0px',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
  }
});

const CoverImage = styled('img')<{ expanded: boolean }>(({ expanded }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  transition: 'all 0.3s ease',
  borderRadius: '4px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '@media (max-width: 899px)': {
    aspectRatio: expanded ? 'auto' : '3/4',
    objectFit: expanded ? 'contain' : 'cover',
  }
}));

const HeroImageSection = styled('div')({
  position: 'relative',
  width: '100%',
  height: '464px',
  marginTop: '-64px',
  marginBottom: '-100px',
  overflow: 'hidden',
  '@media (max-width: 899px)': {
    height: '400px',
    marginTop: '-56px',
    marginBottom: '-60px',
  }
});

const HeroImage = styled('img')<{ isCover?: boolean; useCoverStyle?: boolean }>(({ isCover, useCoverStyle }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  opacity: useCoverStyle ? 1 : isCover ? 1 : 0.5,
  ...(isCover || useCoverStyle ? {
    '@media (min-width: 900px)': {
      maxWidth: '1320px',
      margin: '0 auto',
      display: 'block',
      maskImage: 'linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)',
      WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)',
    }
  } : {})
}));

const HeroGradient = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '200px',
  background: 'linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.85) 70%, rgba(18, 18, 18, 1) 100%)',
  pointerEvents: 'none',
});

const HeroFallbackGradient = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(135deg, #364201 0%, #121212 100%)',
  opacity: 0.5,
});

const HeroFallbackCoverGradient = styled('div')<{ useCoverStyle?: boolean }>(({ useCoverStyle }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: useCoverStyle ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.5)',
  '@media (min-width: 900px)': {
    maxwidth: '1320px',
    margin: '0 auto',
  }
}));

const HeroOuterCoverBackground = styled('div')<{ image: string }>(({ image }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'blur(25px)',
  opacity: 0.35,
  display: 'none',  // デフォルトで非表示
  '@media (min-width: 900px)': {  // PCでのみ表示
    display: 'block'
  }
}));

const StatusButtonsContainer = styled(Box)({
  marginTop: '8px',
  '@media (max-width: 899px)': {
    position: 'absolute',
    right: '-120px',
    bottom: '0',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '110px'
  }
});

const LoginPrompt = styled(Link)({
  fontSize: '0.7rem',
  color: 'rgba(255, 255, 255, 0.7)',
  marginTop: '8px',
  textAlign: 'center',
  padding: '4px 8px',
  borderRadius: '4px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  display: 'block',
  textDecoration: 'none',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  '@media (max-width: 899px)': {
    fontSize: '0.6rem',
    position: 'absolute',
    textAlign: 'left',
    lineHeight: '1.2',
    right: '-210px',
    bottom: '0',
    width: '200px',
    marginTop: 0
  }
});

const GameDetailsPage: React.FC = () => {
  const { game_id } = useParams<{ game_id: string }>();
  const [game, setGame] = useState<Game | null>(null);
  const [parentGame, setParentGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [gameStatus, setGameStatus] = useState<number | null>(null);
  const [gameScore, setGameScore] = useState<number | null>(null);
  const [gameReviewText, setGameReviewText] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [gamePlayStartDate, setGamePlayStartDate] = useState<string | null>(null);
  const [gamePlayEndDate, setGamePlayEndDate] = useState<string | null>(null);
  const [gamePlayTime, setGamePlayTime] = useState<number | null>(null);
  const [gameSubStatus, setGameSubStatus] = useState<number[]>([]);
  const [gameScoreTags, setGameScoreTags] = useState<number[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [wikiSummary, setWikiSummary] = useState<string | null>(null);
  const [wikiOpen, setWikiOpen] = useState(false);
  const [hasWikiData, setHasWikiData] = useState(false);
  const [totalReviews, setTotalReviews] = useState(0);
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const [heroImage, setHeroImage] = useState<string | null>(null);
  const [gamePlatform, setGamePlatform] = useState<number[]>([]);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [initialImages, setInitialImages] = useState<Image[]>([]);
  const [totalImageCount, setTotalImageCount] = useState<number>(0);

  useEffect(() => {
    const determineAuthentication = () => {
      const token = localStorage.getItem('token');
      const authenticated = !!token;
      setIsAuthenticated(authenticated);
      return authenticated;
    };

    const fetchGameDetails = async () => {
      setLoading(true);
      setGame(null);
      setParentGame(null);
      setGameStatus(null);
      setGameScore(null);
      setGameReviewText(null);
      setGamePlayStartDate(null);
      setGamePlayEndDate(null);
      setGamePlayTime(null);
      setGameSubStatus([]);
      setGameScoreTags([]);

      try {
        if (game_id) {
          const data = await getGameDetails(parseInt(game_id, 10));
          setGame(data);
          
          if (data.parent_game_id !== null) {
            const parentData = await getGameDetails(data.parent_game_id.toString());
            setParentGame(parentData);
          }

          const authenticated = determineAuthentication();

          if (authenticated) {
            const { status, score, reviewText, playStartDate, playEndDate, playTime, subStatus, scoreTags, platform } = await getUserGameStatus(parseInt(game_id, 10));
            setGameStatus(status);
            setGameScore(score);
            setGameReviewText(reviewText);
            setGamePlayStartDate(playStartDate);
            setGamePlayEndDate(playEndDate);
            setGamePlayTime(playTime);
            setGameSubStatus(subStatus || []);
            setGameScoreTags(scoreTags || []);
            setGamePlatform(platform || []);

            const birthDate = await getUserBirthDate();
            setUserProfile(birthDate ? { birth_date: birthDate } as UserProfile : null);
          }
        }
      } catch (error) {
        console.error('ゲーム詳細の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();

    if (!loading) {
      window.scrollTo(0, 0);
    }
  }, [game_id]);

  useEffect(() => {
    if (game) {
      const fetchWikiSummary = async () => {
        try {
          const response = await fetch(`https://ja.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(game.japanese_name || game.name)}`);
          const data = await response.json();
          if (data.extract) {
            setWikiSummary(data.extract);
            setHasWikiData(true);
          } else {
            setHasWikiData(false);
          }
        } catch (error) {
          console.error('Wikipedia APIからのデータ取得に失敗しました:', error);
          setHasWikiData(false);
        }
      };

      fetchWikiSummary();
    }
  }, [game]);

  useEffect(() => {
    const fetchInitialImages = async () => {
      try {
        const response = await getGameImages(parseInt(game_id!, 10), 5);
        setInitialImages(response.results);
        setTotalImageCount(response.count);
        
        // ヒーロー画像の設定
        if (response.results && response.results.length > 0) {
          const firstImage = response.results[0];
          const imageUrl = firstImage.is_igdb
            ? `https://images.igdb.com/igdb/image/upload/t_1080p/${firstImage.image_id}.jpg`
            : `${process.env.REACT_APP_R2_PUBLIC_URL}/images/games/${game_id}/${firstImage.image_id}_opt.webp`;
          setHeroImage(imageUrl);
        }
      } catch (error) {
        console.error('初期画像の取得に失敗しました:', error);
      }
    };

    if (game_id) {
      fetchInitialImages();
    }
  }, [game_id]);

  const formatReleaseDateInfo = (releaseDate: string) => {
    const release = new Date(releaseDate);
    const now = new Date();
    const threeYearsFromNow = new Date();
    threeYearsFromNow.setFullYear(now.getFullYear() + 3);
    const isFutureRelease = release > now;

    // 3年以上先の発売日の場合は「未定」を返す
    if (release > threeYearsFromNow) {
      return '未定';
    }

    // 12月31日に設定されている場合は年のみ表示
    if (isFutureRelease && release.getMonth() === 11 && release.getDate() === 31) {
      return release.getFullYear().toString();
    }

    if (isFutureRelease) {
      return release.toLocaleDateString('ja-JP', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      }).replace(/\//g, '/');
    }

    const diffTime = now.getTime() - release.getTime();
    const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365.25));
    const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44));
    const diffDays = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24));

    let ageString = '';
    if (diffYears >= 1) {
      const birthYear = userProfile?.birth_date ? new Date(userProfile.birth_date) : null;
      if (birthYear) {
        let ageAtRelease = release.getFullYear() - birthYear.getFullYear();
        if (release < new Date(release.getFullYear(), birthYear.getMonth(), birthYear.getDate())) {
          ageAtRelease--;
        }
        ageString = `｜${ageAtRelease}歳のとき`;
      }
    }

    let timeAgo = '';
    if (diffYears > 0) {
      timeAgo += `${diffYears}年`;
    }
    if (diffMonths > 0) {
      timeAgo += `${diffMonths}ヶ月`;
    }
    if (timeAgo === '' && diffDays > 0) {
      timeAgo = `${diffDays}日`;
    }
    if (timeAgo === '') {
      timeAgo = '0日';
    }

    const dateString = release.toLocaleDateString('ja-JP', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit' 
    }).replace(/\//g, '/');

    return `${dateString}（${timeAgo}前${ageString}）`;
  };

  // プラットフォームIDが7または8の場合にカバースタイルを使用するかどうかを判定
  const shouldUseCoverStyle = game?.platforms?.some(platform => 
    [4, 7, 8, 20, 22, 24, 33, 37].includes(platform.platform_id)
  );

  if (loading) {
    return (
      <>
        <HeroImageSection>
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height="100%" 
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              bgcolor: 'rgba(255, 255, 255, 0.05)'
            }} 
          />
          <HeroGradient />
        </HeroImageSection>
        <FullWidthGlassSection>
          <ContentWrapper maxWidth="lg" sx={{ px: 1.5, pt: { xs: 2, md: 4 }, pb: 4 }}>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={3.5}>
                <CoverImageSection>
                  <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                      width: '100%',
                      height: 0,
                      paddingBottom: '133.33%', // 縦4横3の比率を維持
                      borderRadius: '4px'
                    }} 
                  />
                  <Box sx={{ mt: 1 }}>
                    <Skeleton width="100%" height={36} />
                    <Skeleton width="60%" height={36} sx={{ mt: 1 }} />
                  </Box>
                </CoverImageSection>
              </Grid>
              <Grid item xs={12} md={7}>
                <Skeleton width="80%" height={32} />
                <Skeleton width="60%" height={24} sx={{ mt: 1 }} />
                <Box sx={{ mt: 2 }}>
                  <Skeleton width={80} height={32} sx={{ mr: 1, display: 'inline-block' }} />
                  <Skeleton width={80} height={32} sx={{ mr: 1, display: 'inline-block' }} />
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Skeleton width="100%" height={100} />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Skeleton width={120} height={36} sx={{ mr: 1, display: 'inline-block' }} />
                  <Skeleton width={120} height={36} sx={{ mr: 1, display: 'inline-block' }} />
                  <Skeleton width={120} height={36} sx={{ display: 'inline-block' }} />
                </Box>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <Skeleton width="100%" height={24} />
                    <Skeleton width="80%" height={20} sx={{ mt: 1 }} />
                    <Skeleton width="90%" height={20} sx={{ mt: 1 }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton width="100%" height={24} />
                    <Skeleton width="70%" height={20} sx={{ mt: 1 }} />
                    <Skeleton width="85%" height={20} sx={{ mt: 1 }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ContentWrapper>
        </FullWidthGlassSection>
        <NormalSection>
          <Container maxWidth="lg" sx={{ 
            px: 1.5, 
            pt: { xs: 2, md: 2 },
            pb: 4,
            minHeight: '100vh',
            bgcolor: '#121212'
          }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Box sx={{ height: '500vh' }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ height: '500vh' }} />
              </Grid>
            </Grid>
          </Container>
        </NormalSection>
      </>
    );
  }

  if (!game) {
    return <Typography>ゲーム情報が見つかりません。</Typography>;
  }

  const handleStatusChange = async (newStatus: number | null) => {
    setGameStatus(newStatus);
    if (newStatus === null) {
      // ステータス削除時は関連データをクリア
      setGameScore(null);
      setGameReviewText(null);
      setGamePlayStartDate(null);
      setGamePlayEndDate(null);
      setGamePlayTime(null);
      setGameSubStatus([]);
      setGameScoreTags([]);
      setGamePlatform([]);
    } else if ([1, 2, 3].includes(newStatus)) {
      // ステータスが1,2,3の場合、レビューダイアログを自動で開く
      setOpenReviewDialog(true);
    }
  };

  const handleReviewChange = async (reviewData: GameReviewData) => {
    setGameScore(reviewData.score);
    setGameReviewText(reviewData.reviewText);
    setGamePlayStartDate(reviewData.playStartDate);
    setGamePlayEndDate(reviewData.playEndDate);
    setGamePlayTime(reviewData.playTime);
    setGameScoreTags(reviewData.scoreTags);
    setGamePlatform(reviewData.platform);
  };

  const initialReviewData: GameReviewData = {
    score: gameScore,
    reviewText: gameReviewText,
    playStartDate: gamePlayStartDate,
    playEndDate: gamePlayEndDate,
    playTime: gamePlayTime,
    scoreTags: gameScoreTags,
    platform: gamePlatform
  };

  const processCompanies = (companies: Company[]) => {
    const roleMap: { [key: string]: Company[] } = {
      '開発': [],
      '協力': [],
      '移植': [],
      '発売': []
    };

    companies.forEach(company => {
      if (company.role) {
        const roles = company.role.split(', ');
        roles.forEach(role => {
          if (roleMap[role]) {
            roleMap[role].push({...company, role});
          } else {
            roleMap['その他'] = roleMap['その他'] || [];
            roleMap['その他'].push({...company, role});
          }
        });
      }
    });

    return roleMap;
  };

  const companyRoles = processCompanies(game.companies);

  const developers = [...companyRoles['開発'], ...companyRoles['協力'], ...companyRoles['移植']];
  const publishers = companyRoles['発売'];

  const genresAndThemes = [...game.genres, ...game.themes];

  const handleOpenReviewDialog = () => {
    setOpenReviewDialog(true);
  };

  return (
    <>
      <Helmet>
        <title>
          {game?.japanese_name 
            ? `${game.japanese_name} / ${game.name}${game.first_release_date ? ` (${new Date(game.first_release_date).getFullYear()})` : ''} のゲーム情報 | PULSE`
            : `${game?.name}${game?.first_release_date ? ` (${new Date(game.first_release_date).getFullYear()})` : ''} のゲーム情報 | PULSE ゲームデータベース`
          }
        </title>
      </Helmet>
      <HeroImageSection>
        {heroImage ? (
          <>
            {shouldUseCoverStyle && <HeroOuterCoverBackground image={heroImage} />}
            <HeroImage 
              src={heroImage} 
              alt={game?.name || ''} 
              useCoverStyle={shouldUseCoverStyle}
            />
            <Box sx={{ 
              position: 'absolute',
              top: 120,
              right: 12,
              zIndex: 2,
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'column',
              gap: 1
            }}>
              <GameRating rating={game.rating} rating_jp={game.rating_jp} ratingCount={game.rating_count} isMobile />
              <GameStatusCount counts={game.status_counts} isMobile />
            </Box>
            {shouldUseCoverStyle ? <HeroFallbackCoverGradient useCoverStyle={shouldUseCoverStyle} /> : null}
            <HeroGradient />
          </>
        ) : game?.cover_hd ? (
          <>
            <HeroOuterCoverBackground image={game.cover_hd} />
            <Box sx={{ 
              position: 'absolute',
              top: 120,
              right: 12,
              zIndex: 2,
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'column',
              gap: 1
            }}>
              <GameRating rating={game.rating} rating_jp={game.rating_jp} ratingCount={game.rating_count} isMobile />
              <GameStatusCount counts={game.status_counts} isMobile />
            </Box>
            <HeroImage src={game.cover_hd} alt={game?.name || ''} isCover />
            <HeroFallbackCoverGradient />
            <HeroGradient />
          </>
        ) : (
          <>
            <HeroFallbackGradient />
            <HeroGradient />
          </>
        )}
      </HeroImageSection>
      <FullWidthGlassSection>
        <BackgroundImage image={game?.cover_hd || ''} />
        <GlassOverlay />
        <ContentWrapper maxWidth="lg" sx={{ 
          px: 1.5, 
          pt: { 
            xs: heroImage ? 2 : 0,
            md: heroImage ? 4 : 6 
          }, 
          pb: { xs: 2, md: 4 } 
        }}>
          <Grid container spacing={{ xs: 2, md: 4 }} sx={{ 
            '@media (max-width: 899px)': {
              flexDirection: 'column'
            }
          }}>
            <Grid item xs={12} md={3.5} sx={{
              '@media (max-width: 899px)': {
                flex: '0 0 auto',
                maxWidth: '100%',
                paddingTop: 0
              }
            }}>
              <CoverImageSection>
                <CoverImage
                  src={game.cover_hd || '/image/no_image.png'}
                  alt={game.japanese_name || game.name}
                  expanded={isImageExpanded}
                />
                <StatusButtonsContainer sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap',
                  gap: 1,
                  '& > *': {
                    flexShrink: 0
                  },
                  '@media (max-width: 899px)': {
                    marginBottom: !isAuthenticated ? '48px' : 0
                  }
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <GameStatusButton
                      gameId={game.game_id}
                      initialStatus={gameStatus}
                      onStatusChange={handleStatusChange}
                    />
                    {(gameStatus === 1 || gameStatus === 2 || gameStatus === 3) && (
                      <GameReviewButton
                        gameId={game.game_id}
                        initialReviewData={initialReviewData}
                        platforms={game.platforms}
                        releaseDate={game.first_release_date}
                        userBirthDate={userProfile?.birth_date}
                        onReviewChange={handleReviewChange}
                        openDialog={openReviewDialog}
                        onDialogClose={() => setOpenReviewDialog(false)}
                      />
                    )}
                  </Box>
                  {gameStatus && (
                    <SubStatusButton
                      gameId={game.game_id}
                      status={gameStatus}
                      initialSubStatus={gameSubStatus}
                      onSubStatusChange={(newSubStatus) => setGameSubStatus(newSubStatus)}
                    />
                  )}
                  {gameStatus === 2 && (
                    <PlaySessionButton 
                      gameId={game.game_id}
                      disabled={!isAuthenticated}
                    />
                  )}
                </StatusButtonsContainer>
                {!isAuthenticated && (
                  <LoginPrompt to="/login">
                    ログインしてゲームを記録・管理しよう
                  </LoginPrompt>
                )}
              </CoverImageSection>
            </Grid>
            <Grid item xs={12} md={7} sx={{
              '@media (max-width: 899px)': {
                paddingTop: 0
              }
            }}>
              {game.japanese_name ? (
                <>
                  <Typography variant="h4" component="h1" sx={{ fontSize: { xs: '0.9rem', sm: '1.3rem' } }}>
                    {game.japanese_name}
                    {game.first_release_date && formatReleaseDateInfo(game.first_release_date) !== '未定' && (
                      <Typography component="span" color="text.secondary" sx={{ ml: { xs: 1, sm: 1.5 }, fontSize: '1em' }}>
                        ({new Date(game.first_release_date).getFullYear()})
                      </Typography>
                    )}
                  </Typography>
                  <Typography 
                    variant="h6" 
                    component="h2" 
                    gutterBottom 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: { xs: '0.7rem', sm: '0.8rem', lineHeight: 1.2 },
                      mb: 1
                    }}
                  >
                    {game.name}
                  </Typography>
                </>
              ) : (
                <Typography variant="h4" component="h1" sx={{ fontSize: { xs: '0.9rem', sm: '1.3rem' }, mb: { xs: 0.5, sm: 0 } }}>
                  {game.name}
                  {game.first_release_date && formatReleaseDateInfo(game.first_release_date) !== '未定' && (
                    <Typography component="span" color="text.secondary" sx={{ ml: { xs: 1, sm: 1.5 }, fontSize: '1em' }}>
                      ({new Date(game.first_release_date).getFullYear()})
                    </Typography>
                  )}
                </Typography>
              )}
              
              {/* ジャンル情報を追加 */}
              {genresAndThemes.length > 0 && (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#a7a7a7',
                    fontSize: { xs: '0.6rem', sm: '0.7rem' },
                    mb: 1,
                    display: '-webkit-box',
                    WebkitLineClamp: { xs: 1, sm: 'none' },
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    '& > span::before': {
                      content: '"・"',
                      color: 'rgba(255, 255, 255, 0.3)'
                    }
                  }}
                >
                  {genresAndThemes.slice(0, 4).map((item) => {
                    const isGenre = 'genre_id' in item;
                    const id = isGenre ? (item as Genre).genre_id : (item as Theme).theme_id;
                    const type = isGenre ? 'genre' : 'theme';
                    
                    return (
                      <Box 
                        component="span" 
                        key={`${type}-${id}`}
                        sx={{ 
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#ffffff',
                          }
                        }}
                        onClick={() => navigate(`/games/${type}/${id}`)}
                      >
                        <Link to={`/games/${type}/${id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                          {item.name}
                        </Link>
                      </Box>
                    );
                  })}
                </Typography>
              )}
              
              {/* プラットフォーム情報 */}
              <Box sx={{ mb: 1 }}>
                {game.platforms && game.platforms.length > 0 ? (
                  game.platforms.map((platform) => (
                    <Chip
                      key={platform.platform_id}
                      label={platform.name}
                      component={Link}
                      to={`/games/platform/${platform.platform_id}`}
                      clickable
                      variant="outlined"
                      sx={{ 
                        mr: { xs: 0.5, sm: 1 }, 
                        mb: { xs: 0.5, sm: 1 },
                        fontSize: { xs: '0.675rem', sm: '0.7rem' },
                        height: { xs: '22px', sm: '28px' },
                        '& .MuiChip-label': {
                          padding: { xs: '0 10px', sm: '0 12px' }
                        }
                      }}
                    />
                  ))
                ) : (
                  <Typography variant="body2">プラットフォーム情報なし</Typography>
                )}
              </Box>

              {parentGame && (
                <Box sx={{ 
                  mb: 2, 
                  mt: 1,
                  position: 'relative',
                  width: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(8px)',
                  borderRadius: '8px',
                  border: '1px solid rgba(255, 255, 255, 0.08)',
                  overflow: 'hidden',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    transform: 'translateY(-1px)',
                  }
                }}
                onClick={() => navigate(`/game/${parentGame.game_id}`)}
                >
                  <Box sx={{ 
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    color: '#a7a7a7',
                    px: 1,
                    py: 0.25,
                    borderRadius: '4px',
                    fontSize: '0.6rem',
                    letterSpacing: '0.02em',
                    fontWeight: 500,
                    zIndex: 1,
                  }}>
                    Original
                  </Box>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1.5,
                  }}>
                    <Box 
                      component="img"
                      src={parentGame.cover_hd ? parentGame.cover_hd : '/image/no_image.png'}
                      alt={parentGame.name}
                      sx={{ 
                        width: 60,
                        height: 80,
                        objectFit: 'cover',
                        borderRadius: '2px',
                        mr: 1.5,
                      }}
                    />
                    <Box sx={{ 
                      flex: 1, 
                      minWidth: 0,
                    }}>
                      <Typography 
                        sx={{ 
                          fontSize: { xs: '0.7rem', sm: '0.8rem' },
                          fontWeight: 500,
                          lineHeight: 1.3,
                          mb: 0.5,
                          color: '#ffffff',
                          pr: 8
                        }}
                      >
                        {parentGame.japanese_name || parentGame.name}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                        <Typography sx={{ 
                          fontSize: { xs: '0.6rem', sm: '0.65rem' },
                          color: '#a7a7a7',
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}>
                          {parentGame.first_release_date ? new Date(parentGame.first_release_date).getFullYear() : '発売年不明'}
                          <Box component="span" sx={{ 
                            width: '1px',
                            height: '1.5em',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            mx: 0.5,
                          }} />
                          {parentGame.platforms && parentGame.platforms.length > 0 
                            ? parentGame.platforms.map(platform => platform.name).join(', ') 
                            : 'プラットフォーム不明'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              
              {(game.summary || wikiSummary) && (
                <Box sx={{ position: 'relative', mb: 2 }}>
                  <Expandable maxLines={5} expandButtonStyle="icon">
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary', 
                        fontSize: '0.7rem',
                        lineHeight: 1.35,
                        margin: 0
                      }}
                    >
                      {game.summary || wikiSummary}
                      {hasWikiData && (
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            color: 'rgba(255, 255, 255, 0.5)',
                            cursor: 'pointer',
                            ml: 0.5,
                            fontSize: 'inherit',
                            verticalAlign: 'middle',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                          onClick={() => setWikiOpen(true)}
                        >
                          <AutoStoriesIcon fontSize="small" sx={{ mr: 0.5, fontSize: '0.7rem', verticalAlign: 'middle' }} />
                          Viewerで見る
                        </Typography>
                      )}
                    </Typography>
                  </Expandable>
                </Box>
              )}
              

              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 1 } }}>
                    <InfoSection title="デベロッパー" items={developers} linkPrefix="/games/company/" idKey="company_id" showRole={true} />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 1 } }}>
                    <InfoSection title="パブリッシャー" items={publishers} linkPrefix="/games/company/" idKey="company_id" showRole={false} />
                    {/* <InfoSection title="ゲームエンジン" items={game.game_engines} linkPrefix="/games/game-engine/" idKey="game_engine_id" /> */}
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: -1 }}>
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 1 } }}>
                    <InfoSection title="IP" items={game.franchises} linkPrefix="/games/franchise/" idKey="franchise_id" />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 1 } }}>
                    <InfoSection title="シリーズ" items={game.collections} linkPrefix="/games/collection/" idKey="collection_id" />
                    {/* <InfoSection title="ゲームエンジン" items={game.game_engines} linkPrefix="/games/game-engine/" idKey="game_engine_id" /> */}
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ mt: 2 }}>
              {/* ゲームモード情報 */}
              {game.game_modes && game.game_modes.length > 0 && (
                <GameModeIcons gameModes={game.game_modes} gameEngines={game.game_engines} keywords={game.keywords} />
              )}
                            {/* Twitch、Amazon、YouTubeのリンク */}
                            <Box sx={{ 
                mb: 1, 
                display: 'flex', 
                gap: { xs: 0.75, sm: 1 },
                '& .MuiButton-root': {
                  minWidth: 'auto',
                  borderRadius: { xs: '6px', sm: '8px' },
                  transition: 'all 0.2s ease',
                  backgroundColor: 'rgba(255, 255, 255, 0.06)',
                  backdropFilter: 'blur(8px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  textTransform: 'none',
                  padding: { xs: '2px 12px', sm: '1px 12px' },
                  color: '#ffffffe8',
                  fontSize: { xs: '0.675rem', sm: '0.7rem' },
                  fontWeight: 500,
                  letterSpacing: '0.02em',
                  '& .MuiButton-startIcon': {
                    margin: { xs: 0, sm: '0 8px 0 -4px' },
                    '& > *:nth-of-type(1)': {
                      fontSize: { xs: 20, sm: 18 }
                    }
                  },
                  '& .MuiButton-endIcon': {
                    margin: 0
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.12)',
                    transform: 'translateY(-1px)',
                    '& img': {
                      opacity: 1
                    }
                  }
                }
              }}>
                {game.links?.website && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<LanguageIcon sx={{ fontSize: { xs: 20, sm: 18 }, opacity: 0.6 }} />}
                    component="a"
                    href={game.links.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Web</Box>
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={
                    <img 
                      src="/icons/service/amazon.svg" 
                      alt="Amazon" 
                      style={{ 
                        width: 20,
                        height: 20,
                        filter: 'brightness(0) saturate(100%) invert(71%) sepia(86%) saturate(1857%) hue-rotate(360deg) brightness(103%) contrast(106%)',
                        opacity: 0.9 
                      }} 
                    />
                  }
                  component="a"
                  href={`https://www.amazon.co.jp/s?k=${encodeURIComponent(game.japanese_name || game.name)}&tag=pulse-db-22`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Amazon</Box>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={
                    <img 
                      src="/icons/service/youtube.svg" 
                      alt="YouTube" 
                      style={{ 
                        width: 20,
                        height: 20,
                        filter: 'brightness(0) saturate(100%) invert(16%) sepia(89%) saturate(6054%) hue-rotate(357deg) brightness(97%) contrast(113%)',
                        opacity: 0.8
                      }} 
                    />
                  }
                  component="a"
                  href={`https://www.youtube.com/results?search_query=${encodeURIComponent(game.japanese_name || game.name)}+ゲーム`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>YouTube</Box>
                </Button>
                {game.links?.steam && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={
                      <img 
                        src="/icons/service/steam.svg" 
                        alt="Steam" 
                        style={{ 
                          width: 20, 
                          height: 20,
                          opacity: 0.9
                        }} 
                      />
                    }
                    component="a"
                    href={`https://store.steampowered.com/app/${game.links.steam}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Steam</Box>
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={
                    <img 
                      src="/icons/service/twitch.svg" 
                      alt="Twitch" 
                      style={{ 
                        width: 20, 
                        height: 20,
                        filter: 'brightness(0) saturate(100%) invert(35%) sepia(95%) saturate(1731%) hue-rotate(242deg) brightness(101%) contrast(101%)',
                        opacity: 0.9
                      }} 
                    />
                  }
                  component="a"
                  href={`https://www.twitch.tv/directory/category/${encodeURIComponent((game.slug || game.name).replace(/--\d+$/, ''))}/videos/all`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Twitch</Box>
                </Button>
                {game.links?.x && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<XIcon sx={{ width: 19, height: 19, opacity: 0.9, marginRight: { xs: 0, sm: -1.5 } }} />}
                    component="a"
                    href={`https://twitter.com/${game.links.x}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  </Button>
                )}
              </Box>
              

                <Typography variant="subtitle1">
                  <strong>発売日:</strong> {game.first_release_date ? formatReleaseDateInfo(game.first_release_date) : '情報なし'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={1.5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box sx={{ 
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                width: '100%'
              }}>
                <GameRating rating={game.rating} rating_jp={game.rating_jp} ratingCount={game.rating_count} />
                <GameStatusCount counts={game.status_counts} />
              </Box>
            </Grid>
          </Grid>
        </ContentWrapper>
      </FullWidthGlassSection>
      <NormalSection>
        <Container maxWidth="lg" sx={{ 
          px: 1.5, 
          pt: { xs: 2, md: 2 },
          pb: 4 
        }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <GameDescriptionSection 
                gameId={parseInt(game_id!, 10)} 
                steamId={game?.links?.steam}
              />
              
              {/* スマホ横長広告 */}
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <AdvertisementResponsive maxWidth320={true} />
              </Box>
              
              {/* レビューセクションを1回だけレンダリング */}
              <ReviewSection 
                gameId={parseInt(game_id!, 10)} 
                limit={4} 
                onTotalReviewsChange={setTotalReviews}
                onReviewClick={handleOpenReviewDialog}
                isAuthenticated={isAuthenticated}
                gameStatus={gameStatus}
                renderAfterComponent={
                  <>
                    <GameGallerySection 
                      gameId={parseInt(game_id!, 10)}
                      initialImages={initialImages}
                      totalCount={totalImageCount}
                    />
                    <GameVideoSection gameId={parseInt(game_id!, 10)} />
                  </>
                }
              />

              <GamePhraseSection gameId={parseInt(game_id!, 10)} />
              <GameTriviaSection gameId={parseInt(game_id!, 10)} />
              <Copyright
                publishers={publishers} 
                releaseYear={game.first_release_date}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SideAdvertisement />
            </Grid>
          </Grid>
          {!isAuthenticated && <ProjectCTA loading={loading} />}
        </Container>
      </NormalSection>
      <WikipediaViewer
        open={wikiOpen}
        onClose={() => setWikiOpen(false)}
        title={game?.japanese_name || game?.name || ''} 
        gameId={game.game_id} // game_idを追加
      />
    </>
  );
};

export default memo(GameDetailsPage);
