import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, InputBase, useTheme, useMediaQuery, Avatar, Menu, MenuItem, IconButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logout } from '../services/api';  // この行を追加
import PopoverMenu from './common/PopoverMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { isPwa } from '../utils/isPwa';  // isPwaをインポート
import NotificationsMenu from './common/NotificationsMenu';
import XIcon from '@mui/icons-material/X';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none', // スマートフォンでは非表示
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

// コンポーネントの外で初期状態を設定
const getInitialState = () => {
  const token = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  return {
    isLoggedIn: !!token,
    user: storedUser ? JSON.parse(storedUser) : null
  };
};

const Header: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // 初期状態を同期的に設定
  const [isLoggedIn, setIsLoggedIn] = useState(getInitialState().isLoggedIn);
  const [user, setUser] = useState<any>(getInitialState().user);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState< null | HTMLElement >(null);
  const open = Boolean(anchorEl);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isTransparent = location.pathname === '/' || location.pathname.startsWith('/game/');

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    };

    // ストレージの変更を監視
    window.addEventListener('storage', handleStorageChange);
    handleStorageChange(); // 初期化

    // カスタムイベントをリスン
    const handleLoginSuccess = (event: Event) => {
      const customEvent = event as CustomEvent;
      setIsLoggedIn(true);
      setUser(customEvent.detail);
    };
    window.addEventListener('loginSuccess', handleLoginSuccess as EventListener);

    // ログアウトイベントをリスン
    const handleLogout = () => {
      setIsLoggedIn(false);
      setUser(null);
    };
    window.addEventListener('logout', handleLogout);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    if (isTransparent) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('loginSuccess', handleLoginSuccess as EventListener);
      window.removeEventListener('logout', handleLogout);
    };
  }, [isTransparent]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
  };

  const handleLogout = async () => {
    await logout();
    setIsLoggedIn(false);
    setUser(null); // ログアウト時にユーザー情報をクリア
    navigate('/');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = [
    {
      icon: <AccountCircleIcon />,
      label: 'マイページ',
      onClick: () => {
        navigate('/profile');
        handleClose();
      }
    },
    {
      icon: <SettingsIcon />,
      label: '設定',
      onClick: () => {
        navigate('/profile/settings');
        handleClose();
      }
    },
    // PWAでない場合のみインストールオプションを表示
    ...(!isPwa() ? [{
      icon: <PhoneIphoneIcon />,
      label: 'インストール',
      onClick: () => {
        navigate('/install-pwa');
        handleClose();
      }
    }] : []),
    {
      icon: <XIcon />,
      label: '最新情報',
      onClick: () => {
        window.open('https://x.com/pulse_database', '_blank');
        handleClose();
      }
    },
    {
      icon: <HelpOutlineIcon />,
      label: 'ヘルプ・サポート',
      items: [
        {
          icon: <EmailOutlinedIcon />,
          label: 'お問い合わせ',
          onClick: () => {
            window.open('https://dev.plsdb.com/contact', '_blank');
            handleClose();
          }
        },
        {
          icon: <GavelIcon />,
          label: '利用規約',
          onClick: () => {
            navigate('/terms-of-service');
            handleClose();
          }
        },
        {
          icon: <SecurityIcon />,
          label: 'プライバシー',
          onClick: () => {
            navigate('/privacy-policy');
            handleClose();
          }
        }
      ]
    },
    {
      icon: <LogoutIcon />,
      label: 'ログアウト',
      onClick: () => {
        handleLogout();
        handleClose();
      }
    }
  ];

  return (
    <AppBar 
      position="fixed"
      sx={{
        background: isTransparent
          ? isScrolled 
            ? 'rgba(18, 18, 18, 0.96)'
            : 'transparent'
          : 'rgba(18, 18, 18, 0.96)',
        boxShadow: isTransparent && !isScrolled ? 'none' : undefined,
        transition: 'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        backdropFilter: isScrolled || !isTransparent ? 'blur(10px)' : 'none',
      }}
    >
      <Toolbar sx={{ px: 1.5 }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <img
              src="/pulse_logo.png"
              alt="PULSE Logo"
              style={{
                height: isMobile ? '22px' : '28px',
                marginRight: '8px'
              }}
            />
          </Link>
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <form onSubmit={handleSearchSubmit}>
            <StyledInputBase
              placeholder="ゲームを検索..."
              inputProps={{ 'aria-label': 'search' }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </form>
        </Search>
        {isMobile && !isPwa() && (
          <IconButton
            color="inherit"
            aria-label="search"
            onClick={() => navigate('/search')}
            sx={{ mr: 0 }}
          >
            <SearchIcon />
          </IconButton>
        )}
        {isLoggedIn ? (
          <>
            <NotificationsMenu />
            <IconButton
              onClick={handleClick}
              size="large"
              sx={{ ml: 0 }}
            >
              <Avatar alt={user?.name} src={user?.picture} sx={{ width: 32, height: 32, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
            </IconButton>
            <PopoverMenu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              options={menuOptions}
            />
          </>
        ) : (
          <Button color="inherit" component={Link} to="/login">ログイン</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
