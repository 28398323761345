import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Pagination, PaginationItem } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAnticipatedGames, getBasicGameStatuses } from '../../services/api';
import GameCard from '../../components/GameCard';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { Game } from '../../types/Game';
import { Helmet } from 'react-helmet-async';

const AnticipatedGamesPage: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!localStorage.getItem('token'));
  const location = useLocation();
  const navigate = useNavigate();
  const limit = 30;

  // URLからパラメータを取得
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page') || '1', 10);
  const currentPeriod = parseInt(query.get('period') || '90', 10);

  const periods = [
    { value: 90, label: '3ヶ月' },
    { value: 180, label: '6ヶ月' },
    { value: 730, label: '全期間' },
  ];

  const fetchGames = async (page: number, period: number) => {
    setLoading(true);
    try {
      const offset = (page - 1) * limit;
      const response = await getAnticipatedGames(limit, offset, period);
      setGames(response.data.results);
      
      // 期間に応じた最大ページ数を設定
      const maxPages = period === 730 ? 5 : 3;
      const calculatedTotalPages = Math.min(
        Math.ceil(response.data.total_count / limit),
        maxPages
      );
      setTotalPages(calculatedTotalPages);

      if (isLoggedIn) {
        const gameIds = response.data.results.map((game: Game) => game.game_id);
        const statusesResponse = await getBasicGameStatuses(gameIds);
        setGameStatuses(statusesResponse);
      }
    } catch (error) {
      console.error('ゲーム情報の取得に失敗しました', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const maxPages = currentPeriod === 730 ? 5 : 3;
    if (currentPage > maxPages) {
      navigate(`/anticipated-games?page=1&period=${currentPeriod}`);
      return;
    }
    fetchGames(currentPage, currentPeriod);
  }, [currentPage, currentPeriod, isLoggedIn]);

  return (
    <>
      <Helmet>
        <title>注目の期待作 今後発売するゲームタイトル - PULSE</title>
        <meta name="description" content="今後リリース予定の期待の新作ゲームタイトルをご紹介。PULSEユーザーが注目している upcoming ゲームを掲載しています。" />
      </Helmet>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: 2,
        mb: 3
      }}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            注目の期待作
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            今後リリース予定の期待の新作ゲームタイトルです。
          </Typography>
        </Box>
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          flexWrap: 'wrap'
        }}>
          {periods.map((period) => (
            <a
              key={period.value}
              href={`/anticipated-games?period=${period.value}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/anticipated-games?period=${period.value}`);
              }}
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Typography
                sx={{
                  color: currentPeriod === period.value ? 'primary.main' : 'text.secondary',
                  fontWeight: currentPeriod === period.value ? 'bold' : 'normal',
                  position: 'relative',
                  '&:hover': {
                    color: 'primary.main'
                  },
                  '&::after': currentPeriod === period.value ? {
                    content: '""',
                    position: 'absolute',
                    bottom: -2,
                    left: 0,
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'primary.main',
                    borderRadius: '2px'
                  } : undefined
                }}
              >
                {period.label}
              </Typography>
            </a>
          ))}
        </Box>
      </Box>
      {loading ? (
        <LoadingPlaceholder type="card" count={limit} />
      ) : (
        <>
          <Grid container spacing={{ xs: 1, sm: 1.5 }}>
            {games.map((game) => (
              <Grid item xs={4} sm={3} md={2} key={game.game_id}>
                <GameCard 
                  game={game}
                  initialStatus={isLoggedIn ? gameStatuses[game.game_id] : null}
                />
              </Grid>
            ))}
          </Grid>
          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Pagination
                page={currentPage}
                count={totalPages}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`/anticipated-games?page=${item.page}&period=${currentPeriod}`}
                    {...item}
                  />
                )}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AnticipatedGamesPage; 