import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const RatingContainer = styled(Box)(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: isMobile ? '4px' : '8px',
  padding: isMobile ? '4px' : '12px',
  backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.04)' : 'rgba(255, 255, 255, 0.05)',
  backdropFilter: isMobile ? 'blur(4px)' : 'blur(4px)',
  WebkitBackdropFilter: isMobile ? 'blur(4px)' : 'blur(4px)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  width: isMobile ? '64px' : 'auto',
}));

const ScoreCircle = styled(Box)(({ isMobile }: { isMobile?: boolean }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: isMobile ? '56px' : '90px',
  height: isMobile ? '56px' : '90px',
}));

const CircleBackground = styled(CircularProgress)({
  position: 'absolute',
  color: 'rgba(255, 255, 255, 0.1)',
});

const CircleProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  color: '#4CAF50',
  '& circle': {
    strokeLinecap: 'round',
  },
}));

const ScoreText = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

interface GameRatingProps {
  rating: number | null;
  rating_jp?: number | null;
  ratingCount?: number;
  isMobile?: boolean;
}

const GameRating: React.FC<GameRatingProps> = ({ rating, rating_jp, ratingCount = 0, isMobile = false }) => {
  const normalizedRating = rating_jp ? Math.floor((rating_jp / 20) * 10) / 10 : null;
  const progressValue = rating_jp || 0;

  const getScoreColor = (score: number | null) => {
    if (!score) return '#666666';
    if (score >= 90) return '#4CAF50';
    if (score >= 80) return '#8BC34A';
    if (score >= 70) return '#CDDC39';
    if (score >= 60) return '#FFEB3B';
    if (score >= 50) return '#FFC107';
    if (score >= 41) return '#FF9800';
    return '#F44336';
  };

  const scoreColor = getScoreColor(rating_jp || null);

  return (
    <RatingContainer isMobile={isMobile}>
      {!isMobile && (
        <Typography variant="caption" sx={{ 
          color: '#a7a7a7',
          fontSize: isMobile ? '0.6rem' : '0.75rem',
          fontWeight: 500,
          letterSpacing: '0.02em'
        }}>
          Score
        </Typography>
      )}
      <ScoreCircle isMobile={isMobile}>
        <CircleBackground
          variant="determinate"
          value={100}
          size={isMobile ? 48 : 80}
          thickness={2}
        />
        <CircleProgress
          variant="determinate"
          value={progressValue}
          size={isMobile ? 48 : 80}
          thickness={2}
          sx={{ color: scoreColor }}
        />
        <ScoreText>
          {normalizedRating !== null ? (
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontSize: isMobile ? '0.8rem!important' : '1.5rem',
                fontWeight: 'bold',
                color: '#ffffff',
                lineHeight: 1
              }}
            >
              {normalizedRating.toFixed(1)}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontSize: isMobile ? '0.7rem!important' : '1.3rem',
                fontWeight: 'bold',
                color: '#666666',
                lineHeight: 1
              }}
            >
              N/A
            </Typography>
          )}
        </ScoreText>
      </ScoreCircle>
    </RatingContainer>
  );
};

export default GameRating; 