import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, ImageList, ImageListItem, useTheme, useMediaQuery, Modal, IconButton, Button, Dialog, DialogContent, CircularProgress } from '@mui/material';
import { getGameImages } from '../../services/api';
import ImageUploadButton from './ImageUploadButton';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { isSuperuser } from '../../services/auth';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Image } from '../../types/Game';



interface SelectedImage extends Image {
  url: string;
  original_url: string | null;
}

interface GameGallerySectionProps {
  gameId: number;
  initialImages?: Image[];
  totalCount?: number;
}

const GameGallerySection: React.FC<GameGallerySectionProps> = ({ 
  gameId, 
  initialImages,
  totalCount: initialTotalCount 
}) => {
  const [images, setImages] = useState<Image[]>(initialImages || []);
  const [totalCount, setTotalCount] = useState<number>(initialTotalCount || 0);
  const [selectedImage, setSelectedImage] = useState<SelectedImage | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isUserSuperuser, setIsUserSuperuser] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const fetchImages = async () => {
    if (initialImages && initialTotalCount) {
      setRemainingCount(initialTotalCount - initialImages.length);
      setOffset(initialImages.length);
      return;
    }

    try {
      const response = await getGameImages(gameId, 5, 0);
      setImages(response.results);
      setTotalCount(response.count);
      setRemainingCount(response.count - response.results.length);
      setOffset(response.results.length);
    } catch (error) {
      console.error('画像の取得に失敗しました:', error);
      setImages([]);
      setTotalCount(0);
      setRemainingCount(0);
      setOffset(0);
    }
  };

  const handleLoadMore = async () => {
    if (loadingMore) return;
    setLoadingMore(true);
    try {
      const response = await getGameImages(gameId, 20, offset);
      setImages(prev => [...prev, ...response.results]);
      setOffset(prev => prev + response.results.length);
      setRemainingCount(prev => prev - response.results.length);
      return response.results.length > 0;
    } catch (error) {
      console.error('追加画像の取得に失敗しました:', error);
      return false;
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      fetchImages();
      const superuser = await isSuperuser();
      setIsUserSuperuser(superuser);
    };
    init();
  }, [gameId]);

  const handleUploadComplete = (imageUrls: string[]) => {
    // 全ての画像のアップロードが完了してから画像一覧を再取得
    fetchImages();
  };

  const handleImageClick = (image: Image, index: number) => {
    setCurrentIndex(index);
    // スライドショーを開いたときにサムネイルをスクロールさせる
    scrollThumbnailIntoView(index);

    const selectedImage: SelectedImage = {
      ...image,
      url: getImageUrl(image),
      original_url: getOriginalUrl(image)
    };
    setSelectedImage(selectedImage);
    setDialogOpen(true);
  };

  const handleCloseModal = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  const handleViewOriginal = (e: React.MouseEvent, url: string) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  const getImageUrl = (image: Image) => {
    if (image.is_igdb) {
      return `https://images.igdb.com/igdb/image/upload/t_720p/${image.image_id}.jpg`;
    }

    // カスタム画像の場合
    const basePath = `${process.env.REACT_APP_R2_PUBLIC_URL}/images/games/${gameId}`;
    const webpPath = `${image.image_id}_opt.webp`;
    return `${basePath}/${webpPath}`;
  };

  const getOriginalUrl = (image: Image) => {
    if (image.is_igdb) {
      return null;  // IGDBの画像にはオリジナル画像のURLは不要
    }

    if (image.original_format > 0) {
      const basePath = `${process.env.REACT_APP_R2_PUBLIC_URL}/images/games/${gameId}`;
      const formatExtension = {1: 'jpg', 2: 'png', 3: 'gif', 4: 'webp'}[image.original_format];
      return `${basePath}/${image.image_id}.${formatExtension}`;
    }

    return null;
  };

  const scrollThumbnailIntoView = (index: number) => {
    // images配列とindexの有効性をチェック
    if (!images || !images[index] || typeof images[index].id === 'undefined') {
      return;
    }

    const thumbnail = document.getElementById(`thumbnail-${images[index].id}`);
    if (thumbnail) {
      // スクロール位置を計算
      const container = thumbnail.parentElement;
      if (container) {
        const containerWidth = container.clientWidth;
        const thumbnailWidth = thumbnail.clientWidth;
        const scrollPosition = thumbnail.offsetLeft - (containerWidth / 2) + (thumbnailWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  // currentIndexが変更されたときに自動スクロールを実行
  useEffect(() => {
    if (images && images.length > 0) {
      scrollThumbnailIntoView(currentIndex);
    }
  }, [currentIndex, images]);

  const handlePrevImage = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (currentIndex > 0 && images.length > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleNextImage = async (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (images.length > 0 && currentIndex < images.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);

      // 最後から2番目の画像に到達し、まだ読み込む画像が残っている場合
      if (currentIndex >= images.length - 2 && remainingCount > 0) {
        await handleLoadMore();
      }
    }
  };

  // スライドショーのサムネイルをスクロールさせる関数
  const scrollDialogThumbnails = () => {
    if (!thumbnailsRef.current) return;

    const container = thumbnailsRef.current;
    const thumbnail = document.getElementById(`dialog-thumbnail-${images[currentIndex].id}`) as HTMLElement;

    if (thumbnail) {
      const containerWidth = container.offsetWidth;
      const thumbnailWidth = thumbnail.offsetWidth;
      const scrollPosition = thumbnail.offsetLeft - (containerWidth / 2) + (thumbnailWidth / 2);

      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  // currentIndexが変更されたときにスライドショーのサムネイルもスクロール
  useEffect(() => {
    if (dialogOpen) {
      scrollDialogThumbnails();
    }
  }, [currentIndex, dialogOpen, images]);

  if (!images || images.length === 0) {
    if (process.env.NODE_ENV !== 'development' && !isUserSuperuser) {
      return null;
    }

    return (
      <Box sx={{ mt: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 2 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6" component="h2">
              ギャラリー
            </Typography>
            <IconButton
              component={Link}
              to={`/game/${gameId}/images`}
              size="small"
              sx={{ 
                ml: 0.5,
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
          {(process.env.NODE_ENV === 'development' || isUserSuperuser) && (
            <ImageUploadButton 
              gameId={gameId}
              onUploadComplete={handleUploadComplete}
              onError={(error) => console.error(error)}
            />
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          このゲームの画像はまだ登録されていません。
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 3 } }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 1 
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" component="h2">
            ギャラリー
          </Typography>
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
              fontSize: '0.7rem',
              padding: '2px 8px',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {totalCount}
          </Box>
          <IconButton
            component={Link}
            to={`/game/${gameId}/images`}
            size="small"
            sx={{ 
              ml: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
        {(process.env.NODE_ENV === 'development' || isUserSuperuser) && (
          <ImageUploadButton 
            gameId={gameId}
            onUploadComplete={handleUploadComplete}
            onError={(error) => console.error(error)}
          />
        )}
      </Box>

      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            aspectRatio: '16/9',
            borderRadius: 1,
            overflow: 'hidden',
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            '&:hover': {
              '& img': {
                opacity: 0.9,
              },
            },
          }}
          onClick={() => handleImageClick(images[currentIndex], currentIndex)}
        >
          {images.length > 0 && (
            <img
              src={getImageUrl(images[currentIndex])}
              alt={images[currentIndex].description || ''}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transition: 'opacity 0.2s',
              }}
            />
          )}

          {/* インジケーター */}
          <Box
            sx={{
              position: 'absolute',
              bottom: { xs: 4, sm: 8 },
              left: { xs: 4, sm: 8 },
              display: 'flex',
              gap: 0.5,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: { xs: '2px 8px', sm: '4px 8px' },
              borderRadius: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontSize: { xs: '0.5rem', sm: '0.7rem' },
              }}
            >
              {currentIndex + 1} / {totalCount}
            </Typography>
          </Box>
        </Box>

        {/* サムネイル一覧 */}
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            pb: 1,
            '&::-webkit-scrollbar': {
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.15)',
              borderRadius: '3px',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.25)',
              },
            },
          }}
        >
          {images.map((image, index) => (
            <Box
              key={image.id}
              id={`thumbnail-${image.id}`}
              component="img"
              src={getImageUrl(image)}
              alt={image.description || ''}
              onClick={() => setCurrentIndex(index)} // サムネイルクリック時は currentIndex の更新のみ
              sx={{
                width: '120px',
                height: '67.5px',
                objectFit: 'cover',
                cursor: 'pointer',
                borderRadius: 1,
                opacity: currentIndex === index ? 1 : 0.75,
                border: currentIndex === index ? '1px solid rgba(255, 255, 255, 0.5)' : 'none',
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 1,
                },
              }}
            />
          ))}
          {remainingCount > 0 && (
            <Box
              onClick={handleLoadMore}
              sx={{
                width: '120px',
                height: '67.5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 1,
                cursor: 'pointer',
                color: 'white',
                fontSize: '0.8rem',
                fontWeight: 'bold',
                flexShrink: 0,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              {loadingMore ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body1" component="div" sx={{ color: 'text.secondary' }}>
                    +{remainingCount}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(12px)',
            overflow: 'hidden',
            width: { xs: '100%', sm: '1280px' },
            height: { xs: '100%', sm: '840px' },
            m: { xs: 0, sm: 2 },
          }
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: 'white',
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          sx={{
            p: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '&.MuiDialogContent-root': {
              overflow: 'hidden',
            }
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 0,
              pt: { xs: 2, sm: 0 },
            }}
          >
            {images.length > 0 && (
              <Box
                component="img"
                src={getImageUrl(images[currentIndex])}
                alt={images[currentIndex].description || ''}
                sx={{
                  minWidth: isMobile ? '100%' : '512px',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                  objectFit: 'contain',
                  outline: 'none',
                  userSelect: 'none',
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              mt: 'auto',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              px: 1,
              pt: 2,
              pb: 2,
              height: '120px',
            }}
          >
            <IconButton
              onClick={handlePrevImage}
              disabled={currentIndex === 0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobile ? '32px' : '48px',
                height: '100px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: currentIndex === 0 ? 'default' : 'pointer',
                opacity: currentIndex === 0 ? 0.5 : 1,
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: currentIndex === 0 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: 'white' }} />
            </IconButton>

            <Box
              ref={thumbnailsRef}
              sx={{
                flex: 1,
                display: 'flex',
                gap: 1,
                overflowX: 'auto',
                WebkitUserDrag: 'none',
                userSelect: 'none',
                '& img': {
                  userSelect: 'none',
                  WebkitUserDrag: 'none',
                }
              }}
            >
              {images.map((image, index) => (
                <Box
                  key={image.id}
                  id={`dialog-thumbnail-${image.id}`}
                  component="img"
                  src={getImageUrl(image)}
                  alt={image.description || ''}
                  onClick={() => handleImageClick(image, index)}
                  sx={{
                    width: isMobile ? '90px' : '160px',
                    height: isMobile ? '60px' : '90px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: 1,
                    opacity: currentIndex === index ? 1 : 0.75,
                    border: currentIndex === index ? '1px solid rgba(255, 255, 255, 0.2)' : 'none',
                    transition: 'opacity 0.2s',
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                />
              ))}
              {remainingCount > 0 && (
                <Box
                  onClick={handleLoadMore}
                  sx={{
                    width: isMobile ? '90px' : '160px',
                    height: isMobile ? '60px' : '90px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 1,
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: isMobile ? '0.8rem' : '1rem',
                    fontWeight: 'bold',
                    flexShrink: 0,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  }}
                >
                  {loadingMore ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="body1" component="div">
                        +{remainingCount}
                      </Typography>
                      <Typography variant="caption" component="div" sx={{ fontSize: '0.7rem', color: 'text.secondary' }}>
                        全{totalCount}枚
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>

            <IconButton
              onClick={handleNextImage}
              disabled={currentIndex === images.length - 1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobile ? '32px' : '48px',
                height: '100px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: currentIndex === images.length - 1 ? 'default' : 'pointer',
                opacity: currentIndex === images.length - 1 ? 0.5 : 1,
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: currentIndex === images.length - 1 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <ArrowForwardIosIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>

          {selectedImage?.original_url && selectedImage.original_format > 0 && (
            <Button
              onClick={(e) => handleViewOriginal(e, selectedImage.original_url!)}
              sx={{
                position: 'absolute',
                bottom: 136,
                right: 12,
                color: 'white',
                fontSize: '0.6rem',
                textTransform: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              View original
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default GameGallerySection;