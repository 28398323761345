import React from 'react';

export const formatReviewText = (text: string, isMobile?: boolean): React.ReactNode => {
  if (!text) return text;

  // テキストを一旦単一の文字列として処理
  let formattedText = text;

  // スポイラー処理（複数行対応）
  formattedText = formattedText.replace(
    /\[spoiler\]([\s\S]*?)(?:\[\/spoiler\]|$)/g,
    (match: string, content: string) => {
      const processedContent = content.replace(/\n/g, '<br>');
      return `<span class="spoiler" style="background-color: #303030; color: #303030; cursor: pointer; padding: 0 4px; border-radius: 2px; user-select: none;" onclick="this.style.backgroundColor='transparent'; this.style.color='inherit';">${processedContent}</span>`;
    }
  );

  // 引用（複数行対応）- 改行を<br>に変換
  formattedText = formattedText.replace(
    /\[quote\]([\s\S]*?)\[\/quote\]/g,
    (match: string, content: string) => {
      const processedContent = content.replace(/\n/g, '<br>');
      return `<blockquote style="background-color: rgba(0, 0, 0, 0.3); border-left: 2px solid #666; margin: 0.5em 0; padding: 0.5em 1em; color: #999; border-radius: 4px;">${processedContent}</blockquote>`;
    }
  );

  // リスト処理（複数行対応）
  formattedText = formattedText.replace(
    /\[list\]([\s\S]*?)\[\/list\]/g,
    (match: string, content: string) => {
      const items = content
        .split('[*]')
        .filter((item: string) => item.trim())
        .map((item: string) => `<li>${item.trim()}</li>`)
        .join('');
      return `<ul style="margin: 0.5em 0; padding-left: 1.5em;">${items}</ul>`;
    }
  );

  formattedText = formattedText.replace(
    /\[olist\]([\s\S]*?)\[\/olist\]/g,
    (match: string, content: string) => {
      const items = content
        .split('[*]')
        .filter((item: string) => item.trim())
        .map((item: string) => `<li>${item.trim()}</li>`)
        .join('');
      return `<ol style="margin: 0.5em 0; padding-left: 1.5em;">${items}</ol>`;
    }
  );

  // 処理済みのテキストを行に分割
  const lines = formattedText.split('\n');

  const formatLine = (line: string): React.ReactElement => {
    let processedText = line;

    // ヘッダー - isMobile に応じてフォントサイズを変更
    const h1Size = isMobile ? '0.8rem' : '0.9rem';
    const h2Size = isMobile ? '0.725rem' : '0.825rem';
    const h3Size = isMobile ? '0.65rem' : '0.75rem';

    processedText = processedText.replace(
      /\[h1\](.*?)\[\/h1\]/g,
      `<h2 style="font-size: ${h1Size}; margin: 0.5em 0; color: #f8f8f8; font-weight: 400;">$1</h2>`
    );
    processedText = processedText.replace(
      /\[h2\](.*?)\[\/h2\]/g,
      `<h3 style="font-size: ${h2Size}; margin: 0.5em 0; color: #f8f8f8; font-weight: 400;">$1</h3>`
    );
    processedText = processedText.replace(
      /\[h3\](.*?)\[\/h3\]/g,
      `<h4 style="font-size: ${h3Size}; margin: 0.5em 0; color: #f8f8f8; font-weight: 400;">$1</h4>`
    );

    // インライン装飾
    processedText = processedText.replace(
      /\[b\](.*?)\[\/b\]/g,
      '<strong>$1</strong>'
    );
    processedText = processedText.replace(
      /\[i\](.*?)\[\/i\]/g,
      '<em>$1</em>'
    );
    processedText = processedText.replace(
      /\[s\](.*?)\[\/s\]/g,
      '<del>$1</del>'
    );
    processedText = processedText.replace(
      /\[u\](.*?)\[\/u\]/g,
      '<u>$1</u>'
    );

    // URLリンク
    processedText = processedText.replace(
      /\[url=(.*?)\](.*?)\[\/url\]/g,
      '<a href="$1" target="_blank" rel="noopener noreferrer" style="color: #66c0f4; text-decoration: none;">$2</a>'
    );

    return React.createElement('p', {
      style: { margin: '0.1em 0' },
      dangerouslySetInnerHTML: { __html: processedText }
    });
  };

  // 各行を個別に処理して配列として返す
  const formattedLines = lines.map((line, index) => 
    line.trim() ? formatLine(line) : React.createElement('br', { key: `br-${index}` })
  );
  
  return React.createElement('div', {}, formattedLines);
};
