import React from 'react';
import { Box } from '@mui/material';

const WikiAdComponent: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: '336px',
          height: '280px'
        }}
        data-ad-client="ca-pub-6000060497265595"
        data-ad-slot="5006852275"
        data-ad-format="auto"
        data-full-width-responsive="false"
      />
    </Box>
  );
};

export default WikiAdComponent; 