import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Alert, Box, Typography, Chip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { startPlaying, stopPlaying, getPlayingStatus, getLatestPlayRecord, cancelPlaying } from '../services/api';

interface PlaySessionButtonProps {
  gameId: number;
  disabled?: boolean;
}

const CATEGORIES = [
  { id: "0", label: "メイン" },
  { id: "1", label: "サブ" },
  // { id: "2", label: "探索" },
  // { id: "3", label: "強化" },
  { id: "7", label: "やり込み" },
  { id: "8", label: "マルチ" },
  { id: "97", label: "プレイ終了" },
  { id: "98", label: "達成：クリア" },
  { id: "99", label: "達成：コンプリート" }
];

export default function PlaySessionButton({ gameId, disabled }: PlaySessionButtonProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [category, setCategory] = useState<string[]>([]);
  const [comment, setComment] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [playStartTime, setPlayStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState<string>('00:00');
  const [isOverLimit, setIsOverLimit] = useState(false);

  useEffect(() => {
    // 現在のプレイ状況のみを確認（基本情報のみ）
    getPlayingStatus().then(response => {
      const isCurrentlyPlaying = response.data.is_playing && response.data.game_id === gameId;
      setIsPlaying(isCurrentlyPlaying);
      
      if (isCurrentlyPlaying && response.data.start_time) {
        setPlayStartTime(new Date(response.data.start_time));
      } else {
        setPlayStartTime(null);
      }
    });
  }, [gameId]);

  // プレイ時間の計算と表示を更新
  useEffect(() => {
    if (!isPlaying || !playStartTime) {
      setElapsedTime('00:00');
      setIsOverLimit(false);
      return;
    }

    const updateElapsedTime = () => {
      const now = new Date();
      const diff = Math.floor((now.getTime() - playStartTime.getTime()) / 1000);
      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      const seconds = diff % 60;
      
      // 24時間（86400秒）を超えているかチェック
      const isLimitExceeded = diff >= 86400;
      setIsOverLimit(isLimitExceeded);
      
      if (isLimitExceeded) {
        // 24時間制限に達した場合は24:00:00を表示
        setElapsedTime('24:00:00');
      } else if (hours > 0) {
        setElapsedTime(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      } else {
        setElapsedTime(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      }
    };

    updateElapsedTime();
    const interval = setInterval(updateElapsedTime, 1000);
    
    return () => clearInterval(interval);
  }, [isPlaying, playStartTime]);

  const handleStart = async () => {
    try {
      const response = await startPlaying(gameId);
      setIsPlaying(true);
      setPlayStartTime(new Date(response.data.start_time));
      setSuccess('セッションの記録を開始');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      setError(error.response?.data?.error || 'エラーが発生しました');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleOpenDialog = async () => {
    try {
      // ダイアログを開く時に最新の記録を取得
      const response = await getLatestPlayRecord();
      setCategory(response.data.category || []);
      setComment(response.data.comment || '');
      setOpenDialog(true);
    } catch (error: any) {
      setError(error.response?.data?.error || 'エラーが発生しました');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleStop = async () => {
    try {
      await stopPlaying(category, comment);
      setIsPlaying(false);
      setPlayStartTime(null);
      setOpenDialog(false);
      setIsOverLimit(false);
      setSuccess('セッションの記録を終了');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      setError(error.response?.data?.error || 'エラーが発生しました');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleCancel = async () => {
    try {
      await cancelPlaying();  // 新しいエンドポイントを使用
      setIsPlaying(false);
      setPlayStartTime(null);
      setOpenDialog(false);
      setIsOverLimit(false);
      setSuccess('セッションを中止しました');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      setError(error.response?.data?.error || 'エラーが発生しました');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <Box sx={{ 
      width: '100%',
      position: 'relative',
      '@media (max-width: 599px)': {
        width: '100vw',
        margin: '0 -12px',
        padding: '0 12px',
        maxWidth: 'calc(100vw - 136px)'
      },
      '@media (600px <= width <= 899px)': {
        width: '100vw',
        margin: '0 -12px',
        padding: '0 12px',
        maxWidth: 'calc(100vw - 152px)'
      }
    }}>
      {(error || success) && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            bottom: -24,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: error ? '#ff4444' : 'text.secondary',
            animation: 'fadeIn 0.2s ease',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(-4px)' },
              to: { opacity: 1, transform: 'translateY(0)' }
            }
          }}
        >
          {error || success}
        </Typography>
      )}
      <Button
        variant="contained"
        color={isPlaying ? (isOverLimit ? "error" : "secondary") : "primary"}
        startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        onClick={isPlaying ? handleOpenDialog : handleStart}
        disabled={disabled}
        fullWidth
        sx={{ 
          minWidth: 'auto',
          borderRadius: { xs: '6px', sm: '8px' },
          transition: 'all 0.3s ease',
          backgroundColor: isOverLimit 
            ? 'rgba(255, 50, 50, 0.12)' 
            : (isPlaying ? 'rgba(217, 255, 0, 0.12)' : 'rgba(255, 255, 255, 0.06)'),
          backdropFilter: 'blur(8px)',
          border: isOverLimit 
            ? '1px solid rgba(255, 50, 50, 0.5)' 
            : (isPlaying ? '1px solid rgba(217, 255, 0, 0.5)' : '1px solid rgba(255, 255, 255, 0.1)'),
          textTransform: 'none',
          padding: { xs: '4px 12px', sm: '6px 16px' },
          color: isOverLimit ? '#ff5050' : (isPlaying ? '#d9ff00' : '#ffffffe8'),
          fontSize: { xs: '0.675rem', sm: '0.7rem' },
          fontWeight: 500,
          letterSpacing: '0.02em',
          position: 'relative',
          overflow: 'hidden',
          '& .MuiButton-startIcon': {
            margin: { xs: '0 4px 0 -4px', sm: '0 8px 0 -4px' },
            '& > *:nth-of-type(1)': {
              fontSize: { xs: 20, sm: 18 }
            }
          },
          '&:hover': {
            backgroundColor: isOverLimit 
              ? 'rgba(255, 50, 50, 0.2)' 
              : (isPlaying ? 'rgba(217, 255, 0, 0.2)' : 'rgba(255, 255, 255, 0.12)'),
            transform: 'translateY(-1px)',
            border: isOverLimit 
              ? '1px solid rgba(255, 50, 50, 0.7)' 
              : (isPlaying ? '1px solid rgba(217, 255, 0, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'),
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
            color: 'rgba(255, 255, 255, 0.3)'
          },
          ...(isPlaying && {
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: isOverLimit
                ? 'linear-gradient(90deg, rgba(255, 50, 50, 0), rgba(255, 50, 50, 0.2), rgba(255, 50, 50, 0))'
                : 'linear-gradient(90deg, rgba(217, 255, 0, 0), rgba(217, 255, 0, 0.2), rgba(217, 255, 0, 0))',
              backgroundSize: '200% 100%',
              animation: 'shimmer 2s infinite'
            },
            '@keyframes shimmer': {
              '0%': {
                backgroundPosition: '100% 0'
              },
              '100%': {
                backgroundPosition: '-100% 0'
              }
            }
          })
        }}
      >
        {isPlaying ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Typography variant="caption" component="span" sx={{ mr: 1 }}>
              {isOverLimit ? 'セッション停止' : 'セッションを記録中'}
            </Typography>
            <Typography 
              component="span" 
              sx={{ 
                fontSize: { xs: '0.6rem', sm: '0.65rem' },
                opacity: 0.9,
                color: isOverLimit ? 'rgba(255, 80, 80, 0.8)' : 'rgba(255, 255, 255, 0.6)',
                fontFamily: 'monospace',
                backgroundColor: isOverLimit 
                  ? 'rgba(255, 50, 50, 0.15)' 
                  : 'rgba(217, 255, 0, 0.15)',
                borderRadius: '4px',
                padding: '1px 4px',
                minWidth: { xs: '40px', sm: '45px' },
                textAlign: 'center'
              }}
            >
              {elapsedTime}
            </Typography>
          </Box>
        ) : 'プレイ開始'}
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>プレイログ</Typography>
            {playStartTime && (
              <Typography variant="body2" sx={{ color: 'text.secondary', fontFamily: 'monospace' }}>
                {elapsedTime}
              </Typography>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
              <Typography>カテゴリー</Typography>
              <Typography 
                color="text.secondary" 
                variant="body2"
                sx={{ ml: 1 }}
              >
                {category.length}/4
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {CATEGORIES.map((cat) => (
                <Chip
                  key={cat.id}
                  label={cat.label}
                  onClick={() => {
                    const newCategory = category.includes(cat.id)
                      ? category.filter(id => id !== cat.id)
                      : [...category, cat.id];
                    setCategory(newCategory);
                  }}
                  color={category.includes(cat.id) ? 'primary' : 'default'}
                  size="small"
                />
              ))}
            </Box>
          </Box>

          <TextField
            label="コメント（30文字以内）"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 30 }}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>閉じる</Button>
          <Button 
            onClick={handleCancel}
            color="error" 
            sx={{ 
              mr: 'auto',
              color: 'rgba(255, 80, 80, 0.8)',
              '&:hover': {
                backgroundColor: 'rgba(255, 80, 80, 0.08)'
              }
            }}
          >
            記録中止
          </Button>
          <Button onClick={handleStop} color="primary" variant="contained">
            記録する
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 