import React from 'react';
import { Typography, Box, Paper, Container } from '@mui/material';
import GoogleLoginButton from '../components/auth/GoogleLogin';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';
import DiscordLoginButton from '../components/auth/DiscordLogin';
import SteamLoginButton from '../components/auth/SteamLogin';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const from = (location.state as { from?: string })?.from || '/';

  const handleLoginSuccess = (user: any) => {
    const event = new CustomEvent('loginSuccess', { detail: user });
    window.dispatchEvent(event);
  };

  return (
    <>
      <Helmet>
        <title>ログイン | {SITE_NAME}</title>
      </Helmet>

      <Box
        sx={{
          background: (theme) => `linear-gradient(145deg, ${theme.palette.background.paper}, rgba(0, 0, 0, 0.2))`,
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          mb: -12
        }}
      >
        {/* 背景のグラデーション効果 */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(142, 163, 38, 0.1), transparent 70%)',
          zIndex: 0
        }} />

        <Container maxWidth="sm" sx={{ position: 'relative', zIndex: 1, mt: -12 }}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, sm: 6 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: (theme) => `rgba(${theme.palette.mode === 'dark' ? '255,255,255,0.03' : '0,0,0,0.02'})`,
              backdropFilter: 'blur(10px)',
              borderRadius: '16px',
              border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}`,
            }}
          >
            <Box
              component="img"
              src="/pulse_logo.png"
              alt="PULSE"
              sx={{
                width: 180,
                mb: 4
              }}
            />
            
            <Typography 
              variant="h5" 
              component="h1" 
              gutterBottom
              sx={{
                fontWeight: 600,
                textAlign: 'center',
                mb: 3
              }}
            >
              ゲーム体験を記録するベストな方法
            </Typography>

            <Typography 
              color="text.secondary"
              sx={{
                mb: 4,
                textAlign: 'center',
                maxWidth: '400px'
              }}
            >
              あなただけのゲームライブラリを作成し、プレイしたゲームを記録しましょう。
            </Typography>

            <Box sx={{ width: '100%', maxWidth: '320px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <GoogleLoginButton 
                onLoginSuccess={handleLoginSuccess} 
                redirectPath={from} 
              />
              <DiscordLoginButton 
                onLoginSuccess={handleLoginSuccess}
                redirectPath={from}
              />
              {process.env.NODE_ENV === 'development' && (
                <SteamLoginButton 
                  onLoginSuccess={handleLoginSuccess}
                  redirectPath={from}
                />
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default LoginPage;