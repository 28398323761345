import React, { useState } from 'react';
import { Container, Grid, List, ListItemButton, ListItemText, Typography, Box, Snackbar, Alert, Paper } from '@mui/material';
import ProfileEditForm from '../components/setting/ProfileEditForm';
import AccountSettingsForm from '../components/setting/AccountSettingsForm';
import PrivacySettingsForm from '../components/setting/PrivacySettingsForm';
import { getUserProfile, updateUserProfile } from '../services/api';
import { UserProfile } from '../types/UserProfile';
import StatusLimitsDisplay from '../components/setting/StatusLimitsDisplay';
import AvatarSettingsForm from '../components/setting/AvatarSettingsForm';
import SteamSettings from '../components/profile/SteamSettings';

const ProfileSettingsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isAdmin, setIsAdmin] = useState(false);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await getUserProfile();
      setProfile(response.data);
      setIsAdmin(response.data.user_id === 1);
    } catch (error) {
      console.error('プロフィールの取得に失敗しました', error);
      showSnackbar('プロフィールの取得に失敗しました', 'error');
    }
  };

  const handleSave = async (updatedProfile: UserProfile) => {
    try {
      await updateUserProfile(updatedProfile);
      setProfile(updatedProfile);
      showSnackbar('プロフィールが正常に更新されました', 'success');
    } catch (error: any) {
      console.error('プロフィールの更新に失敗しました', error);
      showSnackbar('プロフィールの更新に失敗しました', 'error');
      throw error;
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAvatarUpdate = (newAvatarUrl: string) => {
    if (profile) {
      setProfile({
        ...profile,
        avatar: newAvatarUrl
      });
      showSnackbar('アバターが正常に更新されました', 'success');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return profile ? (
          <ProfileEditForm profile={profile} onSave={handleSave} />
        ) : (
          <Typography>読み込み中...</Typography>
        );
      case 'avatar':
        return profile ? (
          <AvatarSettingsForm 
            currentAvatar={profile.avatar} 
            onAvatarUpdate={handleAvatarUpdate} 
          />
        ) : (
          <Typography>読み込み中...</Typography>
        );
      case 'account':
        return profile ? (
          <AccountSettingsForm profile={profile} onSave={handleSave} />
        ) : (
          <Typography>読み込み中...</Typography>
        );
      case 'privacy':
        return profile ? (
          <PrivacySettingsForm profile={profile} onSave={handleSave} />
        ) : (
          <Typography>読み込み中...</Typography>
        );
      case 'steam':
        return <SteamSettings />;
      case 'information':
        return profile ? (
          <StatusLimitsDisplay profile={profile} />
        ) : (
          <Typography>読み込み中...</Typography>
        );
      case 'appearance':
        return <Typography>外観設定（未実装）</Typography>;
      case 'accessibility':
        return <Typography>アクセシビリティ設定（未実装）</Typography>;
      case 'notifications':
        return <Typography>通知設定（未実装）</Typography>;
      default:
        return null;
    }
  };

  const getImplementedTabs = () => {
    const baseTabs = ['profile', 'avatar', 'account', 'privacy', 'information'];
    return isAdmin ? [...baseTabs, 'steam'] : baseTabs;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, p: 0 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Setting
      </Typography>
      <Paper elevation={0} sx={{ bgcolor: 'background.paper' }}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ borderRight: 1, borderColor: 'divider' }}>
            <List component="nav" sx={{ py: 0 }}>
              {getImplementedTabs().map((tab) => (
                <ListItemButton
                  key={tab}
                  selected={activeTab === tab}
                  onClick={() => setActiveTab(tab)}
                  sx={{
                    py: { xs: 0.5, sm: 1 },
                    borderLeft: activeTab === tab ? 2 : 0,
                    borderColor: 'primary.main',
                    '&.Mui-selected': {
                      backgroundColor: 'action.selected',
                    },
                  }}
                >
                  <ListItemText 
                    primary={tab.charAt(0).toUpperCase() + tab.slice(1)} 
                    primaryTypographyProps={{
                      fontSize: { xs: 14, sm: 14 },
                      fontWeight: activeTab === tab ? 'bold' : 'normal',
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ p: 3 }}>
              {renderTabContent()}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProfileSettingsPage;
