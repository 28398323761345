import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button, IconButton, Skeleton } from '@mui/material';
import { getGameReviews } from '../../services/api';
import { Review } from '../../types/Review';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CreateIcon from '@mui/icons-material/Create';
import Dialog from '../dialogs/Dialog';
import LoginRequiredDialog from '../dialogs/LoginRequiredDialog';
import ReviewItem from './ReviewItem';

interface ReviewSectionProps {
  gameId: number;
  limit?: number;
  onTotalReviewsChange?: (total: number) => void;
  onReviewClick?: () => void;
  isAuthenticated?: boolean;
  gameStatus?: number | null;
  renderAfterComponent?: React.ReactNode;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ 
  gameId, 
  limit, 
  onTotalReviewsChange,
  onReviewClick,
  isAuthenticated,
  gameStatus,
  renderAfterComponent
}) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [showStatusError, setShowStatusError] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const location = useLocation();
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { results, count } = await getGameReviews(gameId, undefined, limit);
        setReviews(results);
        setTotalCount(count);
        if (onTotalReviewsChange) {
          onTotalReviewsChange(count);
        }
      } catch (error) {
        console.error('レビューの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [gameId, limit, onTotalReviewsChange]);

  const handleReviewClick = () => {
    if (!isAuthenticated) {
      setShowLoginDialog(true);
      return;
    }

    if (gameStatus && [1, 2, 3].includes(gameStatus)) {
      onReviewClick?.();
    } else {
      setShowStatusError(true);
    }
  };

  if (loading) {
    return (
      <Box sx={{ mt: { xs: 3, sm: 3 } }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 1 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton variant="text" width={120} height={32} />
            <Skeleton variant="text" width={30} height={24} />
          </Box>
          <Skeleton variant="text" width={80} height={32} />
        </Box>
        {[1, 2, 3, 4].map((i) => (
          <Box key={i} sx={{ 
            mb: 1, 
            p: { xs: 1.5, sm: 2 }, 
            bgcolor: 'rgba(255, 255, 255, 0.03)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.08)',
          }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1.5 }} />
              <Box sx={{ flex: 1 }}>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="40%" height={20} />
              </Box>
            </Box>
            <Box sx={{ pl: { xs: 0, sm: 6.5 } }}>
              <Skeleton variant="text" width="90%" />
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="70%" />
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 3, sm: 3 } }}>
      {reviews.length > 0 && (
        <>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1 
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" component="h2">
                ユーザーレビュー
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: 'white',
                  fontSize: '0.7rem',
                  padding: '2px 8px',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {totalCount}
              </Box>
              <IconButton
                component={Link}
                to={`/game/${gameId}/reviews`}
                size="small"
                sx={{ 
                  ml: 0.5,
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Button
              variant="text"
              startIcon={<CreateIcon />}
              onClick={handleReviewClick}
              sx={{ 
                borderRadius: '12px',
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              review
            </Button>
          </Box>
          {reviews.map((review) => (
            <ReviewItem key={review.id} review={review} />
          ))}
          {totalCount >= 5 && (
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button
                component={Link}
                to={`/game/${gameId}/reviews`}
                variant="text"
                size="small"
              >
                {totalCount}件のレビューを見る
              </Button>
            </Box>
          )}
        </>
      )}

      {renderAfterComponent}

      {reviews.length === 0 && (
        <>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mt: { xs: 3, sm: 3 },
            mb: 1 
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" component="h2">
                ユーザーレビュー
              </Typography>
              <IconButton
                component={Link}
                to={`/game/${gameId}/reviews`}
                size="small"
                sx={{ 
                  ml: 0.5,
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Button
              variant="text"
              startIcon={<CreateIcon />}
              onClick={handleReviewClick}
              sx={{ 
                borderRadius: '12px',
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              review
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary">
            このゲームのレビューはまだ登録されていません。
          </Typography>
        </>
      )}

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={location.pathname}
      />

      <Dialog
        open={showStatusError}
        onClose={() => setShowStatusError(false)}
        title="レビューを書くには"
        content="レビューを書くには、ゲームのステータスを「プレイ済み」「プレイ中」「中断」のいずれかに設定してください。"
      />
    </Box>
  );
};

export default ReviewSection;